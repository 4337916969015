<template>
<b-card v-if="property" >
            <b-card-sub-title class="mr-2">{{ property.Name }}</b-card-sub-title>
           <template v-if="property.Type==types.Html">
               <hr/>
                <div  v-html="property.Value" > 
              
          </div>
           </template>
          <span v-else>
              {{property.Value}} </span>
</b-card>
</template>
<script>
import {ManagerActivityLogPropertyType} from '../../../common/enums'
export default {
  data() {
    return {
     types:ManagerActivityLogPropertyType
    };
  },
  props: {
    
    property: {
      type: Object,
      default: null,
    },
  },
  created() {
  },
  methods: {
  
  },
};
</script>