import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
import store from '@/store';
import router from '@/router';
// tslint:disable-next-line:max-line-length
import { CustomerViewModel, JobDetailsViewModel,ResteJasperCredentialsResult, Backup, Template } from '@/common/models';
class TemplateService {
  public get(): Promise<Template[]> {
    return httpService.get(Resources.getBaseUrl() + `templates`);
   }
public downloadTemplate(id:string): void {
 httpService.downloadAndSave(Resources.getBaseUrl() + `templates/download/${id}`);
}
public updateTemplate(template: Template): Promise<any> {
  return httpService.put(Resources.getBaseUrl() + `templates/${template.id}`, template);
}
public syncTemplates(templates:string[],clients:string[], syncBeforeDate:Date,syncNonExistentOnly:boolean): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `templates/sync?syncBeforeDate=${syncBeforeDate||''}&syncNonExistentOnly=${syncNonExistentOnly||''}`, {templates:templates, clients:clients});
}
public syncDefaultTemplates(templates:string[]): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `templates/syncdefaulttemplates`, templates);
}
public getFirmTemplates(firmId:string): Promise<any> {
  return httpService.get(Resources.getBaseUrl() + `templates/firmtemplates/` + firmId);
}
public addTemplates(templates:any[]): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `templates/add`, templates);
}
public getJobs(): Promise<JobDetailsViewModel[]> {
  //8 tempate sync job type
  //1 & 2 job status queued, running
  let queryString = '?types=8&types=9&';
  return httpService.get(Resources.getBaseUrl() + `jobs${queryString}`);
}
public getJob(id:string): Promise<JobDetailsViewModel> {
  
  return httpService.get(Resources.getBaseUrl() + `jobs/${id}`);
}

}
export let templateService = new TemplateService();
