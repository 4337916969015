
import { Component, Prop, Vue } from "vue-property-decorator";
import VueJsonPretty from "vue-json-pretty";


import { dbService, httpService } from "../../utils/http";
import CustomerBase from "@/common/customer.base";
import store from "@/store";
import { stat } from "fs";
import { connect } from "tls";
import Resources from "@/common/resources";

@Component({
  components: {
  }
})
export default class DbOperations extends CustomerBase {
 
  @Prop()
  firmName: string;

  @Prop()
  firmId: string;
    public dbDetails:any=null;
    public connectCommand:string=''
  mounted() {
   this.viewConnectionDetails()
  }
 selectText(containerid) {
     //@ts-ignore
    if (document.selection) { // IE
    //@ts-ignore
        var range = document.body.createTextRange();
        range.moveToElementText(document.getElementById(containerid));
        range.select();
    } else if (window.getSelection) {
        var range = document.createRange();
        range.selectNode(document.getElementById(containerid));
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
    }
}
 copyToClipboard() {
  var copyText = document.getElementById("connectcommand");

  /* Select the text field */
  //@ts-ignore
  copyText.select();
   //@ts-ignore
  copyText.setSelectionRange(0, 99999); /*For mobile devices*/

  /* Copy the text inside the text field */
  document.execCommand("copy");

      //@ts-ignore
  if (window.getSelection) {window.getSelection().removeAllRanges();}
    //@ts-ignore
 else if (document.selection) {document.selection.empty();}
}
 
  downloadCmdConnectionScript() {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.connectCommand));
    element.setAttribute('download', `${this.firmName}-connect.bat`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
    public viewConnectionDetails() {
    this.isBusy(true);
    dbService.getDbDetails(this.firmId).then(result => {
      this.isBusy(false);
      this.dbDetails = result;
      this.connectCommand=`"C:\\Program Files (x86)\\Microsoft SQL Server Management Studio 18\\Common7\\IDE\\ssms.exe"y -S ${this.dbDetails.dbServer} -d ${this.dbDetails.dbName} -u tpsadmin`;
    });
  }
 
}
