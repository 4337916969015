
import { Component, Vue, Prop, Watch} from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class ActionButton extends Vue {
  @Prop({default:()=> 'btn btn-primary btn-lg'})
  cssClasses:string;
  @Prop({default:()=> 'fa fa-spinner fa-spin'})
  icon:string;
  @Prop({default:()=> 'Submit'})
  value:string;
  @Prop({default:()=> 'Processing'})
  loadingValue:string;
  @Prop({default:()=> false})
  isLoading:boolean;
  @Prop({default:()=> false})
  submit:boolean;

  @Watch('isLoading') loadingChanged(value:boolean, oldValue:boolean){

  }

}
