import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
import store from '@/store';
import router from '@/router';
// tslint:disable-next-line:max-line-length
import { CustomerViewModel, JobDetailsViewModel,ResteJasperCredentialsResult, Reports, TreeItem } from '@/common/models';
class ReleaseNotesService {
  public getTags(): Promise<any> {
    return httpService.get(Resources.getBaseUrl() + `releasenotes/tags`);
  }
  public deleteTag(id:string): Promise<any> {
   return  httpService.delete(Resources.getBaseUrl() + `releasenotes/tags/${id}`,{});
  }
  public addOrUpdateTag(tag:any): Promise<any> {
    if(tag.id) return  httpService.put(Resources.getBaseUrl() + `releasenotes/tags/${tag.id}`,tag);
    return httpService.post(Resources.getBaseUrl() + `releasenotes/tags`,tag);
  }
  public get(): Promise<any> {
    return httpService.get(Resources.getBaseUrl() + `releasenotes/`);
  }
  public getSingle(id): Promise<any> {
    return httpService.get(Resources.getBaseUrl() + `releasenotes/${id}`);
  }
 public getNoteContent(id:string): Promise<any> {
   return httpService.get(Resources.getBaseUrl() + `releasenotes/content/${id}`);
 }
 public saveNoteContent(id:string, content:string): Promise<any> {
  return httpService.put(Resources.getBaseUrl() + `releasenotes/content/${id}`,{content:content});
}


 public update(id:string,note:any): Promise<any> {
  return httpService.put(Resources.getBaseUrl() + `releasenotes/${id}`,note);
}
public getDates(): Promise<Date[]> {
  return httpService.get(Resources.getBaseUrl() + `releasenotes/dates`);
}
public add( note:any): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `releasenotes`,note) 
}
public delete(id:string): Promise<any> {
  return httpService.delete(Resources.getBaseUrl() + `releasenotes/${id}`,{});
}
public publish(id:string): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `releasenotes/publish/${id}`,{});
}
public unPublish(id:string): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `releasenotes/unpublish/${id}`,{});
}




}
export let releaseNotesService = new ReleaseNotesService();
