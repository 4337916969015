
  import { Component, Prop, Vue } from "vue-property-decorator";
  import ComponentBase from "../../common/component.base";
  import { integrationService } from "../../utils/http";

  @Component({
  components: {
  },
})
  export default class Integrations extends ComponentBase {
    @Prop()
  integrationId: string;
   
  isLoading:boolean=false;
    stats:any[]=[]
    created() {
   this.getData()
    
      
    }
    getData(){
      this.isLoading=true;
      integrationService.getStats(this.integrationId).then(result=>{
this.stats=result
this.isLoading=false;
      })
    }
  }
  