
import { Component, Prop, Vue } from "vue-property-decorator";
import VueJsonPretty from "vue-json-pretty";

import {
  CustomerViewModel,
  JobStatus,
  JobType,
  DatabaseStatus,
  Backup,
  JobViewModel
} from "../../common/models";
import LoadCustomer from "../common/LoadCustomer.vue";
import { dbService, httpService } from "../../utils/http";
import CustomerBase from "@/common/customer.base";
import store from "@/store";
import { stat } from "fs";
import { connect } from "tls";
import Resources from "@/common/resources";

@Component({
  components: {
    VueJsonPretty,
    LoadCustomer
  }
})
export default class DbOperations extends CustomerBase {
  @Prop()
  firmId: string;
  jobs: any[] = [];
  outputData:any=null;
  showOutput:boolean=false
  jobDetailsFields = [
    { key: "jobType", sortable: true },
    { key: "jobStatus", sortable: true },
    { key: "start", sortable: true },
    { key: "output", sortable: false }
  ];

  showOutputData(output) {
    this.outputData = output ? JSON.parse(output) : "";
    this.showOutput = !this.showOutput;
  }
  mounted() {
    this.loadJobs()
  }
  loadJobs(){
this.isBusy(true);
    dbService
      .getJobs(this.firmId,true)
      .then(result => {
        this.isBusy(false);
        this.jobs = result;
      })
      .catch(err => this.isBusy(false));
  }
  public jobStatusDisplay(status: JobStatus) {
    switch (status) {
      case JobStatus.Queued:
        return "Queued";
      case JobStatus.Running:
        return "Running";
      case JobStatus.Failed:
        return "Failed";
      case JobStatus.Succeeded:
        return "Succeeded";
      default:
        return "Unknown";
    }
  }
  public jobTypeDisplay(type: JobType) {
    switch (type) {
      case JobType.Update:
        return "Update Db";
      case JobType.Delete:
        return "Delete db from service";
      case JobType.Create:
        return "Create db ";
      case JobType.Backup:
        return "Backup database";
      case JobType.Restore:
        return "Restore database";
      case JobType.PostRestoreCleanup:
        return "Post restore cleanup(Emp license status, credentials etc)";
      default:
        return "Unknown";
    }
  }
   created(){
    //@ts-ignore
    this.$eventHub.on(this.EventNames.JobsChanged,(data:JobViewModel)=>{
         console.log('jobs changed',data)
         console.log('jobs changed',this.firmId)
         if(this.firmId==data.customerId) this.loadJobs()
    });

  }
}
