
  import { Component, Vue, Prop, Watch } from "vue-property-decorator";
  import componentBase from "../../common/component.base";
  import { dbService } from "../../utils/http/db.service";
  import VueJsonPretty from "vue-json-pretty";
  
  @Component({
    components: {
        VueJsonPretty
    },
  })
  export default class extends componentBase {
    @Prop({required:false})
    firmId: string;
    public query: String = null;
    public output: String = null;
    public isLoading:Boolean=false;
    public useSecondary:Boolean=false;
    async created() {
    }
  
   
    async runQuery() {
        this.output=''
    this.isLoading=true;
    var result=this.firmId? await dbService.runQuery(this.firmId,this.query,this.useSecondary): await dbService.runManagerQuery(this.query);
    this.isLoading=false;
    this.output=JSON.parse(result.isSuccess?result.result:result.error);


    }
  }
  