import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
import store from '@/store';
import router from '@/router';
// tslint:disable-next-line:max-line-length
import { CustomerViewModel, JobDetailsViewModel,ClientsViewModel } from '@/common/models';
class ClientPortalService {
 public getFiles(firmId:String): Promise<any> {
   return httpService.get(Resources.getBaseUrl() + `clientportal/files/${firmId}`);
 }
 public getClients(firmId:String): Promise<any> {
  return httpService.get(Resources.getBaseUrl() + `clientportal/clients/${firmId}`);
}
public calculatePermissions(firmId:String): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `clientportal/calculatepermissions/${firmId}`,{
    
  });

  
}
public getUploadLink(firmId:String,clientId:String,name:String, folderPath:String): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `clientportal/uploadlink/${firmId}/${clientId}`,{
    name:name,
    folderPath:folderPath
  });

  
}
public completeUpload(firmId:String,tempFileId:String): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `clientportal/completeupload/${firmId}/${tempFileId}`,{
  });

}
 
}
export let clientPortalService = new ClientPortalService();
