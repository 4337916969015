
import { clientJobsService } from '../../../utils/http'
import VueJsonPretty from "vue-json-pretty";
import JobLog from './JobLog.vue'
import {

    Component,

    Prop,

    Vue

} from "vue-property-decorator";


import moment from 'moment';

import CustomerBase from '@/common/customer.base'

@Component(
    {
        components: {
            VueJsonPretty,
            JobLog
        }
    }
)
export default class ClientJobs extends CustomerBase {
    @Prop()
    firmId: string;

    fields = [
        { key: 'id', sortable: false },
        { key: 'description', sortable: true },
        { key: 'isDismissed', sortable: true },
        { key: 'lastUpdated', sortable: true },
        { key: 'startTime', sortable: true },
        { key: 'status', sortable: true },
        { key: 'type', sortable: true },
        { key: 'userId', sortable: true },
        { key: 'details', sortable: false },
        { key: 'action', sortable: false },
    ];


    filter = null;


    public jobs: any[] = [];



    created() {


        this.getJobs();

    }
    deleteJob(id){
        this.isBusy(true);

clientJobsService.deleteJob(this.firmId,id).then((result) => {

    this.isBusy(false);

   let jobIndex= this.jobs.findIndex(z=>z.id==id);
   if(jobIndex<0) return;
   this.jobs.splice(jobIndex,1)



}).catch((err) => this.isBusy(false))
    }
    getJobs() {

        this.isBusy(true);

        clientJobsService.getJobs(this.firmId).then((result) => {

            this.isBusy(false);

            this.jobs = result;



        }).catch((err) => this.isBusy(false))



    }

}
