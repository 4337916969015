
import { Component, Vue } from 'vue-property-decorator';
import Workspaces from '../../components/powerbi/Workspaces.vue'
import ReportFiles from '../../components/powerbi/ReportFiles.vue'
@Component({
  components: {
      Workspaces,
      ReportFiles
  },
})
export default class WorkspacesView extends Vue {}
