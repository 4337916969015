<template>
  <textarea ref="summernote" id="summernote" />
</template>
<script>
import Vue from "vue";
import $ from "jquery";
import bootstrap from "bootstrap";
import summernote from "summernote";

export default {
  data:()=>{
   return {
     currentValue:''
   }
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  computed: {
    summernote() {
      return $(this.$refs.summernote);
    },
  },
  mounted() {
    this.currentValue=this.value||''
    $(this.$refs.summernote).summernote({
      disableDragAndDrop: false,
      callbacks: {
        onChange: () => {
          this.currentValue=$(this.$refs.summernote).summernote("code");
          this.$emit("change", this.currentValue);
          this.$emit("input", this.currentValue);
          $(this.$refs.summernote).summernote("editor.saveRange");
        },
      },
      height: 700,
      minHeight: 100,
      // toolbar: [
      //   ["style", ["style"]],
      //   ["font", ["bold", "underline", "clear"]],
      //   ["fontname", ["fontname"]],
      //   ["color", ["color"]],
      //   ["para", ["ul", "ol", "paragraph"]],
      //   ["table", ["table"]],
      //   ["insert", ["link", "picture","video"]],
      //   ["view", ["fullscreen", "codeview"]]
      // ]
    });
    $(this.$refs.summernote).summernote("code", this.currentValue);
    $(".dropdown-toggle").dropdown();
  },

  watch: {
    value(newVal,oldValue) {
    if(this.currentValue==newVal) return;
      this.set(newVal);
    },
  },
  methods: {
    code() {
      var data = $(this.$refs.summernote).summernote("code");
      return data;
    },
    set(value) {
      $(this.$refs.summernote).summernote("code", value || "");
    },
    insert(value) {
      console.log("insert", value);
      $(this.$refs.summernote).summernote("editor.saveRange");

      $(this.$refs.summernote).summernote("editor.restoreRange");
      $(this.$refs.summernote).summernote("editor.focus");
      $(this.$refs.summernote).summernote("pasteHTML", value || "");
    },
  },
};
</script>


