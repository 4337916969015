
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  LoginModel,
  TokenResultModel,
  TreeItem,
  Reports
} from "../../common/models";
import { httpService, subscriptionService, listService } from "../../utils/http";
import ActionButton from "../common/ActionButton.vue";
import FormError from "../common/FormError.vue";
import CustomerBase from "@/common/customer.base";

import store from "@/store";

@Component({
  components: {
    ActionButton,
    FormError
  }
})
export default class Events extends CustomerBase {
     @Prop()
  firmId: String;
  features: any[] = [];
  firmFeatures: any[] = [];
 selectedFeatures:any[]=[]
  async created() {
    await this.initialize();
  }
  async initialize() {
    try {
      this.isBusy(true);
      // var data =await rightsService.getRights()
      this.features = await subscriptionService.getFeatureList();
      this.firmFeatures = await subscriptionService.getFirmFeatures(this.firmId);
      this.selectedFeatures= this.firmFeatures.map(z=>z.featureId);
      this.isBusy(false);
    } catch (err) {
      this.isBusy(false);
    }
  }
  async save() {
    try {
      this.isBusy(true);
      var result= await subscriptionService.saveFirmFeatures(this.firmId, this.selectedFeatures)
      this.firmFeatures= result;
      this.selectedFeatures= this.firmFeatures.map(z=>z.featureId);
      this.isBusy(false);
    } catch (err) {
      this.isBusy(false);
    }
  }
}
