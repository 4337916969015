
    import {
    
      Component,
    
      Prop,
    
      Vue
    
    } from "vue-property-decorator";
    
    
    import moment from 'moment';
    
    
    import VueJsonPretty from "vue-json-pretty";

    
    import {
    
        healthCheckService
    
    } from "../../utils/http";
    
    import CustomerBase from '@/common/customer.base'
     
    
    @Component({
      components:{
        VueJsonPretty
      }
    })
    export default class HealthChecks extends CustomerBase {
      @Prop()
      checkId: number;
    
      fields = [
        {key:'typeName',  sortable: true },
        {key:'status',  sortable: true },
        {key:'actions',  sortable: false },
    ]
       
  
        
      filter = null;
    
    
      public healthCheckResults: any[] = [];
    
      
  
      created() {
  
  
        this.getResults();
  
      }
      async runFixes(id){
     let confirmation=await   this.confirm("Confirm","Are you sure you want to run the fixes? This will change the data on the firm")
     if(!confirmation) return; 
     this.isBusy(true);
  
  healthCheckService.runFixes(id).then((result) => {

    this.isBusy(false);


  }).catch((err) => this.isBusy(false))
      }
      getResults() {
  
        this.isBusy(true);
  
        healthCheckService.getCheckResults(this.checkId).then((result) => {
            
          result.forEach(r=>{
            try{
              r.parsedOutput=JSON.parse(r.output);
            }
            catch{
              r.parsedOutput={};
            }
          })
          this.isBusy(false);
  
          this.healthCheckResults = result;
  
  
  
        }).catch((err) => this.isBusy(false))
  
  
  
      }
  
    }
  