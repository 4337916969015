import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
// tslint:disable-next-line:max-line-length
class WorkflowService {
  public startMigration(id:string): Promise<string> {
    return httpService.post(`${Resources.getBaseUrl()}workflow/migrate`,[id]);
  }
  public batchMigrate(ids:string[]): Promise<string> {
    return httpService.post(`${Resources.getBaseUrl()}workflow/migrate`,ids);
  }
  public migrateRights(ids:string[]): Promise<string> {
    return httpService.post(`${Resources.getBaseUrl()}workflow/migraterights`,ids);
  }
  public findAndFixBadRecords(ids:string[]): Promise<string> {
    return httpService.post(`${Resources.getBaseUrl()}workflow/migrationbadrecords`,ids);
  }
 
}
export let workflowService = new WorkflowService();
