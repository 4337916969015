import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
import store from '@/store';
import router from '@/router';
// tslint:disable-next-line:max-line-length
import { CustomerViewModel, JobDetailsViewModel,ResteJasperCredentialsResult, Reports, TreeItem } from '@/common/models';
class EventsService {
 public get(subscriberId:number): Promise<any> {
   return httpService.get(Resources.getBaseUrl() + `events/${subscriberId}`);
 }
 public update(subscriberId:number,events:any[]): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `events/${subscriberId}`,events);
}
public getSubscribers(): Promise<any> {
  return httpService.get(Resources.getBaseUrl() + `events/subscribers`);
}
public addUpdateSubscriber(subscriberId:number, subscriber:any): Promise<any> {
  return subscriberId? httpService.put(Resources.getBaseUrl() + `events/subscribers/${subscriberId||''}`,subscriber) : httpService.post(Resources.getBaseUrl() + `events/subscribers`,subscriber) 
}
public deleteSubscriber(id:string): Promise<any> {
  return httpService.delete(Resources.getBaseUrl() + `events/subscribers/${id}`,{});
}




}
export let eventsService = new EventsService();
