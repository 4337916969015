
  import { Component, Vue, Prop, Watch } from "vue-property-decorator";
  import componentBase from "../../../common/component.base";
  import { dbService } from "../../../utils/http/db.service";
  import VueJsonPretty from "vue-json-pretty";
  
  @Component({
    components: {
        VueJsonPretty
    },
  })
  export default class extends componentBase {
    @Prop({required:true})
    type: string;
    @Prop({required:false})
    initialQuery: string;
    public query: String = '';
    public output: any = null;
    public isLoading:Boolean=false;
    async created() {
        this.query=this.initialQuery||''
        console.log('initial query',this.initialQuery)
    }
  
   
    async runQuery() {
    this.$emit('onQuery',this.query);
    this.output={}
    this.isLoading=true;
    let result={isSuccess:false,result:"",error:""};
    if(this.type=='Manager'){
        result= await dbService.runManagerQuery(this.query);
    }
    else if(this.type=='Portal'){
        result= await dbService.runPortalQuery(this.query);
    }
    else if(this.type=='Admin'){
        result= await dbService.runAdminQuery(this.query);
    }
    else{
        this.isLoading=false;
        alert('Not supported query destination: '  + this.type)
        this.output={error:'Invalid query type'}
    }
    
    this.isLoading=false;

   
    this.output=JSON.parse(result.isSuccess?result.result:result.error);


    }
  }
  