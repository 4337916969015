
    import {
    
      Component,
    
      Prop,
    
      Vue
    
    } from "vue-property-decorator";
    
    import FirmEmails from './FirmEmails.vue'
    import DataStats from './DataStats.vue'
   
    
   
    import CustomerBase from '@/common/customer.base'
     
    
    @Component({
        components:{
            FirmEmails,
            DataStats
        }
    })
    export default class FirmData extends CustomerBase {
    
    }
  