import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig, AxiosPromise } from 'axios';
import store from '@/store';
import router from '@/router';
import { msalPluginInstance } from "@/plugins/msal-plugin";
import { messageService } from '../message.service'

class HttpService {
  private apiClient: AxiosInstance;
  private authTokenRequest: any;
  constructor(withInterceptor: boolean = false) {
    this.apiClient = axios.create();
    if (withInterceptor) {
      this.apiClient.interceptors.response.use(undefined, (err) => {
        const error = err.response;
        
        if (error.status === 401 && error.config) {
          router.push('/accountpicker');
          console.log('error',error)
        }
        else if (error.data && error.data.errorMessage) {
          
          messageService.toast("We couldn't complete the request.", error.data.errorMessage,'danger')
        }
      });
    }
  }
  public async get<T>(url: string): Promise<T> {
    const response = this.apiClient.get(url, await this.getConfig());
    return this.ResponsePipe<T>(response);
  }
  public async post<T>(url: string, data: any,  config: any = null): Promise<T> {
    let finalConfig = await this.getConfig();
    if (config) {
      config.headers =  {...finalConfig.headers, ...config.headers};
      finalConfig = {...finalConfig,...config};
    }
    const response = this.apiClient.post(url, data, finalConfig);
    return this.ResponsePipe<T>(response);
  }

  public async put<T>(url: string, data: any): Promise<T> {
    const response = this.apiClient.put(url, data, await this.getConfig());
    return this.ResponsePipe<T>(response);
  }
  public async patch<T>(url: string, data: any): Promise<T> {
    const response = this.apiClient.patch(url, data, await this.getConfig());
    return this.ResponsePipe<T>(response);
  }
  public async delete<T>(url: string, data: any): Promise<T> {
    const response = this.apiClient.delete(url, await this.getConfig());
    return this.ResponsePipe<T>(response);
  }
  public async download(url: string): Promise<any> {
   var authHeader=await this.getAuthorizationHeaderValue()
    return new Promise((resolve, reject) => {
      this.apiClient({
        url,
        method: 'GET',
        responseType: 'blob',
    onDownloadProgress: ( progressEvent )=> {
      //@ts-ignore
     const percentage = ((progressEvent.loaded/progressEvent.total)*100).toFixed(0)
     store.commit("loadingPercentage", percentage);

    },
        headers: {
          'Authorization': authHeader ,
          'Access-Control-Expose-Headers': 'Content-Disposition',
        },
      })
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'];
        const filename = contentDisposition.split(';')
        .map((z) => {
               const values = z.trim().split('=');
               return {
                  name: values[0],
                   value: values[1]};
                   },
             )
        .find((z) => z.name == 'filename')
        .value;
        resolve({file: response.data, filename});
      }).catch((error) => {
        reject(error);
      });
    });

  }
  public downloadAndSave(url: string): Promise<any> {
    return new Promise((resolve,reject)=>{
      this.download(url).then((ret) => {
        const url = window.URL.createObjectURL(new Blob([ret.file]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', ret.filename);
        document.body.appendChild(link);
        link.click();
        resolve(ret);
      }).catch(err=>reject(err));
    })

  }

  private async getConfig(): Promise<AxiosRequestConfig> {
    return {
      headers: {
        Authorization: await this.getAuthorizationHeaderValue(),
      },
    };
  }

  private async getAuthorizationHeaderValue(): Promise<string> {
    return `Bearer ${await msalPluginInstance.acquireToken()}`
  };


  private ResponsePipe<T>(response: AxiosPromise<any>): Promise<T> {
    return new Promise((resolve, reject) => {
      response.then((result: AxiosResponse<T>) => {
       
        resolve(result.data);
      }).catch((error) => {
        reject(error);
      });
    });
  }
 private replaceAll(str, find, replace): string {
    return str.replace(new RegExp(find, 'g'), replace);
}
}
export let httpService = new HttpService(true);
export let httpServiceWithoutInterceptor = new HttpService();
export enum RequestMethods {
  get = 1,
  post = 2,
  put = 3,
  delete = 4,
  patch = 5,
}
