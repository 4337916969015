
  import { Component, Prop, Vue } from "vue-property-decorator";
  import ComponentBase from "../../common/component.base";
import IntegratedFirmsList from './IntegratedFirmsList.vue'
  import { integrationService } from "../../utils/http";
  
  @Component({
  components: {
    IntegratedFirmsList
  },
})
  export default class Integrations extends ComponentBase {
   
    public showIntegratedAccounts:boolean=false;
  
    public integrations: any[] = [];
    public selectedFirmIntegrations:any[]=[]
    
    
    created() {
      this.getData();
    
      
    }
   async markIntegrateablesForReconnect(id:string){
      let confirmation= await this.confirm("Confirmation","Are you sure you want to proceed. All the entegrations for this type will be marked unhealthy and needing reconnect.")
    if(!confirmation) return;
    this.isBusy(true);
    integrationService.markForReconnect(id).then(result=>{
      this.getData();
    }).catch(err=>{
      this.isBusy(false)
      this.info("Error","Something went wrong during the request")
    })
    }
    showIntegrationsForIntegrateable(id:string){
      this.selectedFirmIntegrations=[]
      let integrateable= this.integrations.find(z=>z.id==id);
      if(!integrateable) return;
      this.selectedFirmIntegrations=integrateable.integrations;
      this.showIntegratedAccounts=true;
    }
    getData() {
        this.isBusy(true)
      integrationService
        .getIntegrations()
        .then(result => {
          this.isBusy(false);
        
          this.integrations = result;
        })
        .catch(err => this.isBusy(false));
    }
  }
  