
import { clientStatssService } from '../../utils/http'
import {

    Component,

    Prop,

    Vue

} from "vue-property-decorator";


import moment from 'moment';

import CustomerBase from '@/common/customer.base'

@Component(
    {
        components: {
            
        }
    }
)
export default class ClietPortalTenantStats extends CustomerBase {
    @Prop({required:false})
    firmId: string;


    fields = [
        { key: 'name', sortable: true },
        { key: 'nrOfClientFiles',label:'Client Files', sortable: true },
        { key: 'nrOfFirmFiles',label:'Firm Files', sortable: true },
        { key: 'nrOfFolders',label:'Folders', sortable: true },
        { key: 'totalSize',label:'Space', sortable: true },
        { key: 'nrOfUsers',label:'Users', sortable: true },
        { key: 'numberOfClientsWithFiles',label:'Clients with files', sortable: true },
        { key: 'numberOfRoles', label:'Roles',sortable: true },
        { key: 'totalClientDownloadSize', label:'Client Download Bandwidth', sortable: true },
        { key: 'totalClientUploadSize', label:'Client Upload Bandwidth', sortable: true },
    ];


    filter = null;


    public stats: any[] = [];



    created() {


        this.getStats();

    }
    calclateStats(){
        this.isBusy(true);

        clientStatssService.calclateStats().then((result) => {

            this.isBusy(false);
            this.info('Stats calculation started','Wait for the job to finish and then refresh the stats.')
        }).catch((err) => this.isBusy(false))
    }
    getStats() {

        this.isBusy(true);

        clientStatssService.getStats(this.firmId).then((result) => {

            this.isBusy(false);

            this.stats = result;



        }).catch((err) => this.isBusy(false))



    }

}
