
import { Component, Prop, Vue } from "vue-property-decorator";
import utils from '../../utils/miscellaneous'
import {
  releaseNotesService
} from "../../utils/http";
import CustomerBase from "@/common/customer.base";
import {  Chrome} from 'vue-color'
@Component({
  components: {
   Chrome
  }
})
export default class TagAddEdit extends CustomerBase {
  @Prop({required:false})
  value: any;
   @Prop({required:false,default:false})
  isAddOnly: boolean;
  tag:any
  popoverShow:boolean=false;
  id=utils.uuidv4()

  async created() {
   if(!this.value){
       this.tag={
           name:'New Tag',
           color:''
       }
   }
   else this.tag=this.value
  }
 async deleteTag(){
try {
      this.isBusy(true);
     await releaseNotesService.deleteTag(this.tag.id);
     this.$emit('tagdeleted',this.tag)
     this.popoverShow=false;
      this.isBusy(false);
    } catch (err) {
      this.isBusy(false);
    }
 }
  async save(event:Event) {
      event.preventDefault();
    try {
      this.isBusy(true);
     await releaseNotesService.addOrUpdateTag(this.tag);
     this.$emit('tagupdated',this.tag)
     this.popoverShow=false;
      this.isBusy(false);
    } catch (err) {
      this.isBusy(false);
    }
  }
 
}
