import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
class ClientContactService {
 public getContactTypes(firmId:string): Promise<any[]> {
   return httpService.get(Resources.getBaseUrl() + `contacts/types/${firmId}`);
 }
 public getContacts(firmId:string,type:string=''): Promise<any[]> {
  return httpService.get(Resources.getBaseUrl() + `contacts/clientcontacts/${firmId}?contactType=${type||''}`);
}
public updateContactType(firmId:string,fromType:string='',toType:string=''): Promise<any[]> {
  return httpService.post(Resources.getBaseUrl() + `contacts/changetype/${firmId}`,{fromType:fromType,toType:toType});
}
}
export let clientContactService = new ClientContactService();
