
import ComponentBase from '@/common/component.base';
import { Component, Vue } from 'vue-property-decorator';
import Subscriptions from '../../components/subscriptions/Subscriptions.vue'
@Component({
  components: {
      Subscriptions
  },
})
export default class SubscriptionView extends ComponentBase {}
