export enum JobType {
    
    Update = 1,
    Delete=2,
    Create=3,
    Backup=4,
    Restore = 5,
    RestoreConversion = 6,
    PostRestoreCleanup=7,
    ClientTemplateSync=8,
    DefaultTemplateSync=9

}
export enum ActivityLogActionType{
    Create=1,
    Update=2,
    Delete=3
  }
export enum JobStatus {
    
   
    Queued = 1,
    Running = 2,
    Failed = 3,
    Succeeded = 4

}
export enum ManagerActivityLogPropertyType
{
    Text=1,
    Html=2,
    Date=3,
    Number=4,
    LogProperties=5
}