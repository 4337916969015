
import { Component, Vue } from 'vue-property-decorator';
import ReleaseNotes from '../../components/ReleaseNotes/ReleaseNotes.vue'
@Component({
  components: {
      ReleaseNotes
  },
})
export default class ReleaseNotesView extends Vue {
  

}
