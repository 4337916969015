
import router from "@/router";

import { Component, Vue } from 'vue-property-decorator';
@Component({
  components: {
      
  },
})
export default class HomeView extends Vue {
  search: string = '';
  adminRoutes: any[] =[];public canViewLink(name: String){
   var route= this.adminRoutes.find(z=>z.path == name)
   if(!route) return;
   return route.meta.canNavigate == null || route.meta.canNavigate()
  }
  
  created() {
    
    // @ts-ignore
  this.adminRoutes = router.options.routes.find(z => z.path == '/admin').children.filter(z=>z.meta && (z.meta.canNavigate ==null || z.meta.canNavigate()));

  
  }
  get filteredRoutes(){
    if(!this.search) return this.adminRoutes;
    return this.adminRoutes.filter(z=> z.meta?.title?.toLowerCase().includes(this.search.toLowerCase()))
  }
}
