
import { Component, Prop, Vue,Watch } from "vue-property-decorator";

import { subscriptionService } from "../../utils/http";
import CustomerBase from "@/common/customer.base";

import Summernote from "../common/Summernote.vue";

@Component({
  components: {
    Summernote
  },
})
export default class SubscriptionVisualization extends CustomerBase {
  @Prop({ required: true })
  subscriptionLockId: string;
    showPreview= false;
  subscriptions: any[] = [];
 
  selectedSubscription: any = null;
 
 
  @Watch('subscriptionLockId') subscriptionLockChanged(value:string, oldValue:string){
    this.selectedSubscription=null;
  this.showPreview=false;
  this.initialize();

}
  async created() {
    await this.initialize();
  }
  get canEdit(){
  return this.hasAnyRight([this.RightIds.SubscriptionVisual])
}
  async initialize() {
    this.isBusy(true);
    var data = await subscriptionService.getSubscriptions(this.subscriptionLockId);

    this.subscriptions = data.subscriptions.map((z) => ({
      id: z.id,
      name: z.name,
      price: z.price,
      billingCycle: z.billingCycle,
      isHistory: z.isHistory,
      displayText:'',
      originalDisplayText:''
    }));
    this.isBusy(false);
   
    if (!this.selectedSubscription)
      this.setCurrentSubscription(this.subscriptions[0].id);
  }
  async setCurrentSubscription(id){
      if(this.selectedSubscription){
        if(this.selectedSubscription.id==id) return;
        if(this.selectedSubscription.originalDisplayText!=this.selectedSubscription.displayText){
            var confirmUnsavedChanges=await this.confirm("Unsaved changes","You have unsaved changes. Do you want to proceed without saving");
            if(!confirmUnsavedChanges) return;
        }
      }
    this.selectedSubscription= this.subscriptions.find(z=>z.id==id)
    var text= await subscriptionService.getSubscriptionDisplayText(id);
    this.selectedSubscription.displayText=text
    this.selectedSubscription.originalDisplayText=text;
  }
  async save(id) {
    this.isBusy(true);
    let subscription= this.subscriptions.find(z=>z.id==id);
  
    await subscriptionService.saveSubscriptionDisplayText(id,subscription.displayText);
    subscription.originalDisplayText=subscription.displayText;
    this.isBusy(false);
  }
  getSubscriptionPreview(){
    console.log('this.selectedSubscription',this.selectedSubscription)
    let text=this.selectedSubscription.displayText;
    text=text.replace("{{name}}", this.selectedSubscription.name)
    text=text.replace("{{price}}", this.selectedSubscription.price)
    text=text.replace("{{cycle}}", this.selectedSubscription.billingCycle)
    return text;
  }
}
