import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
import store from '@/store';
import router from '@/router';
// tslint:disable-next-line:max-line-length
import { CustomerViewModel, JobDetailsViewModel,ResteJasperCredentialsResult, Reports, TreeItem, FinancialReportViewModel, ElavonSubscriptionViewModel } from '@/common/models';
class BillingService {
 public getMonthlyReport(date): Promise<FinancialReportViewModel[]> {
   return httpService.get(Resources.getBaseUrl() + `financial/monthlyreport?date=${date}`);
 }
 public getElavonSubscription(id:string): Promise<ElavonSubscriptionViewModel> {
  return httpService.get(Resources.getBaseUrl() + `financial/subscription/${id}`);
}
public refreshBillDate(id:string): Promise<string> {
  return httpService.post(Resources.getBaseUrl() + `financial/refreshnextpaymentdate/${id}`,{});
}
public refreshExpireDate(id:string): Promise<string> {
  return httpService.post(Resources.getBaseUrl() + `financial/refreshexpiredate/${id}`,{});
}

 



}
export let billingService = new BillingService();
