
  import { Component, Prop, Vue } from "vue-property-decorator";
  import ComponentBase from "@/common/component.base";
  import { couponService } from "../../utils/http";
  import CreateCoupon from './CreateCoupon.vue'
  import EditCoupon from './EditCoupon.vue'
  import CouponHistory from './CouponHistory.vue'
  
  @Component({
    components: {
        CreateCoupon,
        EditCoupon,
        CouponHistory
    }
  })
  export default class Coupons extends ComponentBase {
   
    includeInactive:boolean=false;
    filter:string='';
    showNewCouponModal:boolean=false;
    showEditCouponModal:boolean=false;
    showCouponHistoryModal:boolean=false;
    editingCouponId:number=null;
    showHistoryCouponId:number=null;
    fields = [
      
      { key: "actions", sortable: false,label:'' },
      { key: "isDeleted", sortable: true,label:'Status' },
      { key: "code", sortable: true,label:'Coupon' },
      { key: "typeName", sortable: true,label:'Type' },
      { key: "description", sortable: true,label:'Description' },
      { key: "createdOn", sortable: true,label:'Created On' },
      { key: "expireDate", sortable: true,label:'Expires On' },
      { key: "trialDays", sortable: true,label:'Trial Days' },
      { key: "maxUseage", sortable: true,label:'Max Useage' },
      { key: "currentUseage", sortable: true,label:'Current Useage' },
      { key: "userEmail", sortable: true,label:'User' },
      
    ];
  
    public coupons: any[] = [];
  
    
    get canEdit(){
        return   this.hasAnyRight([this.RightIds.EditCoupons])

    }
    created() {
      this.getData();
    
      
    }
    couponUpdated(coupon){
        this.showEditCouponModal=false;
        this.editingCouponId=null;
        this.getData();
    }
    couponAdded(coupon){
        this.showNewCouponModal=false;
        this.coupons.push(coupon);
    }
    addCoupon(){
    this.showNewCouponModal=true;
    }
    editCoupon(id:number){
    this.showEditCouponModal=true;
    this.editingCouponId=id;
    }
    showCouponHistory(id:number){
    this.showCouponHistoryModal=true;
    this.showHistoryCouponId=id;
    }
    toggleStatus(id:number,isActive:boolean){
        this.isBusy(true);
        couponService.toggleStatus(id,isActive).then(result=>{
            this.isBusy(false);
            this.getData();
        }).catch(err=>this.isBusy(false));
    }
    get couponList(){
        if(this.includeInactive){
            return this.coupons;
        }
        return this.coupons.filter(x=>!x.isDeleted);
    }
    getData() {
        this.isBusy(true)
      couponService
        .getCoupons()
        .then(result => {
          this.isBusy(false);
        
          this.coupons = result;
        })
        .catch(err => this.isBusy(false));
    }
  }
  