
import { Component, Prop, Vue } from "vue-property-decorator";
import VueJsonPretty from "vue-json-pretty";
import { ShareFileClient } from "@azure/storage-file-share";

import {
  CustomerViewModel,
  JobStatus,
  JobType,
  DatabaseStatus,
  Backup,
  JobViewModel,
} from "../../common/models";
import LoadCustomer from "../common/LoadCustomer.vue";
import { dbService, httpService } from "../../utils/http";
import CustomerBase from "@/common/customer.base";
import store from "@/store";
import { stat } from "fs";
import { connect } from "tls";
import Resources from "@/common/resources";

@Component({
  components: {
    VueJsonPretty,
    LoadCustomer,
  },
})
export default class DbOperations extends CustomerBase {
  @Prop()
  firmId: string;
  backups: any[] = [];
  backupFields = [
    { key: "date", sortable: true },
    { key: "version", sortable: true },
    { key: "description", sortable: true },
    { key: "actions", sortable: false },
  ];
  mounted() {
    this.loadBackups();
  }
  backup() {
    this.isBusy(true);
    dbService.backup(this.firmId).then(result => {
      this.isBusy(false);
    });
  }
  restoreCrossFirm(backup: Backup) {
    var firmid = prompt("Enter firm ID(The firm GUID)");
    if (!firmid)
      return this.info("Not firm specified", "Please specify a firm first");
    this.multiConfim("Are you really sure?", [
      "Are you sure you want to perform this operation. If you enter the wrong firm id their data will be lost",
      "One last chance to back out",
    ]).then((result) => {
      if (result) {
        dbService
          .crossFirmRestore(firmid, backup.id)
          .then((output) => {
            this.isBusy(false);
          })
          .catch((err) => {
            this.isBusy(false);
            this.info(
              "Something went wrong",
              "Something went wrong during restoration job"
            );
          });
      }
    });
  }
  restoreDb(backup: Backup) {
    var message = this.confirm(
      `${
        backup.isConversion
          ? "This is a conversion db. Make sure to execute it only on intended firm"
          : "This will restore the db to this backup, if the backup is not up to date with the code or with app requirements this firm could fail to work on certain areas"
      }`,
      "Are you sure you want to perform this operation. This will restore the db to this version. This operation cannot be undone"
    ).then((result) => {
      if (result) {
        this.confirm(
          "Double checking still going to do it?",
          "If you are sure then go ahead and click OK"
        ).then((result2) => {
          if (result2) {
            this.isBusy(true);
            dbService.restore(backup.id).then((result) => {
              this.isBusy(false);
            });
          }
        });
      }
    });
  }
  loadBackups() {
    this.isBusy(true);
    dbService
      .getBackups(this.firmId)
      .then((result) => {
        this.isBusy(false);
        this.backups = result;
      })
      .catch((err) => this.isBusy(false));
  }
  backupFileChanged(event) {
    var files = event.target.files;
    if (files.lenght == 0) return;
    if (files.length > 1) return;
    this.uploadBackupFile(files[0]);
    // @ts-ignore
    this.$refs.backupFileUpload.value = "";
  }
  async uploadBackupFile(file: File) {
    try {
      var isConversion = await this.yesno(
        "Backup type.",
        "IS this a conversion db. Pleease chooose carefully. This will determine the type of restore."
      );

      this.isBusy(true);

      //create backup
      var backup = await httpService.post<any>(
        `${Resources.getBaseUrl()}backups/create/${
          this.firmId
        }?isConversion=${isConversion}`,
        {}
      );
      //create sharedaccesssignature token
      var sharedAccessSignature = await httpService.get<string>(
        `${Resources.getBaseUrl()}backups/uploadlink/${
          backup.id
        }`
      );

      const fileClient = new ShareFileClient(sharedAccessSignature);

      await fileClient.uploadData(file, {
        onProgress: (progress) => {
          var percentCompleted = (
            (progress.loadedBytes / file.size) *
            100
          ).toFixed(0);
          this.isBusy(true, percentCompleted);
        },
      });
      this.isBusy(false);
      this.loadBackups();
    } catch (err) {
      this.isBusy(false);
      this.info('Error',"Something went wrong, pls try again, if the backup record is created please delete it as it won't have any file attached")
      console.log("err", err);
    }
  }
  downloadBackup(id: string, noAzureCompatible: boolean) {
    this.isBusy(true);
    dbService
      .downloadBackup(id, noAzureCompatible)
      .then((ret) => {
        this.isBusy(false);
      })
      .catch((err) => this.isBusy(false));
  }
  updateBackup(backup: Backup) {
    this.isBusy(true);
    dbService.updateBackup(backup).then((result) => {
      this.isBusy(false);
    });
  }
  deleteBackup(backup: Backup) {
    this.confirm(
      "Just making sure",
      "Are you sure you want to perform this operation. This will delete the backup and it can't be undone"
    ).then((result) => {
      if (!result) return;
      this.isBusy(true);
      dbService.deleteBackup(backup.id).then((result) => {
        this.isBusy(false);
        this.backups.splice(
          this.backups.findIndex((z) => z.id == backup.id),
          1
        );
      });
    });
  }
  uploadBackup() {
    //@ts-ignore
    this.$refs.backupFileUpload.click();
  }

  created() {
    //@ts-ignore
    this.$eventHub.on(this.EventNames.BackupsChanged, (data: JobViewModel) => {
      if (this.firmId == data.customerId) this.loadBackups();
    });
  }
}
