
/**
 * Trim string and remove all string occurences that appear more than once
 *
 * usage: {{ value | trim }}
 */
import Vue from 'vue'
Vue.filter('nicebytes', function (val) {
  return niceBytes(val)
})

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
   
function niceBytes(x){

  let l = 0, n = parseInt(x, 10) || 0;

  while(n >= 1024 && ++l){
      n = n/1024;
  }
  
  return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}