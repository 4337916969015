
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import componentBase from "../../common/component.base";
import { powerBIService } from "../../utils/http/powerbi.service";
import * as pb from "powerbi-client";

@Component({
  components: {},
})
export default class extends componentBase {
  @Prop()
  firmId: String;
  @Prop()
  reportId: String;
  config=null;
 
  async fetchData() {
    this.config = await powerBIService.getPowerBIEmbedConfig(this.firmId,this.reportId);
     if(this.config.isUpdating){
      return this.info("Cannot preview","Report is being updated please check back later")
     }
     let tokenFilter = {
            $schema: "http://powerbi.com/product/schema#basic",
            filterType: 1,
            target: {
              table: "Data",
              column: "PowerBIFilterId",
            },
            operator: "In",
            values: [this.config.filterTokenId],
            displaySettings: {
              isHiddenInViewMode: false,
              displayName: "PowerBIFilterId",
            },
          };
          
          const embedConfig = {
            type: "report",
            tokenType: pb.models.TokenType.Embed,
            accessToken: this.config.token,
            embedUrl: this.config.embedURL,
            filters: [tokenFilter],
            settings: {
              // filterPaneEnabled: true,
              // navContentPaneEnabled: true
            },
          };
          //@ts-ignore
          powerbi.embed(
            document.getElementById("reportContainer"),
            embedConfig
          );
  } 
  created(){
    this.fetchData();
  }

  
}
