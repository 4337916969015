
import { Component, Prop, Vue } from "vue-property-decorator";
import VueJsonPretty from "vue-json-pretty";
import FirmFeatures from './FirmFeatures.vue'
import ChangeFirmName from './ChangeFirmName.vue'
import BatchQuery from './BatchQuery.vue';
import FindAnReplaceVariables from './FindAnReplaceVariables.vue';
import SubscriptionDetails from './SubscriptionDetails.vue';
import ActivityLogList from '../activitylog/ActivityLogList.vue';
import ManagerActivityLogList from '../manageractivityLog/ActivityLogList.vue';
import PowerBIView from '../powerbi/PowerBIView.vue'
import ClientPortalView from '../portal/ClientPortalView.vue'
import FirmUsers from './FirmUsers.vue'
import ClientJobs from './clienttasks/Tasks.vue'
import PortalStats from '../portalstats/PortalStats.vue'
import CompanionRequests from '../CompanionRequest/RequestList.vue'
import DeletionStatus from "./DeletionStatus.vue";
import Workflow from "./Workflow.vue";
import {
CustomerViewModel,
DBDeletionStatus
} from "../../common/models";
import LoadCustomer from "../common/LoadCustomer.vue";
import Backups from "./Backups.vue";
import ConnectionDetails from "./ConnectionDetails.vue";
import Jobs from "./Jobs.vue";
import { dbService, subscriptionService } from "../../utils/http";
import CustomerBase from "@/common/customer.base";
import ClientContacts from './tools/ClientContacts.vue'
import QueryRunner from "./QueryRunner.vue";
import DataIntegrityView from './DataIntegrity/DataIntegrityView.vue'
import HealthChecks from '../healthcheck/HealthChecks.vue'
import BidirectionalEmail from './BidirectionalEmail.vue'
import DataStats from '../firmdata/DataStats.vue'
@Component({
  components: {
    DataStats,
    DeletionStatus,
    BidirectionalEmail,
    HealthChecks,
    DataIntegrityView,
    PortalStats,
    FirmUsers,
    QueryRunner,
    ClientContacts,
    ManagerActivityLogList,
    SubscriptionDetails,
    ActivityLogList,
    VueJsonPretty,
    LoadCustomer,
    Backups,
    Jobs,
    ConnectionDetails,
    FirmFeatures,
    ChangeFirmName,
    BatchQuery,
    PowerBIView,
    ClientPortalView,
    FindAnReplaceVariables,
    ClientJobs,
    CompanionRequests,
    Workflow
  }
})
export default class DbOperations extends CustomerBase {
  @Prop()
  firmId: string;

  firm:CustomerViewModel=null;
  constructor() {
    super();
  }

  get isDeletedOrDeleting(){
    if(!this.firm) return;
    return this.firm.dbDeletionStatus== DBDeletionStatus.Deleted || this.firm.dbDeletionStatus== DBDeletionStatus.IsDeleting
  }
created(){
  this.loadfirm();
}
executeBatchQuery(queryId){
    this.isBusy(true);
    dbService
        .executeBatchQuery(queryId, [this.firm.id])
        .then(result => {
          this.isBusy(false);
          this.info('Started','View results on the jobs in progress')
        })
        .catch(err => this.isBusy(false));
  
}
  
  public hasRunningJob() {
    return this.firm.runningJobs > 0;
  }
  public runningJobsCount() {
    return this.firm.runningJobs;
  }
  async schedulePriceUpdate(){
   let confirmation= await this.confirm('Confirmation','Are you sure you want to update the subscription price for this firm?')
   if(!confirmation) return;
   subscriptionService.schedulePriceUpdateByFirms([this.firm.id]).then(result=>{
     this.info('Success','Price update scheduled successfully')
   })
  }


 setToAllowExtension(){
   this.confirm(
      "Just making sure",
      "Are you sure you want to perform this operation. This will update the firm duedates and allow extensions for all of them. It cannot be undone"
    ).then(result => {
      if (result) {
        this.isBusy(true);
        dbService.fixOldDueDates(this.firm.id).then(output => {
          this.isBusy(false);
          this.info("Success", "Duedates were updated successfully");
        });
      }
    });
}
  
  fixWipMarkup() {
    this.confirm(
      "Just making sure",
      "Are you sure you want to perform this operation. This will upate any invoice that might have issues with markup"
    ).then(result => {
      if (result) {
        this.isBusy(true);
        dbService.fixWipMarkup(this.firm.id).then(output => {
          this.isBusy(false);
          this.info("Success", "A job has been started, follow it in the jobs section");
        });
      }
    });
  }
  startUpdate() {
    this.confirm(
      "Just making sure",
      "Are you sure you want to perform this operation. This will update the firms database to the latest database stored in the db deploy folder"
    ).then(result => {
      if (result) {
        this.isBusy(true);
        dbService.updateCustomers([this.firm.id]).then(result => {
          this.isBusy(false);
          this.$emit("updated", this.firm.id);
        });
      }
    });
  }
  loadfirm(){
    this.isBusy(true);
    dbService.getTenant(this.firmId).then(result=>{
      this.isBusy(false);
      this.firm=result;
    }).catch(err=>{
      this.isBusy(false);
      this.info('Error','Error loading firm details')
    })
  }
  refreshCustomer() {
    this.$emit("refresh", this.firm.id);
  }
}
