
import { Component, Prop, Vue } from "vue-property-decorator";

import { firmUSersService } from "../../utils/http";
import ActionButton from "../common/ActionButton.vue";
import FormError from "../common/FormError.vue";
import CustomerBase from "@/common/customer.base";

import store from "@/store";

@Component({
    components: {
        ActionButton,
        FormError
    }
})
export default class Events extends CustomerBase {
    @Prop()
    firmId: String;
    users: any[] = [];
    employees: any[] = [];
    newUserEmail: String = '';
    addAsCompanion: boolean = false;
    selectedEmployeeId:string='';

    async created() {
        
        await this.initialize();

    }

    async initialize() {
        try {
            this.isBusy(true);

            this.users = await firmUSersService.getUsers(this.firmId,1);

            this.isBusy(false);
        } catch (err) {
            this.isBusy(false);
        }
    }
    async addUser() {
        try {
            if(this.addAsCompanion){
                let confirmation = await this.confirm('Confirmation', "Are you sure you want to add this user. They will be able to login as the employee you selected");
            if (!confirmation) return;
            }
            else{
                let confirmation = await this.confirm('Confirmation', "Are you sure you want to add this user. They will be able to manage this firm as an administrator");
            if (!confirmation) return;
            }
           
            await firmUSersService.addUser(this.firmId, this.newUserEmail,this.addAsCompanion?6:1, this.selectedEmployeeId??'');
          this.newUserEmail=''
            this.isBusy(false);
            this.initialize();
        } catch (err) {
            this.isBusy(false);
        }
    }
    async removeUser(id) {
        let confirmation =await this.confirm("Confirm!", "Are you sure you want to remove this user. They won't be able to manage the firm anymore");
        if (!confirmation) return;
        await firmUSersService.removeFirmUser(id);
        this.initialize();
    }
    async removeLoginFlowRestriction(id){
        let confirmation =await this.confirm("Confirm!", "Are you sure you want to remove login restriction from user. They will be set to use the default login flow but if the firm requires them to use multifacor they must set it up on their next login");
        if (!confirmation) return;
        await firmUSersService.removeUserLoginFlow(id);
        this.initialize();
    }
}
