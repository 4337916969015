
    import {
    
      Component,
    
      Prop,
    
      Vue
    
    } from "vue-property-decorator";
    
    
    
    
    import {
    
      dataIntegrityService
    
    } from "../../../utils/http";
    import CustomerBase from "@/common/customer.base";

    @Component({
    })
    
    export default class DuplicateEmployeeDueDates extends CustomerBase {
        @Prop()
  firmId: String;
    
    
      public data: any[] = [];
    
      
  
      created() {
  
  
  
      }
   
      getData() {
  
        this.isBusy(true);
       
        dataIntegrityService.getDuplicateEmployeeDueDates(this.firmId).then((result) => {
       
          this.isBusy(false);
          
          this.data = result;
  
  
  
        }).catch((err) => this.isBusy(false))
  
  
  
      }
     async fixDuplicateRecords(){
      
        let confirmation= await this.confirm('Confirmation',`Are you sure you want to merge these duedate records?`);
        if(!confirmation) return;
        this.isBusy(true);
       
       dataIntegrityService.fixDuplicateEmployeeDueDates(this.firmId).then((result) => {
      
         this.isBusy(false);
         
        this.getData();
      })
    }
  
    }
  