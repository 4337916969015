
import { Component, Prop, Vue } from "vue-property-decorator";
import VueJsonPretty from 'vue-json-pretty'

import {
  CustomerViewModel, JobStatus, JobType
} from "../../common/models";
import LoadCustomer from '../common/LoadCustomer.vue'
import { httpService } from "../../utils/http";
import CustomerBase from '@/common/customer.base'
import store from '@/store'
import ActionButton from '../common/ActionButton.vue'
import Resources from '@/common/resources';

@Component({
  components:{
   ActionButton
  }
})
export default class DbFile extends CustomerBase {
 isLoading=false;
 file = null;

 public update(){
    var data= new FormData()
    
   data.append("file", this.file, this.file.name);
   console.log(data)
   this.isLoading=true
  httpService.post(Resources.getBaseUrl() + `dbfiles/updatedbfile`, data, {
      headers: {
          'Content-Type': 'multipart/form-data'
        }
  }).then(result=>{
  this.isLoading=false
  })
 }
 
 
}
