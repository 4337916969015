import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
import store from '@/store';
import router from '@/router';
// tslint:disable-next-line:max-line-length
import { CustomerViewModel, JobDetailsViewModel,ResteJasperCredentialsResult, Reports, TreeItem } from '@/common/models';
class RightsService {
 public getRights(userId:number): Promise<any> {
   return httpService.get(Resources.getBaseUrl() + `rights/${userId}`);
 }
 public update(userId:number,rights:any[]): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `rights/${userId}`,rights);
}




}
export let rightsService = new RightsService();
