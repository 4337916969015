import {httpService} from '@/utils/http';
import Resources from '@/common/resources';



// tslint:disable-next-line:max-line-length
import { CustomerViewModel, JobDetailsViewModel,ClientsViewModel } from '@/common/models';
class PowerBIService {
 public getPowerBIConfig(firmId:String): Promise<any> {
   return httpService.get(Resources.getBaseUrl() + `powerbireports/${firmId}`);
 }
 public savePowerBIConfig(firmId:String, data:any): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `powerbireports/${firmId}`,data);
}
public schedulePowerBiJob(firms:String[],jobType:Number): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `powerbireports/schedulejob/${jobType}`,firms);
}
public scheduleMultiUpdate(firms:String[]): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `powerbireports/scheduleupdate`,firms);
}
public getJobs(firms:String[]): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `powerbireports/powerbiJobs`,firms);
}
public deleteJob(id): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `powerbireports/powerbiJobs/cancel/${id}`,{});
}
public getWorkspaces(): Promise<any> {
  return httpService.get(Resources.getBaseUrl() + `powerbireports/workspaces`);
}
public getFiles(): Promise<any> {
  return httpService.get(Resources.getBaseUrl() + `powerbifiles`);
}
public getPowerBIEmbedConfig(firmId:String, reportId:String): Promise<any> {
  return httpService.get(Resources.getBaseUrl() + `powerbireports/embedconfig/${firmId}/${reportId}`);
}
public updatePowerBiFile(name:String,file:any){
    var data= new FormData()
    
    data.append("file", file, file.name);
   return httpService.post(Resources.getBaseUrl() + `powerbifiles/updatefile?name=${name}`, data, {
       headers: {
           'Content-Type': 'multipart/form-data'
         }
   })
}
}
export let powerBIService = new PowerBIService();
export const jobTypes={
  Create : 1,
  Update: 2,
  Delete:3,
  SyncReports:4
}
