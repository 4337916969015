

import { Component, Vue } from "vue-property-decorator";
import Tasks from "@/components/BackgroundTasks/BackgroundTasks.vue";
import Services from './home/HomeView.vue'
import ComponentBase from "@/common/component.base";

@Component({
  components: {
    Services,
    Tasks
  }
})
export default class AdministrationView extends ComponentBase {
  showServicesModal: boolean = false;
}
