import {httpService} from '@/utils/http';
import queryStringUtils from '../../utils/httpquery.service'

import Resources from '@/common/resources';
export default  {
    getByFirm(firmId){
        
        return httpService.get(`${Resources.getBaseUrl()}manageractivitylog/byfirm/${firmId}`)
      }
     
}
