
import { Component, Prop, Vue } from 'vue-property-decorator';
import {LoginModel,TokenResultModel} from '../common/models'
import {httpService,accountService} from '../utils/http'
import ActionButton from './common/ActionButton.vue'
import FormError from './common/FormError.vue'
import router from '../router'
import store from '@/store'
@Component({
  components: {
    ActionButton,
    FormError
  },
})
export default class Login extends Vue{
       async login() {
         //@ts-ignore
     await  this.$msal.signIn();
      
     
    }
 async mounted() {
     //@ts-ignore
    if( this.$msal.isAuthenticated){
     this.$router.push('accountpicker')
    }
    else {
      this.login();
    }
  }
    
}
