import Vue from 'vue'
import store from '@/store'
import ComponentBase from '@/common/component.base'

import {
    CustomerViewModel
  } from "@/common/models";
export default class CustomerBaseBase extends ComponentBase {
 
  

}