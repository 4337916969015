import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
// tslint:disable-next-line:max-line-length
class ClientStatssService {
 public getStats(firmId:string=null): Promise<any[]> {
   return httpService.get(Resources.getBaseUrl() + `clientportal/stats${firmId ? `/${firmId}` : ''}`);
 }
 public calclateStats(){
  return httpService.post(Resources.getBaseUrl() + `clientportal/stats/calculate`,{});
 }
}
export let clientStatssService = new ClientStatssService();
