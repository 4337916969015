
  import { Component, Prop, Vue } from "vue-property-decorator";
  import ComponentBase from "@/common/component.base";
  import { companionRequestService,firmUSersService } from "../../utils/http";
  
  @Component({
    components: {
       
    }
  })
  export default class CompanionRequest extends ComponentBase {
    @Prop()
    firmId: String;
    employeeId:String='';
    showAddUI:Boolean=false;
    reason:String=''
    addAdministrator:boolean=false;
    fields = [
      
      { key: "actions", sortable: false,label:'' },
      { key: "appliedOn", sortable: true,label:'Applied' },
      { key: "createdOn", sortable: true,label:'Created' },
      { key: "firmId", sortable: true,label:'Firm' },
      
    ];
  
    public requests: any[] = [];
    public employees: any[] = [];
  
    
   
    async created() {
      this.getData();
      this.employees = await firmUSersService.getEmployees(this.firmId);

      
    }
    redeemRequest(id:String){
     companionRequestService.redeem(id).then(res=>{
      this.getData()
     }).catch(err=>{
      alert('Something went wrong')
     })
    }
    deleteRequest(id:String){
      companionRequestService.delete(id).then(res=>{
      this.getData()
     }).catch(err=>{
      alert('Something went wrong')
     })
    }
    addRequest(e:Event){
      e.preventDefault();
      this.isBusy(true)
      if(this.addAdministrator) this.employeeId=''
      companionRequestService
        .addRequest(this.firmId,this.employeeId,this.reason)
        .then(result => {
          this.showAddUI=false;
          this.addAdministrator=false;
          this.employeeId=''
         this.getData()
        })
        .catch(err => this.isBusy(false));

    }
   
    
    getData() {
        this.isBusy(true)
      companionRequestService
        .getRequests()
        .then(result => {
          this.isBusy(false);
        
          this.requests = result;
        })
        .catch(err => this.isBusy(false));
    }
  }
  