
  import { Component, Prop, Vue } from "vue-property-decorator";
  
  import {usersService} from "../../utils/http";
  import CustomerBase from "@/common/customer.base";
  import UserAssosiations from './UserAssosiations.vue'
  
  @Component({
    components: {
        UserAssosiations
    }
  })
  export default class DbOperations extends CustomerBase {
    selectedUserId:string='';
    filter=''
    filterOn= ['email']
    totalRows= 1;
          currentPage= 1;
          perPage= 100;
  
    constructor() {
      super();
    }
  
  
    fields = [
      { key: "assosiations", sortable: false,label:'Access' },
      { key: "email", sortable: true,label:'Email' },
      { key: "firstName", sortable: true,label:'FirstName' },
      { key: "lastName", sortable: true,label:'LastName' },
      { key: "loginUserFlowName", sortable: true,label:'Security Level' },
      { key: "mfaCheck", sortable: true,label:'MFA' },
       
    ];
  
    public users: any[] = [];
  
  
  
    created() {
      this.getData();
     
    }
    checkForMFA(userId){
      usersService.getMfaStatus(userId).then(result=>{
        
        const user=this.users.find(z=>z.id==userId);
        user.mfaChecked=true;
        user.hasMfa=result.hasMfa;
        user.mfaId=result.mfaId
     })
    }
    async removeMFA(userId){
      
     const confirmation= await this.confirm("Confirmation","Are you sure you want to remove MFA. Do this only if the user has login issues")
     if(!confirmation) return;
     usersService.removeMFA(userId).then(result=>{
         const userIndex= this.users.findIndex(z=>z.id==userId)
      usersService.getUser(userId).then(result=>{
        Vue.set(this.users, userIndex, result);
this.checkForMFA(userId)
     })
     })
    }
    getData() {
     usersService.getAllUsers().then(result=>{
       result.forEach(element => {
        element.hasMfa=false;
        element.mfaChecked=false;
       });
        this.users=result;
        this.totalRows = result.length
     })
    }
  }
  