
    import {
    
      Component,
    
      Prop,
    
      Vue
    
    } from "vue-property-decorator";
    
    import HealthCheckResults from './HealthCheckResults.vue'
   
    
    import {
    
        healthCheckService
    
    } from "../../utils/http";
    
    import CustomerBase from '@/common/customer.base'
     
    
    @Component({
        components:{
            HealthCheckResults
        }
    })
    export default class HealthChecks extends CustomerBase {
        @Prop({required:false})
      firmId: string;
    
      fields = [
        {key:'firmUrl',  sortable: true },
        {key:'status',  sortable: true },
        {key:'time',  sortable: true },
        {key:'actions',  sortable: false },
    ]
       
  
    showResultsView:boolean=false;
    selectedCheckId:number=null;
      filter = null;
    
    
      public healthChecks: any[] = [];
    
      
  
      created() {
  
  
        this.geathHealthChecks();
  
      }
      runChecks(){
        this.isBusy(true);
  
  healthCheckService.runChecks(this.firmId).then((result) => {

    this.isBusy(false);
this.geathHealthChecks();


  }).catch((err) => this.isBusy(false))
      }
      async runAllFixes(){
     let confirmation=await   this.confirm("Confirm","Are you sure you want to run all the fixes? This will look at any check with errors and try to fix it.")
     if(!confirmation) return; 
     this.isBusy(true);
  
  healthCheckService.runAllFixes().then((result) => {

    this.isBusy(false);


  }).catch((err) => this.isBusy(false))
      }
     async runFixes(id){
     let confirmation=await   this.confirm("Confirm","Are you sure you want to run the fixes? This will change the data on the firm")
     if(!confirmation) return; 
     this.isBusy(true);
  
  healthCheckService.runFixes(id).then((result) => {

    this.isBusy(false);


  }).catch((err) => this.isBusy(false))
      }
     showCheckResults(id:number){
        this.selectedCheckId = id;
        this.showResultsView=true;
     }
      geathHealthChecks() {
  
        this.isBusy(true);
  
        healthCheckService.getChecks(this.firmId).then((result) => {
  
          this.isBusy(false);
  
          this.healthChecks = result;
  
  
  
        }).catch((err) => this.isBusy(false))
  
  
  
      }
  
    }
  