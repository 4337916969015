
import { Component, Vue } from 'vue-property-decorator';
import Resources from '@/common/resources';
import store from '@/store';

@Component({
 
})
export default class HangfireDashboards extends Vue {
    getUrl(dashboard){
     //todo fix
     return Resources.getBaseUrl() + `${dashboard}?access_token`
    }
}
