
  import { Component, Vue, Prop, Watch } from "vue-property-decorator";
  import componentBase from "../../../common/component.base";
  import QueryRunner from "./QueryRunner.vue";
  import utils from '../../../utils/miscellaneous'
  const cacheName='tps-query-history'
  @Component({
    components: {
        QueryRunner
    },
  })
  export default class extends componentBase {
    types:{title,type}[]=[{title:'Manager Query', type:'Manager'},{title:'Portal Query', type:'Portal'},{title:'Admin Query', type:'Admin'}]
    tabs:{id,title,query, type}[]=[]
    activeTab: number | null = null;  
    async created() {
        const savedTabs = localStorage.getItem(cacheName);
    if (savedTabs) {
      this.tabs = JSON.parse(savedTabs);
    }
    }

    addQueryTab(qType:string){
        this.tabs.push({
             id:utils.uuidv4(),
             title: qType + ' ' + (this.tabs.length +1),
             type:qType,
             query:''
        })
        setTimeout(() => {
            this.activeTab= this.tabs.length-1
        }, 500);
        this.cacheTabs();
      
    }
    removeTab(id:string){
        this.tabs.splice(this.tabs.findIndex(z=>z.id==id),1)
        this.cacheTabs();
    }
    cacheTabs(){
        localStorage.setItem(cacheName, JSON.stringify(this.tabs));
    }
    onQuery(tabId,query){
       let tab=this.tabs.find(z=>z.id==tabId);
       tab.query=query;
       this.cacheTabs();
    }
   
  }
  