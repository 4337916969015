
import { Component, Prop, Vue } from 'vue-property-decorator';
import { subscriptionService } from '../../utils/http'
import CustomerBase from '@/common/customer.base'
import Subscription from './Subscription.vue'
import SubscriptionDisplay from './SubscriptionDisplay.vue'
@Component({
    components: {
        Subscription,
        SubscriptionDisplay
    },
})
export default class Subscriptions extends CustomerBase {

    selectedSubscriptionLockId:string=''
    subscriptionLocks:any[]=[]

    created(){
       this.loadData()
    }
    loadData(){
        subscriptionService.getSubscriptionLocks().then(locks=>{
            this.subscriptionLocks=locks;
            this.selectedSubscriptionLockId=this.subscriptionLocks[0].id
        })
    }
    async makeDefault(id){
       let confirmed= await this.confirm("Confirm","Are you sure you want to make this subscription lock the default? Any new firms that signup will only see the subscriptions under this subscription lock. This does not affect any existing firms")
       if(!confirmed) return;
       subscriptionService.makeSubscriptionLockDefault(id).then(()=>{
        this.loadData()
        })
    }
    async cloneLock(id){

       subscriptionService.cloneSubscriptionLock(id).then(()=>{
        this.loadData()
        })
    }
    async schedulePriceUpdate(id){
        let confirmed= await this.confirm("Confirm","Are you sure you want to update all existing firms to the new price of the subscription? This will update stripe and the firms will be charged the new price on their next billing date")
       if(!confirmed) return;
       subscriptionService.schedulePriceUpdateBySubscriptionLock(id).then(()=>{
         this.info('Success','Price update scheduled. Please wait for it to finish. You can check the status of the update in the jobs in progress')
        }).catch(err=>{
            this.info('Error','Could not schedule price update.')
        })
    }
   
    async deleteLock(id){
       let confirmed= await this.confirm("Confirm","Are you sure you want to delete this subscription lock the default? This will not affect any existing firms but you won't be able to undo this action")
       if(!confirmed) return;
       subscriptionService.deleteSubscriptionLock(id).then(()=>{
        this.loadData()
        }).catch(err=>{
            this.info('Error','Could not delete Subscription Lock. Its either used by firms or it was used at any point in time and needs to remain for historical purposes')
        })
    }

}
