
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import componentBase from "../../common/component.base";
import { clientPortalService } from "../../utils/http";

@Component({
  components: {
    
  },
})
export default class extends componentBase {
  public isLoading:Boolean=false;
   @Prop()
  firmId: String;


  async calculatepermissions() {
    this.isLoading=true;
    await clientPortalService.calculatePermissions(this.firmId);
    this.isLoading=false;
   
  }
 
  
}
