const mustEncodeCharacters=[',', '/', '?', ':', '@', '&', '=', '+', '$', '#'];
export default {
  toQueryString(filter) {
    let queryString = []
    let flattenFilter = this.flattenObject(filter)
    for (let key of Object.keys(flattenFilter)) {
      queryString.push(flattenFilter[key])
    }
    return queryString.join('&')
  },
  flattenObject (ob, appender = '', firstLevel = true) {
    let toReturn = {}
    if (!ob) {
      return toReturn
    }

    for (let i of Object.keys(ob)) {
      if ((!ob[i] || ob[i].length <= 0) && ob[i] !== false) {
        continue
      }
      if (ob[i].constructor === Array) {
        toReturn[i] = ob[i].map(item => i + '=' + this.encodeValue(item)).join('&') + appender
      } else if ((typeof ob[i]) === 'object') {
        if (ob[i] && !ob[i].excluded) {
          let flatObject = this.flattenObject(ob[i], '_array', false)
          for (let x of Object.keys(flatObject)) {
            if (flatObject[x].indexOf('_array') > 0) {
              toReturn[i + '.' + x] = flatObject[x].replace(/_array/g, '')
            } else {
              toReturn[i + '.' + x] = (i + '.' + x + '=' + this.encodeValue(flatObject[x]))
            }
          }
        }
      } else {
        toReturn[i] = firstLevel ? i + '=' + this.encodeValue(ob[i]) : this.encodeValue(ob[i])
      }
    }
    return toReturn
  },
  encodeValue(value:any){
   
    try{//temporary try catch because this addition might cause issues on other places. This addition is for patching 
      if((typeof value === 'string' || value instanceof String) &&  mustEncodeCharacters.some(character=>value.includes(character))){
        if(!value) return ''
        return encodeURIComponent(value as string);
      }
    }
    catch{

    }
    return value;
  }
}
