
import { Component, Prop, Vue } from "vue-property-decorator";
import VueJsonPretty from 'vue-json-pretty'

import {
  CustomerViewModel, JobStatus, JobType
} from "../../common/models";
import LoadCustomer from '../common/LoadCustomer.vue'
import { httpService } from "../../utils/http";
import CustomerBase from '@/common/customer.base'
import store from '@/store'
import ActionButton from '../common/ActionButton.vue'
import Resources from '@/common/resources';

@Component({
  components:{
   ActionButton
  }
})
export default class DemoDbFile extends CustomerBase {
 isLoading=false;

  backupInfo={
    us:null,
    canada:null
  }
 usFile = null;
 canadaFile=null;
 usDescription:String=""
 canadaDescription:String=""
 public update(file,description,region){
    var data= new FormData() 
   data.append("file", file, file.name);
   this.isLoading=true
    httpService.post(Resources.getBaseUrl() + `dbfiles/updatedemodbfile/${region}?description=${description}`, data, {
      headers: {
          'Content-Type': 'multipart/form-data'
        }
  }).then(result=>{
  this.isLoading=false
  this.backupInfo[region]=result;
   this.reset()
  })
 }
 reset(){
   this.canadaFile=null;
   this.canadaDescription=null;
   this.usFile=null;
   this.usDescription=null;
 }
  public loadDbFile(region){
    this.isBusy(true)

  httpService.get(Resources.getBaseUrl() + `dbfiles/demodbfileinfo/${region}`
  ).then(result=>{
  this.isBusy(false)
  this.backupInfo[region]=result;
  }).catch(err=>{
   this.isBusy(false)
    this.info('Error','Something went wrong')
  })
   
 }
  created(){
    this.loadDbFile('us')
    this.loadDbFile('canada')
  }
 
 
}
