'use strict'

/**
 * DateTime utility class
 *
 * used to format date time to local datetime or to UTC
 *
 *
 *
 */
import moment from 'moment'
class DateTimeUtility {
    defaultFormat= 'YYYY-MM-DD HH:mm';
    fullDateTime= 'YYYY-MM-DD HH:mm:ss';
    dateFormat= 'YYYY-MM-DD';
    timeFormat= 'HH:mm:ss';
    timeFormatWithoutSeconds= 'HH:mm';
    twelveHourFormat="hh:mm A"
    slashDateFormat= 'MM/DD/YYYY';
    longDateFormat= "DD MMMM YYYY"
    mothdayyear= "MMMM DD, YYYY"
    yearend= "MMM DD"

    areDatesEqual(value1, value2){
      if(!value1) return false;
      if(!value2) return false;
      return this.toDate(value1) == this.toDate(value2)
    }
    isAfter(date,compareDate){
     return  moment(date).isAfter(compareDate);
    }
    isBefore(date,compareDate){
      return  moment(date).isBefore(compareDate);
     }
    monthStart= function (date = null, format = this.dateFormat) {
      let time = date ? moment(date) : moment()
      return time.startOf('month').format(format)
    }
    monthEnd= function (date = null, format = this.dateFormat) {
      let time = date ? moment(date) : moment()
      return time.endOf('month').format(format)
    }
    /**
     * get todays date using default date format or with a specific format
     * @param dateTime
     * @param format
     * @returns {*}
     */
    today =function (format = this.dateFormat) {
      return moment().format(format)
    };

    parseDate =function (dateTime, format = this.defaultFormat) {
      if (!dateTime) {
        return null
      }
      return new Date(moment(dateTime).format(format))
    };
     /**
     * get date from datetime, default format is "YYYY-MM-DD"
     * @param dateTime
     * @param format
     * @returns {*}
     */
     toDate =function (dateTime, format = this.dateFormat) {
       if (!dateTime) {
         return moment().format(format)
       }
       return moment(dateTime).format(format)
     };
  /**
     * get time from datetime, default format is "HH:mm:ss"
     * @param dateTime
     * @param format
     * @returns {*}
     */
    toTime = function (dateTime, format = this.timeFormat) {
      if (!dateTime) {
        return moment().format(format)
      }
      return moment(dateTime).format(format)
    };
  /**
       * Convert given utc time to local timezone value
     * Format given datetime, default format is 'YYYY-MM-DD HH:mm:ss'
     * @param dateTime
     * @param format
     * @returns {*}
     */
   toLocale = function (dateTime, format = this.defaultFormat) {
     if (!dateTime) {
       return moment().format(format)
     }
     return moment.utc(dateTime).local().format(format)
   };
    /**
     * Format given datetime, default format is 'YYYY-MM-DD HH:mm:ss'
     * @param dateTime
     * @param format
     * @returns {*}
     */
    format = function (dateTime, format = this.defaultFormat) {
      if (!dateTime)  return 
      return moment(dateTime).format(format)
    };

    validateTimeInput (value) {
      var values = value ? value.split(':') : []
      if (values.length !== 2) value = '00:00'

      var hours = parseInt(values[0])
      if (isNaN(hours)) hours = 0
      var minutes = parseInt(values[1])
      if (isNaN(minutes)) minutes = 0
      if (hours > 24) hours = 24
      if (hours < 0) hours = 0
      if (minutes > 59) minutes = 59
      if (minutes < 0) minutes = 0
      if (hours === 24) minutes = 0
      let hrsValue = `0${hours}`.slice(-2)
      let minsValue = `0${minutes.toFixed(0)}`.slice(-2)
      return `${hrsValue}:${minsValue}`
    }

    hourFormatToMinute (value) {
      let numbers = value.split(':')
      let preDecimal = parseInt(numbers[0])
      if (numbers.length === 1) return value
      let postDecimal = parseInt(numbers[1])
      let minsToHours = postDecimal / 60
      return (preDecimal + minsToHours).toFixed(2)
    }

    minutesToHourFormat (value) {
      if (!value) value = 0
      let mins = value * 60
      let hours = Math.floor(mins / 60)
      let minutes = mins % 60
      let hrsValue = `0${hours}`.slice(-2)
      let minsValue = `0${minutes.toFixed(0)}`.slice(-2)
      return `${hrsValue}:${minsValue}`
    }
    /**
     * Convert local datetime to utc, this function accept 2 parameters one is date and one is time
     * @param date
     * @param time
     * @returns {string | *}
     */
     combine = function (date, time, format = this.defaultFormat) {
       if (!date && !time) {
         throw new Error('date and time parameter can not be null')
       }
       return moment(date + ' ' + time).format(format)
     };

  /**
     * Convert local datetime to UTC datetime
     *
     * @param dateTime
     * @returns {string | *}
     * @deprecated don't use this function
     */
    utc = function (dateTime) {
      if (!dateTime) {
        return moment().toDate().toISOString()
      }
      return moment(dateTime).toDate().toISOString()
    };
    /**
     * Convert local datetime to UTC datetime
     *
     * @param dateTime
     * @returns {string | *}
     * @deprecated don't use this function
     */
    toUtc = function (date, format= this.defaultFormat) {
      if (!date) {
        return date
      }
      return moment.utc(date).format(format);
    };
    
  /**
     * Calc the hours difference between start time and end time
     * @param startTime
     * @param endTime
     * @returns {string}
     */

    timeDifference = function (startTime, endTime) {
      if (!startTime || !endTime) {
        return 0
      }

      var timeStart = new Date(startTime).getTime()
      var timeEnd = new Date(endTime).getTime()
      var hourDiff = (timeEnd - timeStart) / 3600000// ms to hours
      return parseFloat(hourDiff.toString()).toFixed(2)
    };
  /**
     * Add miliseconds
     * @param startTime
     * @param endTime
     * @returns {string}
     */
  addMilisecondsToTime = function (time, value, timeFormat = this.timeFormat) {
    if (!time) {
      return moment()
    }
    var date = moment().format('YYYY-MM-DD')
    return moment(date + ' ' + time, `YYYY-MM-DD ${timeFormat}`).add(value, 'ms').format(timeFormat)
  };
  /**
     * Check if datetime is valid
     * @param value
     * @param endTime
     * @returns {boolean}
     */
    isValidDate = function (value) {
      var timestamp = Date.parse(value)
      return !isNaN(timestamp)
    };
  /**
     * Add value to a date object
     * @param date
     * @param value
     * @param metric
     * @param format
     * @returns {string}
     */
  addValueToDate = function (date, value, metric = 'ms', format = this.dateFormat) {
    if (!date) {
      return moment()
    }
    return moment(date).add(value, metric).format(format)
  };
 
  /**
     * Get first day of current month
     * @param date
     * @returns {Date}
     */
   getFirstDay = function (date) {
     let y = date.getFullYear(),
       m = date.getMonth()
     return new Date(y, m, 1)
   };
  /**
     * Get last day of current month
     * @param date
     * @returns {Date}
     */
   getLastDay = function (date) {
     let y = date.getFullYear(),
       m = date.getMonth()
     return new Date(y, m + 1, 0)
   };
   subtractMonths = function (date, num) {
     return moment(date).subtract(num, 'months')
   }
   isLastDay = function (dt) {
     let current = new Date(dt.getTime())
     let month = current.getMonth()

     current.setDate(current.getDate() + 1)
     return current.getMonth() !== month
   }
   isDateBetween(date,start,end){
     if(!date || !start || !end ) return false
     return moment(date).isBetween(moment(start),moment(end),null,"[]")
   }
   setHours(date,hour=0){
    return moment(date).set("hour", hour)
   }
   setMinutes(date,minutes=0){
    return moment(date).set("minute", minutes)
   }
   setTime(date,hours=0,minutes=0,seconds=0){
    return moment(date).set("hour", hours).set("minute", minutes).set("second", seconds)
   }
   relativetime(start){
    return moment(start).toNow(true);
    
   }
}
let dateTimeUtility = new DateTimeUtility()
export default dateTimeUtility
