import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
import store from '@/store';
import router from '@/router';
// tslint:disable-next-line:max-line-length
import { CustomerViewModel, JobDetailsViewModel,ResteJasperCredentialsResult } from '@/common/models';
class SyncService {
 public syncronize(): Promise<any> {
   return httpService.post(Resources.getBaseUrl() + `sync`,null);
 }
 public syncDbVersion(): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `Databases/SyncDbVersions`,null);
}
public syncDefaultFiles(): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `sync/SyncDefaultFiles`,null);
}
public syncTemplateRecords(id:String): Promise<any> {
  if(!id) return;
  return httpService.post(Resources.getBaseUrl() + `templates/synctemplaterecords/${id}`,null);
}



}
export let syncService = new SyncService();
