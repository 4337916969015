<template>
 
  <div>
    <Logfilter :firmId="firmId" @filter="onFilter"/>
    <b-list-group v-if="entries.length>0">
      <b-list-group-item 
        v-for="entry in entries"
        :key="entry.id"
        class="flex-column align-items-start"
      >
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">
          On  <b-badge  class="ml-1" variant="light">{{ entry.timestamp }}</b-badge>
            <b-badge  class="ml-1" variant="light">{{ entry.userName }}</b-badge>
            <b-badge   class="ml-1" :variant="entry.actionVariant">{{ entry.actionDisplayText }}</b-badge>
            <b-badge  class="ml-1" variant="light">{{ entry.entityTypeName }}</b-badge>
            <b-badge  class="ml-1" >{{ entry.displayName }}</b-badge>
             <InlineProperty :properties="entry.properties" :visiblePropertyName="visiblePropertyName" v-if="entry.isChange">
               
               </InlineProperty>

            <b-badge  class="ml-1" variant="light"><b-button class="p-0" variant="link" @click="showDetails(entry.id)">View Details</b-button></b-badge>
          </h5>
          <small>{{ entry.timestamp | relativetime }}</small>
        </div>

       
      </b-list-group-item>
    </b-list-group>
    <b-card v-else title="No Records">
      <b-card-text>
        We couldn't find any record. Try changin your filters
      </b-card-text>
    </b-card>
    <b-modal
  v-if="selectedLogId" @close="selectedLogId=null"
    @hide="selectedLogId=null"
    hide-footer
    :size="'xl'"
    :visible="true"
  >
           <ActivityEntry :firmId="firmId" :id="selectedLogId"/>

    </b-modal>
  </div>
</template>
<script>
import activityLogService from "../../utils/http/activitylog.service";
import ActivityEntry from "./common/ActivityEntry.vue";
import {ActivityLogList} from './common/ActivityLogList'
import Logfilter from './common/LogFilter.vue'
import InlineProperty from './common/InlineProperty.vue'
export default {
  components: {
    ActivityEntry,
    Logfilter,
    InlineProperty
  },
  props: {
    firmId: String,
  },
  data() {
    return {
      isLoading:false,
      entries: [],
      selectedLogId:null,
      visiblePropertyName:''
    };
  },
  created() {
    this.loadEntries();
  },
  methods: {
    onFilter(filter){
      this.visiblePropertyName=filter?.pName;
       this.loadEntries(filter)
    },
      showDetails(id){
        this.selectedLogId=id;
      },
    loadEntries(filter) {
      this.isLoading=true;
      activityLogService
        .getLogEntries(this.firmId, filter)
        .then((entries) => {
          this.isLoading=false;
          this.entries = entries.map(z=>new ActivityLogList().fromPayload(z));
        }).catch(err=>this.isLoading=false);
    },
  },
};
</script>