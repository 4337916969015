
import { Component, Prop, Vue } from 'vue-property-decorator';
import { accountService } from "../utils/http/account.service";

@Component({
  components: {
  
  },
})
export default class LoggedOut extends Vue{
   
  created(){
      accountService.loggedOut();
  }
}
