
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  LoginModel,
  TokenResultModel,
  TreeItem,
  Reports
} from "../../common/models";
import { httpService, eventsService, listService } from "../../utils/http";
import ActionButton from "../common/ActionButton.vue";
import FormError from "../common/FormError.vue";
import CustomerBase from "@/common/customer.base";

import store from "@/store";

@Component({
  components: {
    ActionButton,
    FormError
  }
})
export default class Events extends CustomerBase {
  events: any[] = [];
  subscribers: any[] = [];
  editingSubscriber: any=null;
  public selectedSubscriberId = null;

  // async changeState(subscription:any){
  //    var result= await subscriptionService.changeState(subscription.id, !subscription.isHistory);
  //    subscription.isHistory = result.isHistory;
  //  }
  addEditSubscriber(subscriberId) {
    this.editingSubscriber = subscriberId
      ? this.subscribers.find(z=>z.id==subscriberId)
      : {
          name: "",
          email: ""
        };
  }
  async deleteSubscriber(id:string){
    this.isBusy(true)
     await eventsService.deleteSubscriber(id);
     this.subscribers.splice(this.subscribers.findIndex(z=>z.id==id),1)
     this.events=[]
     this.selectedSubscriberId=null
     this.isBusy(false)

  }
  async saveSubscriber() {
    this.isBusy(true)
    try{
       var result = await eventsService.addUpdateSubscriber(
      this.editingSubscriber.id,
      this.editingSubscriber
    );
    if (this.editingSubscriber.id) {
      var existing = this.subscribers.find(
        z => z.id == this.editingSubscriber.id
      );
     Vue.set(this.subscribers,this.subscribers.findIndex(z=>z.id==result.id),this.mapEvent(result)) 
      // existing.name = result.name;
      // existing.email = result.email;   
    } else {
      this.subscribers.push(this.mapEvent(result));
    }
    this.subscriberChanged(result.id)
    this.editingSubscriber=null;
    this.isBusy(false)
    }
    catch(err){
      this.isBusy(false);
      this.info('Error', 'Something went wrong during creation, make sure sure you do not have duplicate emails');
    }
  }
  async subscriberChanged(subscriberId) {
    try {
      this.isBusy(true);
      this.selectedSubscriberId = subscriberId;
      this.events = await eventsService.get(subscriberId);

      this.isBusy(false);
    } catch (err) {
      this.isBusy(false);
    }
  }
  async created() {
    await this.initialize();
  }
  async initialize() {
    try {
      this.isBusy(true);
      // var data =await rightsService.getRights()
      this.subscribers = (await eventsService.getSubscribers()).map(z=>this.mapEvent(z));

      this.isBusy(false);
    } catch (err) {
      this.isBusy(false);
    }
  }
   mapEvent(event){
     return {
       id:event.id,
       name:event.name,
       email:event.email,
       label:`${event.name} (${event.email})`
     }
   }
  async save() {
    try {
      this.isBusy(true);
      // var data =await rightsService.getRights()
      this.events = await eventsService.update(
        this.selectedSubscriberId,
        this.events.filter(z => z.isSubscribed).map(z => z.id)
      );

      this.isBusy(false);
    } catch (err) {
      this.isBusy(false);
    }
  }
}
