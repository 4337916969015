<template>
   <b-badge  class="ml-1" variant="light">
     <span v-if="properties.length>1 || !visibleProperty">
       {{ properties.length }} fields
     </span>
     <template v-if="visibleProperty">
       <b>{{visibleProperty.name}} &nbsp;</b>
       <span
              class="text-danger mr-2"
              style="text-decoration: line-through"
              title="visibleProperty.oldValue"
              v-if="visibleProperty.oldValue"
              >{{ visibleProperty.oldValue  }}</span
            >
            <span title="visibleProperty.value" class="text-success">{{ visibleProperty.value}}</span>
     </template>
   </b-badge>

</template>
<script>
export default {
  data() {
    return {
      visibleProperty: null,
    };
  },
  props: {
    visiblePropertyName: {
      type: String,
      default: '',
    },
    properties: {
      type: [],
      default: [],
    },
  },
  watch:{
    visiblePropertyName(){
      this.parseProperty();
    }
  },
  created() {
    this.parseProperty();
  },
  methods: {
    parseProperty() {
      if(!this.visiblePropertyName && this.properties.length>1) this.visibleProperty=null;
      else if(!this.visiblePropertyName && this.properties.length==1){
         this.visibleProperty=this.properties[0] 
         return;
      }
     this.visibleProperty=this.properties.find(z=>z.name==this.visiblePropertyName)
    },
  },
};
</script>