
import { Component, Prop, Vue } from "vue-property-decorator";
import ReleaseNote from './ReleaseNote.vue'
import {
  releaseNotesService
} from "../../utils/http";

import CustomerBase from "@/common/customer.base";
@Component({
  components: {
    ReleaseNote
  }
})
export default class ReleaseNotes extends CustomerBase {
  releaseNotes: any[] = [];
  showEditScreen=false;
  currentEditingNoteId:string=null;
  async created() {
    await this.initialize();
  }
    async publish(id: string, isDraft: boolean) {
    var confirmation = await this.confirm(
      "Confirm",
      "Are you sure you want to perform this action?"
    );
    if (!confirmation) return;
    try {
      this.isBusy(true);
      if (isDraft) await releaseNotesService.publish(id);
      else await releaseNotesService.unPublish(id);
     this.initialize();
      this.isBusy(false);
    } catch (err) {
      this.isBusy(false);
    }
  }
  setEditingEntry(id){
    this.showEditScreen=true;
    this.currentEditingNoteId=id;
  }
    async deleteReleaseNote(id: string) {
    var confirmation = await this.confirm(
      "Confirm",
      "Are you sure you want to delete?"
    );
    if (!confirmation) return;
    try {
      this.isBusy(true);
      await releaseNotesService.delete(id);
     this.initialize();

      this.isBusy(false);
    } catch (err) {
      this.isBusy(false);
    }
  }
 
  async createNew() {
   let note={
      notes: "",
      title:'New Release',
      date: this.formatDate(new Date()),
      tags:[]
    };
    var newNote=await releaseNotesService.add(note)
    this.initialize();
    this.currentEditingNoteId=newNote.id;
    this.showEditScreen=true;
  }

  async initialize() {
    try {
      this.isBusy(true);
      // var data =await rightsService.getRights()
      let releaseNotes = await releaseNotesService.get();
      this.releaseNotes = releaseNotes;
      this.isBusy(false);
    } catch (err) {
      this.isBusy(false);
    }
  }
}
