
import { Component, Prop, Vue } from "vue-property-decorator";


import { dbService, httpService } from "../../utils/http";
import CustomerBase from "@/common/customer.base";

@Component({
  components: {
  }
})
export default class DeletionStatus extends CustomerBase {
 
 

  @Prop()
  firmId: string;
    
  public firm:any=null;
  public newStatus:Number=null;
    public    availableStatuses :any[]= [
          { value: 0, text: 'Active. Do not delete' },
          { value: 1, text: 'Delete: Schedule to be deleted' },
          { value: 2, text: 'Deleting: Delete in progress', disabled:true },
          { value: 2, text: 'Deleted: Firm has been deleted', disabled:true }
        ]
  created() {
 this.loadData();
  }
  loadData(){
    this.isBusy(true);
    dbService.getTenant(this.firmId).then(result=>{
    this.firm=result;
    this.newStatus=result.dbDeletionStatus
    this.isBusy(false);
   }).catch(err=>{
    this.isBusy(false);
   })
  }
  saveChanges(){
    this.isBusy(true);
    dbService.updateTenantDeletionStatus(this.firmId,this.newStatus).then(res=>{
      this.isBusy(false);
      this.info('Success','Status set successfully');

     this.loadData();
      //@ts-ignore
      this.$eventHub.emit(this.EventNames.DBStatusChanged,this.firmId);
    }).catch(err=>{
      this.isBusy(false);
      this.loadData();
    })
  }

 
}
