
  import { Component, Prop, Vue } from "vue-property-decorator";

 import { backgroundEventsService } from "../../utils/http";
  import CustomerBase from "@/common/customer.base";
  import VueJsonPretty from "vue-json-pretty";
import Requeue from './Requeue.vue'
  
  
  @Component({
    components: {
        VueJsonPretty,
        Requeue
    }
  })
  export default class BackgroundEventsDetails extends CustomerBase {
    @Prop({default:()=> []})
    events: any[];
    showRequeue:boolean=false;
    eventsToRequeue:any[]=[]
    created(){
   this.eventsToRequeue=this.events.map(item => item.data.events).flat();
    }
   
  }
  