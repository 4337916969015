class Debounce {
    debounce (method: (...params: any[]) => any, wait:number, immediate:boolean =false) {
        let timeout: any=null;
        return function () {
          var context = this as any;
          let args = arguments
          clearTimeout(timeout)
          timeout = setTimeout(() => {
          timeout = null
            if (!immediate) method.apply(context, args) 
          }, wait)
          if (immediate && !timeout) method.apply(context, args)
        }
      }
     
     slice = [].slice;
     asyncDebounce(fn: (...params: any[]) => Promise<any>, interval:number) {
        
        let timeout:any=null;
        var running = false;
        var nextArgs:any;
        
        return ()=> {
          var args = this.slice.call(arguments);
          args.push(function() {
            running = false;
            if (nextArgs) {
              run(nextArgs);
              nextArgs = null;
            }
          });
          
          if (running) return nextArgs = args;
          if (timeout) clearTimeout(timeout);
          
          timeout = setTimeout(function() {
            run(args);
            timeout = null;
          }, interval);
          
          function run(args:any) {
            fn.apply(null, args);
            running = true;
          }
        }
      }
      throttle(func, wait) {
        let waiting = false;
        return function () {
          if (waiting) {
            return;
          }
      
          waiting = true;
          setTimeout(() => {
            func.apply(this, arguments);
            waiting = false;
          }, wait);
        };
      }
     
}
export const debounce =new Debounce()