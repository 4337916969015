
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import componentBase from "../../common/component.base";
import { backgroundTasksService } from "../../utils/http/backgroundtasks.service";
import { notificationService } from "../../utils/notification/notification.service";
import { JobType, JobStatus } from "../../common/enums";
import VueJsonPretty from "vue-json-pretty";

@Component({
  components: {
    VueJsonPretty
  }
})
export default class extends componentBase {
  public backgroundTasks: any[] = [];
  public interval: any;
  public jobOutput:String=''
  async created() {
    await this.fetchData();
    this.setupNotificationEvents();
    this.toggleInterval();
  }

  refresh() {
    this.fetchData();
  }
  async fetchData() {
    let tasks = await backgroundTasksService.getActive();
    tasks.forEach(x => {
     this.mapTask(x)
    });
    this.backgroundTasks = tasks;
    this.recalculateDuration();
  }
  mapTask(task:any){
   task.duration = "";
      return task;
  }
  dismiss(id) {
    backgroundTasksService.dissmiss(id).then(result => {
      var currentJobIndex = this.backgroundTasks.findIndex(z => z.id == id);
      if (currentJobIndex >= 0) this.backgroundTasks.splice(currentJobIndex, 1);
    });
  }

  setupNotificationEvents() {
    notificationService.onJobInfo(jobInfo => {

var currentJobIndex = this.backgroundTasks.findIndex(
        z => z.id == jobInfo.id
      );
      if (currentJobIndex >= 0) {
        Vue.set(this.backgroundTasks, currentJobIndex, this.mapTask(jobInfo));
      } else {
        this.backgroundTasks.splice(0, 0, this.mapTask(jobInfo));
        Vue.nextTick(() => {
          //@ts-ignore
          this.$refs.tasks.show(true);
        });
      }
      this.toggleInterval();
      this.recalculateDuration();
    });
  }

  jobStatusText(status) {
    switch (status) {
      case JobStatus.Queued:
        return "Starting Soon";
      case JobStatus.Running:
        return "In Progress";
      case JobStatus.Succeeded:
        return "Finished";
      case JobStatus.Failed:
        return "Error Occurred";
    }
  }

  jobStatusBadge(status) {
    switch (status) {
      case JobStatus.Queued:
        return "info";
      case JobStatus.Running:
        return "primary";
      case JobStatus.Succeeded:
        return "success";
      case JobStatus.Failed:
        return "danger";
    }
  }

  canDismiss(status) {
    return status == JobStatus.Succeeded || status == JobStatus.Failed;
  }

  toggleInterval() {
    if (this.backgroundTasks.some(z => z.status == JobStatus.Running)) {
      this.interval = window.setInterval(() => {
        this.recalculateDuration();
      }, 10000);
    } else {
      this.stopInterval();
    }
  }

  stopInterval() {
    if (this.interval) clearInterval(this.interval);
  }

  recalculateDuration() {
    this.backgroundTasks.forEach(task => {
      task.duration = this.getDurationForTask(task);
    });
  }

  getDurationForTask(task) {
    switch (task.jobStatus) {
      case JobStatus.Failed:
      case JobStatus.Succeeded:
        return `Duration: ${moment
          .duration(moment.utc(task.stop).diff(moment.utc(task.start)))
          .humanize()}`;
      case JobStatus.Queued:
        return "";
      case JobStatus.Running:
        return `Duration: ${moment
          .duration(moment.utc().diff(moment.utc(task.start)))
          .humanize()}`;
      default:
        return "";
    }
  }
}
