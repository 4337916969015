import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import store from '@/store';
import 'jquery'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import "summernote/dist/summernote-lite.css";
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import '@/utils/filters'
import { FormSelectPlugin } from 'bootstrap-vue'
Vue.use(FormSelectPlugin)
import vSelect from 'vue-select'
Vue.component('v-select', vSelect);
import Sidebar from './components/common/layout/Sidebar.vue'
Vue.component('v-sidebar', Sidebar);

Vue.use(BootstrapVue);
import { ToastPlugin } from 'bootstrap-vue'
Vue.use(ToastPlugin)
import VueFormWizard from 'vue-form-wizard';
Vue.use(VueFormWizard)
import VueContentPlaceholders from 'vue-content-placeholders'
import EventHub from 'vue-event-hub';
import VueCarousel from 'vue-carousel';
import "./filters/index";
Vue.use(VueCarousel);
Vue.use(EventHub);
Vue.use(VueContentPlaceholders)
import JsonViewer from 'vue-json-viewer'
Vue.use(JsonViewer)
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)


import { MsalPlugin } from './plugins/msal-plugin';
const options = {
  clientId: "82f47d69-9c16-4844-8def-37d7a3d1ec09",
  loginAuthority: "https://devapptpssoftware.b2clogin.com/devapptpssoftware.onmicrosoft.com/B2C_1_admin/",
  knownAuthority :"devapptpssoftware.b2clogin.com",
  redirectUri:  document.location.protocol + `//` + document.location.host.replace('www.','') + "/accountpicker",
  postLogoutRedirectUri: document.location.protocol + `//` + document.location.host.replace('www.','') + "/loggedout",
  scopes:[ "openid", "profile", "https://devapptpssoftware.onmicrosoft.com/82f47d69-9c16-4844-8def-37d7a3d1ec09/tpsapps" ]
};

Vue.use(new MsalPlugin(), options);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  async created () {
    Vue.prototype.$toast=this.$bvToast;
    
  }
}).$mount('#app');

