
  import {
  
    Component,
  
    Prop,
  
    Vue
  
  } from "vue-property-decorator";
  
  import VueJsonPretty from 'vue-json-pretty'
  
  import moment from 'moment';
  
  
  
  import {
  
    DatabaseStatus,
  
    FinancialReportViewModel,
  
    LicenseStatus
  
  } from "../../common/models";
  
  import LoadCustomer from '../common/LoadCustomer.vue'
  
 
  import {
  
    billingService
  
  } from "../../utils/http";
  
  import CustomerBase from '@/common/customer.base'
  
  import store from '@/store'
  
  
  @Component({
  
    components: {
  
      VueJsonPretty,
  
      LoadCustomer
  
    }
  
  })
  
  export default class MonthlyReport extends CustomerBase {
  
  showOutput = false;
  outputData = {};
    fields = [
      {key:'nonBillable',  sortable: true, label:'Billable' },
      {key:'firmName',  sortable: true },
      {key:'owner',  sortable: true },
      {key:'billingDate', sortable: true },
      {key:'expireDate', sortable: true },
      {key: 'tax',  sortable: true },
        {key: 'amount', sortable: true },
      {key: 'amountWithTax',  sortable: true },
      {key:'currentSubscription',  sortable: true },
      {key:'subscription',  sortable: true },
      {key:'show_details', sortable: false }
      ];
    filter = null;
    licenseFields = ['license_status', 'statusText', 'amount', 'statusSetDate', 'activatedOn'];
    eLicenseFields = [...this.licenseFields, 'employee']
    private reportDate
    amountWithTax = 0
    amountWithoutTax=0
    showBilleable = true;
    showNonBilleable = false;
    CurrentDate= new Date()
  
    public firms: FinancialReportViewModel[] = [];
    
    public licenseStatus(status: LicenseStatus) {
  
      return LicenseStatus[status]
  
    }

    get filteredFirm(){
      if(this.showBilleable && this.showNonBilleable) return this.firms;
      else if(this.showBilleable) return this.firms.filter(z=> !z.nonBillable)
      else if(this.showNonBilleable) return this.firms.filter(z=> z.nonBillable)
    }
  
    created() {
  
      this.reportDate = moment(new Date()).format('YYYY-MM');
  
      this.getReport();
  
    }
  onFiltered (filteredItems) {
      this.amountWithTax =filteredItems.filter(z=>z.amountWithTax).map(z=>z.amountWithTax).reduce((a, b) => a + b, 0);
      this.amountWithoutTax =filteredItems.filter(z=>z.amount).map(z=>z.amount).reduce((a, b) => a + b, 0);
    }
    refreshBillingDate(firm:FinancialReportViewModel){
      this.isBusy(true)
      billingService.refreshBillDate(firm.firmId).then(result=>{
        this.isBusy(false)
       if(result){
         let updateItem = this.firms.find(z=>z.firmId==firm.firmId)
         updateItem.billingDate=result;
       }
      }).catch(err=>this.isBusy(false))
    }
       refreshExpireDate(firm:FinancialReportViewModel){
      this.isBusy(true)
      billingService.refreshExpireDate(firm.firmId).then(result=>{
        this.isBusy(false)
       if(result){
         let updateItem = this.firms.find(z=>z.firmId==firm.firmId)
         updateItem.expireDate=result;
         updateItem.isCardExpired= this.isExpired(result);
       }
      }).catch(err=>this.isBusy(false))
    }
    viewElavonSubscription(firm:FinancialReportViewModel){
          this.isBusy(true);
  
      billingService.getElavonSubscription(firm.firmId).then(result => {
  
        this.isBusy(false);
  
     this.outputData = result;
    this.showOutput = !this.showOutput;
  
      }).catch(err => {
        this.isBusy(false)
        alert("Couldn't get subscription for firm.")
      })
  
  
    }
    getReport() {
  
      this.isBusy(true);
  
      billingService.getMonthlyReport(this.reportDate).then(result => {
  
        this.isBusy(false);
  
        this.firms = result;
        this.firms.forEach(x=>{
          //@ts-ignore
          x._rowVariant=x.isCardExpired? 'danger' : ''
          })
  
  
  
      }).catch(err => this.isBusy(false))
  
  
  
    }
       isExpired(date:string){
    if(!date) return false
    let expireDate = new Date(date);
    return expireDate.getTime() <= this.CurrentDate.getTime();
  }
   rowClass(item, type) {
        if (!item) return
        if (this.isExpired(item.expireDate)) return 'expired'
      }
  }
