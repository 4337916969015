import store from '@/store';

 class Resources {
    private base =  document.location.protocol + `//` + document.location.host;
    //  private base =  "https://localhost:5001";
    //  private base =  "https://admin.development.tpssoftware.net";
 private version = '/api/'
 private resourceBase = this.base + this.version;
 getBaseUrl(){
     return `${this.resourceBase}`
 }
}

 export default new Resources();
