import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
import store from '@/store';
import router from '@/router';
// tslint:disable-next-line:max-line-length
class BackgroundEventsService {
 public getFailedEvents(): Promise<any> {
   return httpService.get(Resources.getBaseUrl() + `backgroundevents/failed`);
 }
 public requeue(events:any[]): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `backgroundevents/requeue`,events);
}
 
}
export let backgroundEventsService = new BackgroundEventsService();
