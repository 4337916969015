
import { Component, Prop, Vue } from "vue-property-decorator";

import {
  CustomerViewModel, JobStatus, JobType
} from "../../common/models";
import { dbService } from "../../utils/http";
import ComponentBase from '@/common/component.base'
import store from '@/store'
@Component({
})
export default class LoadCustomers extends ComponentBase {
  public customerIds: {id}[] = [];

  
  removeId(index){
     this.customerIds.splice(index,1);
  }
  
  addNewId(){
     this.customerIds.push({id:''})
  }

  loadCustomerData(){
  //  var payload= this.customerIds.filter(z=> z.id).map(z=>z.id);
  //  this.isBusy(true);
  //  localStorage.setItem("customerids",JSON.stringify(this.customerIds));
  //  dbService.findCustomer(payload).then(result=>{
  //    this.isBusy(false);
  //     this.$emit("onload",result)
  //  }).catch(error=>{this.isBusy(false)})
  
  }
  created(){
    
      var customerids= localStorage.getItem("customerids");
      console.log("customerids")
      console.log(customerids)
      if(customerids){
        var result=JSON.parse(customerids);
         this.customerIds.push(...result)
      }
      else{
            this.customerIds.push({id:''})

      }

  }
 
 
}
