import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
import store from '@/store';
import router from '@/router';
// tslint:disable-next-line:max-line-length
import { CustomerViewModel, JobDetailsViewModel,ResteJasperCredentialsResult, Reports, TreeItem } from '@/common/models';
class ListService {
 public getUsers(): Promise<any[]> {
   return httpService.get(Resources.getBaseUrl() + `lists/users`);
 }
 


}
export let listService = new ListService();
