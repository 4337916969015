
  import { Vue, Component, Prop, Model } from 'vue-property-decorator';
  import { CouponType } from './common/CouponType';

  @Component
  export default class CouponTypeSelect extends Vue {
    @Model('change', { type: Number }) readonly value!: number;
  
    options = [
      { value: CouponType.Nonchargeable, text: 'Nonchargeable' },
      { value: CouponType.Demo, text: 'Demo(Not implemented yet)' },
      { value: CouponType.Trial, text: 'Trial' },

    ];
  
    get selected() {
      return this.value;
    }
  
    set selected(value) {
      this.$emit('change', value);
    }
  }
  