
  import { Component, Prop, Vue } from "vue-property-decorator";
  import ComponentBase from "@/common/component.base";
  import { couponService } from "../../utils/http";

  @Component({
    components: {
       
    }
  })
  export default class Coupons extends ComponentBase {
   
    @Prop()
    id: number;
   
    fields = [
      
     
      { key: "couponCode", sortable: true,label:'Coupon' },
      { key: "couponType", sortable: true,label:'Type' },
      { key: "couponDescription", sortable: true,label:'Description' },
      { key: "date", sortable: true,label:'Used On' },
      { key: "tenantCode", sortable: true,label:'Firm' }
      
    ];
  
    public histories: any[] = [];
  
    
     
    created() {
      this.getData();
    
      
    }
    
    getData() {
        this.isBusy(true, "Loading History....")
      couponService
        .getUseageHistory(this.id)
        .then(result => {
          this.isBusy(false);
        
          this.histories = result;
        })
        .catch(err => this.isBusy(false));
    }
  }
  