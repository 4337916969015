class RightIds {
    public readonly Backup: string = '1';
    public readonly Restore: string = '2';
    public readonly Jasper: string = '3';
    public readonly Billing: string = '4';
    public readonly UpdateDB: string = '5';
    public readonly DeleteClient: string = '6';
    public readonly DownloadBackup: string = '7';
    public readonly DeleteBackup: string = '8';
    public readonly ChangeRights: string = '9';
    public readonly UpdateDBFile: string = '10';
    public readonly Subscriptions: string = '11';
    public readonly Sync: string = '12';
    public readonly UpdateTemplateFiles: string = '13';
    public readonly SyncTemplateFiles: string = '14';
    public readonly ResetDbCredentials: string = '15';
    public readonly UploadBackup: string = '16';
    public readonly DbFixingTools: string = '17';
    public readonly ClientList: string = '18';
    public readonly BatchQuery: string = '19';
    public readonly MassDelete: string = '20';
    public readonly Hangfire: string = '21';
    public readonly MassUpdate: string = '22';
    public readonly DemoDbFiles: string = '23';
    public readonly Events: string = '24';
    public readonly PowerBIEdit: string = '25';
    public readonly PowerBIRead: string = '27';
    public readonly SubscriptionVisual: string = '26';
    public readonly EnablePermissions: string = '28';
    public readonly EditFirmUsers: string = '29';
    public readonly ViewVideoTutorials: string = '30';
    public readonly EditVideoTutorials: string = '31';
    public readonly ViewCoupons: string = '50';
    public readonly EditCoupons: string = '51';
    public readonly HealthChecks: string = '60';
    public readonly EditIntegrations: string = '62';
    public readonly CompanionRequest: string = '63';
}
export default new RightIds();
