
import { Component, Prop, Vue,Watch } from 'vue-property-decorator';
import { subscriptionService } from '../../utils/http'
import CustomerBase from '@/common/customer.base'


@Component({
  components: {
  },
})
export default class Subscription extends CustomerBase {
  @Prop({ required: true })
  subscriptionLockId: string;
  subscriptions: any[] = [];
  features: any[] = [];

  selectedSubscription: any = null;
  @Watch('subscriptionLockId') subscriptionLockChanged(value:string, oldValue:string){
    this.selectedSubscription=null;
  this.initialize();
 
}
get canEdit(){
  return this.hasAnyRight([this.RightIds.Subscriptions])
}
  async changeState(subscription: any) {
    var result = await subscriptionService.changeState(subscription.id, !subscription.isHistory);
    subscription.isHistory = result.isHistory;
  }
  subscriptionSelected(subscription: any) {
    this.selectedSubscription = subscription;
  }
  isActive(subscription: any) {
    if (this.selectedSubscription == null) return false;
    return this.selectedSubscription.id == subscription.id
  }
  async created() {
    await this.initialize();

  }
  async initialize() {
    this.isBusy(true);
    var data = await subscriptionService.getSubscriptions(this.subscriptionLockId)

    this.subscriptions = data.subscriptions.map(z => ({ id: z.id, name: z.name, isHistory: z.isHistory, features: z.features.map(f => ({ featureId: f, selected: true, name: '' })) }));
    this.features = data.features
    this.subscriptions.forEach(subscription => {
      this.features.forEach(feature => {
        var existing = subscription.features.find(z => z.featureId == feature.id);
        if (existing) {
          Vue.set(existing, 'selected', true);
          Vue.set(existing, 'isLoading', false);
          existing.name = feature.name;
        }
        else {
          var sFeature = { featureId: feature.id, name: feature.name, selected: false,isLoading:false }
          subscription.features.push(sFeature)
        }

      })
      subscription.features.sort(this.reportsSortFunction)
    })
    this.isBusy(false);
    if (!this.selectedSubscription) this.selectedSubscription = this.subscriptions[0]
  }
  reportsSortFunction(r1, r2) {
    return (r1.featureId == r2.featureId) ? 0 : ((r1.featureId > r2.featureId) ? 1 : -1)
  }
  async featureStateChanged(subscriptionId, feature: any) {
    feature.isLoading = true;
    await subscriptionService.updateSubscriptionFeatures(subscriptionId,[{featureId:feature.featureId,isEnabled:feature.selected}]);
    feature.isLoading = false;
  }
  async save() {
    this.isBusy(true);
    var payload = []
    this.subscriptions.forEach(subscription => {
      var payloadItem = { id: '', features: [] }
      payloadItem.id = subscription.id;
      payloadItem.features = subscription.features.filter(z => z.selected).map(z => z.featureId)
      payload.push(payloadItem)
    })
    await this.initialize();
    this.isBusy(false);
  }
  async enableAll(){
    let payload=this.selectedSubscription.features.map(z=>({featureId:z.featureId,isEnabled:true}))
    await subscriptionService.updateSubscriptionFeatures(this.selectedSubscription.id,payload);
  }
}
