import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
import store from '@/store';
import router from '@/router';
// tslint:disable-next-line:max-line-length
import { CustomerViewModel, JobDetailsViewModel,ClientsViewModel } from '@/common/models';
class ClientService {
 public getClients(): Promise<ClientsViewModel[]> {
   return httpService.get(Resources.getBaseUrl() + `clients`);
 }
}
export let clientService = new ClientService();
