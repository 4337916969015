
    import {
    
      Component,
    
      Prop,
    
      Vue
    
    } from "vue-property-decorator";
    
    
    
    
    import {
    
      dataIntegrityService
    
    } from "../../../utils/http";
    import CustomerBase from "@/common/customer.base";

    @Component({
    })
    
    export default class DuplicateData extends CustomerBase {
        @Prop()
  firmId: String;
    
    
      public data: any[] = [];
    
      
  
      created() {
  
  
      }
     decapitalizeTest = (text) => {
    return text && text[0].toLowerCase() + text.slice(1) || text;
};
  
      getData() {
  
        this.isBusy(true);
       
        dataIntegrityService.getDuplicateTimes(this.firmId).then((result) => {
        //@ts-ignore
         result.columns.forEach(col=>{
          col.id=this.decapitalizeTest(col.id)
         })
          this.isBusy(false);
          
          this.data = result;
  
  
  
        }).catch((err) => this.isBusy(false))
  
  
  
      }
  
    }
  