import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
// tslint:disable-next-line:max-line-length
class BidirectionalEmailService {
 public getStatus(firmId:String): Promise<any> {
   return httpService.get(`${Resources.getBaseUrl()}BidirectionalEmail/${firmId}`);
 }
 public setupBidirectionalEmail(firmId:String): Promise<any> {
  return httpService.post(`${Resources.getBaseUrl()}BidirectionalEmail/${firmId}`,{});
}

 
}
export let bidirectionalEmailService = new BidirectionalEmailService();
