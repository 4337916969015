<template>
  <content-placeholders v-if="!logEntry">
    <content-placeholders-text :lines="15" />
  </content-placeholders>
  <b-card class="m-0" v-else>
    <b-card-title class="mt-0">
     
      <b-badge variant="link" v-if="!isRelatedEntry" class="ml-2"><i class="fa fa-clock"></i> {{ logEntry.timestamp }}</b-badge>
  
      <b-badge variant="link" title="Name" class="ml-2" >{{ logEntry.entityTypeName }} <u> {{ logEntry.displayName }}</u>
      
      </b-badge>
       <b-badge  class="ml-1" :variant="logEntry.actionVariant" >{{
        logEntry.actionTypeName
      }}</b-badge>
    </b-card-title>

    <PropertyVisualizer
      :isChangeMode="logEntry.isChange"
      :properties="logEntry.properties"
    >
    
    </PropertyVisualizer>
    <div v-for="entry in logEntry.relatedEntries">
     <ActivityEntry :isRelatedEntry="true" :id="entry"/>
    </div>
  </b-card>
</template>
<script>
import activityLogService from "../../../utils/http/activitylog.service";
import PropertyVisualizer from "./PropertyVisualizer.vue";
import { ActivityLogEntry } from "./ActivityLogEntry";
import ActivityEntry from './ActivityEntry.vue'
export default {
  name:'ActivityEntry',
  components: {
    PropertyVisualizer,
    ActivityEntry
  },
  props: {
    isRelatedEntry:{
       type: Boolean,
      default: false,
    },
    firmId: {
      type: String,
      required:true
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      logEntry: null,
    };
  },
  created() {
    this.loadLogEntry();
  },
  methods: {
    loadLogEntry() {
      if (!this.id) this.logEntry = null;
      activityLogService.getLogEntry(this.firmId,this.id).then((log) => {
        this.logEntry = new ActivityLogEntry().fromPayload(log);
      });
    },
  },
};
</script>