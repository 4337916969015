import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
class FirmUsersService {
 public getUsers(firmId:String,role:number): Promise<any> {
   return httpService.get(Resources.getBaseUrl() + `firmusers/byfirm/${firmId}?role=${role}`);
 }
 public getEmployees(firmId:String): Promise<any> {
  return httpService.get(Resources.getBaseUrl() + `firmusers/employees/${firmId}`);
}
 public removeFirmUser(id:Number): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `firmusers/removefirmuser/${id}`,{});
}
public removeUserLoginFlow(id:Number): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `firmusers/removeUserSecurity/${id}`,{});
}
public addUser(firmId:String,email:String,role:number, employeeId:string): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `firmusers/addfirmuser`,{
    firmId:firmId,
    email:email,
    role:role,
    employeeId:employeeId
  });
}
 

}
export let firmUSersService = new FirmUsersService();
