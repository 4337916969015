
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  components: {
  
  },
})
export default class Logout extends Vue{
   
    async logout() {
         //@ts-ignore
     this.$msal.signOut();
      
     
    }
    created(){
        this.logout();
    }
}
