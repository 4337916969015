
  import { Component, Prop, Vue } from "vue-property-decorator";
  import ComponentBase from "../../common/component.base";
  import { integrationService } from "../../utils/http";
  import IntegrationStats from './IntegrationStats.vue'
  
  @Component({
  components: {
    IntegrationStats
  },
})
  export default class IntegratedFirmsList extends ComponentBase {
   
    
  
  @Prop()
  firmIntegrations: any[];  
  showIntegrationStatsUI:boolean=false;
  showDiagnosticUI:boolean=false;
  selectedIntegrationId:string='';
  currentDiagnostics:any[]=[]
  fields = [
      
      { key: "firmUrlCode", sortable: true,label:'Firm Code' },
      { key: "firmName", sortable: true,label:'Firm Name' },
      { key: "setupDate", sortable: true,label:'Date' },
      { key: "userName", sortable: true,label:'Employee' },
      { key: "status", sortable: true,label:'Status' },
      { key: "statusMessage", sortable: true,label:'StatusMessage' },
      { key: "integratedAccountName", sortable: true,label:'Account Name' },
      { key: "stats", sortable: false,label:'Statistics' },
      { key: "diagnostics", sortable: false,label:'Diagnostics' },
      
    ];
    showIntegrationStats(id:string){
      this.showIntegrationStatsUI=true;
      this.selectedIntegrationId=id;
    }
    showDiagnostics(id:string){
      let integration = this.firmIntegrations.find(z=>z.id==id);
      
      integrationService.getDiagnostics(id).then(result=>{
        this.showDiagnosticUI=true;
       this.currentDiagnostics=result;
       console.log('diagno',result)
      })
    }

    exportToExcel(){
      console.log(this.firmIntegrations)
        this.exportTableToExcel("integrations-table")
      }
  }
  