import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
class CompanionRequestService {
 
  public getRequests(): Promise<any> {
    return httpService.get(Resources.getBaseUrl() + `CompanionRequests`);
  }

  public addRequest(firmId:String, employeeId:String, reason:String): Promise<any> {
    return httpService.post(Resources.getBaseUrl() + `CompanionRequests`,{
      firmId:firmId,
      employeeId:employeeId,
      reason:reason
    });
  }
  public redeem(id:String): Promise<any> {
    return httpService.post(Resources.getBaseUrl() + `CompanionRequests/redeem/${id}`,{
    });
  }
  public delete(id:String): Promise<any> {
    return httpService.delete(Resources.getBaseUrl() + `CompanionRequests/${id}`,{});
  }
  
 
}
export let companionRequestService = new CompanionRequestService();
