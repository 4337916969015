
  import {
  
    Component,
  
    Prop,
  
    Vue
  
  } from "vue-property-decorator";
  
  
  import moment from 'moment';
  
  
  
  import {
  
   ClientsViewModel
  
  } from "../../common/models";
  
  import LoadCustomer from '../common/LoadCustomer.vue'
  
  
  import {
  
    clientService
  
  } from "../../utils/http";
  
  import CustomerBase from '@/common/customer.base'
  
  import store from '@/store'
  
  import {
  
    stat
  
  } from 'fs';
  
  @Component({
  

  
  })
  
  export default class MonthlyReport extends CustomerBase {
  
  
    fields = [
      {key:'firm',  sortable: true },
      {key:'email',  sortable: true },
      {key:'phone', sortable: true },
      {key: 'createdDate',  sortable: true },
        {key: 'status', sortable: true },
        {key: 'lastActivity', sortable: true },
        {key: 'subscription', sortable: true },
        {key: 'subscriptionStatus', sortable: true },
        {key: 'cancelationReason', sortable: true },
      ];

      
    filter = null;
  
  
    public clients: ClientsViewModel[] = [];
  
    

    created() {


      this.getClients();

    }

    getClients() {

      this.isBusy(true);

      clientService.getClients().then((result) => {

        this.isBusy(false);

        this.clients = result;



      }).catch((err) => this.isBusy(false))



    }

  }
