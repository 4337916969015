
import { Component, Prop, Vue } from 'vue-property-decorator';
import {LoginModel,TokenResultModel, TreeItem, Reports} from '../../common/models'
import {httpService,rightsService,listService} from '../../utils/http'
import ActionButton from '../common/ActionButton.vue'
import FormError from '../common/FormError.vue'
import CustomerBase from '@/common/customer.base'

import store from '@/store'
import { createVerify } from 'crypto';

@Component({
  components: {
    ActionButton,
    FormError,
  },
})
export default class Subscription extends CustomerBase{
    rights: any[]=[];
    users: any[]=[];
    public selectedUserId=null;

    // async changeState(subscription:any){
    //    var result= await subscriptionService.changeState(subscription.id, !subscription.isHistory);
    //    subscription.isHistory = result.isHistory;
    //  }

async userChanged(userId){
    
       try{
            this.isBusy(true);
       this.selectedUserId=userId
     this.rights= await rightsService.getRights(userId);
        
      this.isBusy(false);
      }
      catch(err) {
       this.isBusy(false);
      }
 }
  async  created (){
      await this.initialize();
                
    }
    async initialize(){
      try{
            this.isBusy(true);
        // var data =await rightsService.getRights()
        this.users=(await listService.getUsers()).map(z=>({value:z.id, text:z.value}))
        
      this.isBusy(false);
      }
      catch(err) {
       this.isBusy(false);
      }
    }
   
   async save(){
       try{
            this.isBusy(true);
        // var data =await rightsService.getRights()
        this.rights= await rightsService.update(this.selectedUserId, this.rights);
        
      this.isBusy(false);
      }
      catch(err) {
       this.isBusy(false);
      }
   }
  
}
