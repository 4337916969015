
  import { Component, Prop, Vue } from "vue-property-decorator";

 import { backgroundEventsService } from "../../utils/http";
  import CustomerBase from "@/common/customer.base";
  import VueJsonPretty from "vue-json-pretty";

import { logger } from "@azure/storage-blob";
  
  import EventDetails from './EventDetails.vue'
  @Component({
    components: {
        VueJsonPretty,
        EventDetails
    }
  })
  export default class BackgroundEvents extends CustomerBase {
    events: any[] = [];
    groupedEvents:any[]=[]
   
    
    async created() {
      await this.initialize();
    }
    async initialize() {
      try {
        this.isBusy(true);
        // var data =await rightsService.getRights()
        this.events = await backgroundEventsService.getFailedEvents();
        this.groupedEvents=this.groupEvents(this.events);
        this.isBusy(false);
      } catch (err) {
        this.isBusy(false);
      }
    }
     groupEvents(data){
     
    
// Group by 'type', 'subtype', and 'tenantId'
let grouped = data.reduce((grouped, item) => {
    let typeKey = item.type;
    let subtypeKey = item.subType;
    let tenantKey = item.tenantCode;

    if (!grouped[typeKey]) {
        grouped[typeKey] = {};
    }

    if (!grouped[typeKey][subtypeKey]) {
        grouped[typeKey][subtypeKey] = {};
    }

    if (!grouped[typeKey][subtypeKey][tenantKey]) {
        grouped[typeKey][subtypeKey][tenantKey] = [];
    }

    grouped[typeKey][subtypeKey][tenantKey].push(item);

    return grouped;
}, {});

// Convert the grouped object into the desired array structure
let groupedArray = Object.keys(grouped).map(type => {
    let subTypes = Object.keys(grouped[type]).map(subtype => {
        let tenants = Object.keys(grouped[type][subtype]).map(tenantId => {
            let events = grouped[type][subtype][tenantId];
            return {
                tenantId: tenantId,
                numberOfEntries: events.length,
                events: events,
                showDetails:false
            };
        });

        return {
            subType: subtype,
            tenants: tenants
        };
    });

    return {
        type: type,
        subTypes: subTypes
    };
});

console.log(groupedArray);

return groupedArray;
     }
  }
  