
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
// import multiMonthPlugin from '@fullcalendar/multimonth'

import { Component, Prop, Vue } from "vue-property-decorator";

import { httpService } from "../../../utils/http";
import CustomerBase from "@/common/customer.base";
import Resources from "@/common/resources";
import tools from '../../../utils/miscellaneous'
import dateTimeUtility from '@/utils/datetime.utils'
@Component({
  components: {
    FullCalendar

  }
})
export default class FirmStats extends CustomerBase {
  calendarOptions = {
    plugins: [
      // multiMonthPlugin,
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin // needed for dateClick
    ],
    multiMonthMaxColumns:2,
    headerToolbar: {
      right: 'prev,next today',
      left: 'title',
      //  right: ''// 'dayGridMonth,timeGridWeek,timeGridDay'
    },
    initialView:'dayGridMonth',// 'multiMonthYear',
    events: async (info, successCallback, failureCallback)=> await this.loadEvents(info)  ,
    editable: false,
    selectable: false,
    selectMirror: true,
    dayMaxEvents: false,
    weekends: true,
    select: this.handleDateSelect,
    eventClick: this.handleEventClick,
    eventsSet: this.handleEvents,
    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
    timeZone: 'UTC',
  }
  currentEvents = []
  isLoading = false;
  data = null;
  filter: string = null;
  filterOn = [];
  year: number = new Date().getFullYear();

 
  public created() {
    
  }
  async loadEvents(dataInfo){
   let start=new Date(dataInfo.startStr).toISOString();
   let end=new Date(dataInfo.endStr).toISOString();
   let events= await this.getEventData(start,end)
   return events;
  }

  handleDateSelect(selectInfo) {
    return;
    let title = prompt('Please enter a new title for your event')
    let calendarApi = selectInfo.view.calendar
    calendarApi.unselect() // clear date selection
    if (title) {
      calendarApi.addEvent({
        id: tools.uuidv4(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      })
    }
  }
  handleEventClick(clickInfo) {
    return;
    if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      clickInfo.event.remove()
    }
  }
  handleEvents(events) {
    return;
    console.log('handle events')
    this.currentEvents = events
  }
 async getEventData(start,end) {
    this.isLoading = true;
   return await httpService
      .get(
        Resources.getBaseUrl() + `FirmStats/licensestats/daily?start=${start}&end=${end}`
      )
      .then((result: any[]) => {
        this.isLoading = false;
        return result.map((item) => {
         let outout= {
        id: tools.uuidv4(),
        title: item.firmUrl,
        start:dateTimeUtility.combine(dateTimeUtility.toDate(item.date),'13:00'),
        data:item
      };
      return outout;
        });
      
      });
  }



}
