
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import componentBase from "../../common/component.base";
import { clientPortalService } from "../../utils/http";

@Component({
  components: {
    
  },
})
export default class extends componentBase {
  public files: any[]=[];
  public currentPage:number=0;
  public perPage:number=50;
  public filter:string=''
   public fields: any[] = [
    {
      label: "File",
      key: "displayName",
      sortable: true,
    },
    {
      label: "Client",
      key: "clientName",
      sortable: true,
    },
     {
      label: "C.Code",
      key: "clientCode",
      sortable: true,
    },
    {
      label: "C.Folder",
      key: "clientFolder",
      sortable: true,
    },
    {
      label: "F.Folder",
      key: "firmFolder",
      sortable: true,
    },
    {
      label: "Date",
      key: "sharedOn",
      sortable: true,
    },
    // {
    //   label: "Sent",
    //   key: "isSentByFirm",
    //   sortable: true,
    // }
  ];
   @Prop()
  firmId: String;
  async created() {
    this.fetchData();
  }

  async fetchData() {
    this.files = await clientPortalService.getFiles(this.firmId);
   
  }
 
  
}
