
  import { Component, Prop, Vue } from "vue-property-decorator";
  import ComponentBase from "@/common/component.base";
  import { couponService } from "../../utils/http";
  import CouponTypeInput from './CouponTypeInput.vue'
  import { CouponType } from './common/CouponType';

  @Component({
    components: {
        CouponTypeInput
    }
  })
  export default class Coupons extends ComponentBase {
   
  
    coupon:{
        code:string,
        description:string,
        type:number,
        trialDays:number,
        maxUseage:number,
        expireDate:Date,
    }={
        code:this.generateCouponCode(),
        description:'',
        type:CouponType.Nonchargeable,
        trialDays:5,
        expireDate:null,
        maxUseage:1
    }
  

    get canHaveTrialDays(){
        return this.coupon.type==CouponType.Trial;
    }
   
    created() {
    
    
      
    }
    generateCouponCode() {
  var length = Math.floor(Math.random() * 9) + 4;  // Random length between 4 and 8
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  var charactersLength = characters.length;
  
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  
  return 'TPS-' + result;
}
    async saveCoupon(e) {
       e.preventDefault();
       this.isBusy(true, "Validating coupon...");
       const exists= await couponService.couponExists(this.coupon.code);
       if(exists){
        this.isBusy(false);
        this.info("Coupon already exists","A coupon with the same code already exists. Please choose a different code.");
        return;
       }
       this.isBusy(true, "Adding coupon...");
       let payload={...this.coupon};
       if(!this.canHaveTrialDays){
           payload.trialDays=null;
       }
      couponService.addCoupon(this.coupon).then((res) => {
        this.isBusy(false);
       this.$emit('added',res);
       
      });

  }
  }
  