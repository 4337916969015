import { render, staticRenderFns } from "./MessageResult.vue?vue&type=template&id=42ec604b&scoped=true&"
import script from "./MessageResult.vue?vue&type=script&lang=ts&"
export * from "./MessageResult.vue?vue&type=script&lang=ts&"
import style0 from "./MessageResult.vue?vue&type=style&index=0&id=42ec604b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42ec604b",
  null
  
)

export default component.exports