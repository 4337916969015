
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import componentBase from "../../common/component.base";
import PowerBireports from './PowerBIReports.vue'
import PowerBiJobs from './PowerBIJobs.vue'
@Component({
  components: {
    PowerBireports,
    PowerBiJobs
  },
})
export default class extends componentBase {

   @Prop()
  firmId: String;
  async created() {
  }
 
  
}
