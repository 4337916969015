
import {ActivityLogActionType} from '../../../common/enums'
import {ActivityLogProperty} from './ActivityLogProperty'
export class ActivityLogEntry {
    actionType= 0
    actionTypeName=''
    properties=[]
    displayName=''
    entityType=''
    entityTypeName=''
    timestamp=''
    relatedEntries=[]
   
  fromPayload(logSet){
    if(!logSet) return this;
     this.actionType=logSet.actionType;
     this.actionTypeName=logSet.actionTypeName;
     this.properties=logSet.properties.map(p=>new ActivityLogProperty().fromPayload(p))
     this.displayName=logSet.displayName;
     this.entityType=logSet.entityType;
     this.entityTypeName=logSet.entityTypeName
     this.relatedEntries=logSet.relatedEntries;
     this.timestamp=logSet.timestamp;
     

   return this;
  }
  get actionVariant(){
    switch(this.actionType){
      case ActivityLogActionType.Create: return 'success';
      case ActivityLogActionType.Update: return 'info';
      case ActivityLogActionType.Delete: return 'danger';
      default: return ''
    }
  }
  get isChange() {
    return  this.actionType == ActivityLogActionType.Update
  }
 
}
