import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
class CouponService {
 
  public getSingle(id:number): Promise<any> {
    return httpService.get(Resources.getBaseUrl() + `coupons/${id}`);
  }
  public couponExists(code:string): Promise<any> {
    return httpService.get(Resources.getBaseUrl() + `coupons/exists?code=${code}`);
  }
  public getUseageHistory(id:number): Promise<any[]> {
    return httpService.get(Resources.getBaseUrl() + `coupons/history/${id}`);
  }
 public getCoupons(): Promise<any[]> {
   return httpService.get(Resources.getBaseUrl() + `coupons`);
 }
 public addCoupon(coupon:any): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `coupons`,coupon);
}
public updatedCoupon(id:number,coupon:any): Promise<any> {
  return httpService.put(Resources.getBaseUrl() + `coupons/${id}`,coupon);
}
public toggleStatus(id:number,isActive:boolean): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `coupons/${id}/status`,{isActive:isActive});
}
 
}
export let couponService = new CouponService();
