
import { Component, Prop, Vue } from "vue-property-decorator";
import { httpService, dbService } from "../../utils/http";
import { debounce } from "../../utils/debounce";
import ActionButton from "../common/ActionButton.vue";
import FormError from "../common/FormError.vue";
import CustomerBase from "@/common/customer.base";

import store from "@/store";

@Component({
  components: {
    ActionButton,
    FormError
  }
})
export default class FirmNameChange extends CustomerBase {
  @Prop()
  firmId: String;
  
  @Prop()
  existingUrl: String;
 
  newUrl: String='';

  async created() {
  }
 
  async save(e:Event) {
      e.preventDefault();
    try {
    this.isBusy(true);
      var isUrlTaken= await dbService.isUrlAvailable(this.newUrl)
      if(isUrlTaken){
       this.info("Warning", "This url is already taken")

      }
      else{
       await dbService.changeUrl(this.newUrl,this.firmId);
             this.info("Success", "Job has started, monitor it in the job list")

      }
      this.isBusy(false);
    } catch (err) {
       this.isBusy(false);
       this.info("Failure", "Something went wrong")

    }
  }
}
