
  import { Component, Prop, Vue } from "vue-property-decorator";
  import ComponentBase from "@/common/component.base";
  import { couponService } from "../../utils/http";
  import CouponTypeInput from './CouponTypeInput.vue'
  import { CouponType } from './common/CouponType';
  import dateTimeUtility from "@/utils/datetime.utils";
  @Component({
    components: {
        CouponTypeInput
    }
  })
  export default class Coupons extends ComponentBase {
    @Prop()
    id: number;
  
    coupon:any=null;
  

    get canHaveTrialDays(){
        return this.coupon.type==CouponType.Trial;
    }

   
    created() {
    
      this.isBusy(true, "Loading coupon...");
      couponService.getSingle(this.id).then((res) => {
        this.isBusy(false);
        if(res.expireDate) res.expireDate=dateTimeUtility.format(res.expireDate,dateTimeUtility.dateFormat);
        this.coupon=res;
      });
      
    }
    async saveCoupon(e) {
       e.preventDefault();

       this.isBusy(true, "Updating coupon...");
      couponService.updatedCoupon(this.id, this.coupon).then((res) => {
        this.isBusy(false);
       this.$emit('updated',res);
       
      });

  }
  }
  