
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import componentBase from "../../common/component.base";
import { powerBIService,jobTypes } from "../../utils/http/powerbi.service";
import { notificationService } from "../../utils/notification/notification.service";
import PowerBiJobs from "./PowerBIJobs.vue";
@Component({
  components: {
    PowerBiJobs,
  },
})
export default class extends componentBase {
  workspaces: any[] = [];
  powerBIJobTypes:any= jobTypes;
  fields = [
    { key: "firmUrl", sortable: true, label: "Firm" },
    { key: "powerBIReportCount", sortable: true, label: "Reports" },
    {
      key: "unconfiguredPowerBIReports",
      sortable: true,
      label: "Unconfigured Reports",
    },
    { key: "jobsInProgress", sortable: true, label: "Jobs" }
  ];

  created() {
    this.getData();
  }
  getData() {
    this.isBusy(true);
    powerBIService
      .getWorkspaces()
      .then((result) => {
        this.isBusy(false);

        this.workspaces = result;
      })
      .catch((err) => this.isBusy(false));
  }
  scheduleJob(firmId:string,jobType:Number) {
    powerBIService.schedulePowerBiJob([firmId],jobType).then((result) => {
      this.info(
        "Scheduled",
        "Update has been scheduled. This will take up to 40 minutes for a single firm. Depeneding on how many power bi jobs are in porgress this could take time to complete. Please do not schedule jobs for the same firm"
      );
    });
  }
  // async updateAllUnconfigured(){
  // var firmIds= this.workspaces.filter(z=>z.unconfiguredPowerBIReports>0).map(z=>z.firmId);
  // if(firmIds.length==0){
  //     return this.info('Nothing to update',"There are no firms with unconfigured reports")
  // }
  //            await this.scheduleMultiUpdate(firmIds);
  // }
 async batchRunJob(jobType:Number){
            var firmIds= this.workspaces.map(z=>z.firmId);
            var confirmation= await this.multiConfim("Confirm",['Are you sure you want to perform action for all reports?','Second confirmation','Final warning']);
      if(!confirmation) return;
      powerBIService.schedulePowerBiJob(firmIds,jobType).then(result=>{
          this.info("Job scheduled","Check the job details for firms to view the status. ");
      }).catch(err=>this.info("Error","Something went wrong during the scheduling of the job"))
  }
  }

