import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
import store from '@/store';
import router from '@/router';
// tslint:disable-next-line:max-line-length
import { CustomerViewModel, JobDetailsViewModel,ResteJasperCredentialsResult, Reports, TreeItem } from '@/common/models';
class ReportsService {
 public getReports(): Promise<Array<Reports>> {
   return httpService.get(Resources.getBaseUrl() + `reports`);
 }
 public setReports(reports: Reports[]): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `reports`,reports);
}
 



}
export let reportsService = new ReportsService();
