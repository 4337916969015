import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
class IntegrationService {
 
  public getIntegrations(): Promise<any> {
    return httpService.get(Resources.getBaseUrl() + `ThirdPartyIntegrations`);
  }

  public getDiagnostics(id:string): Promise<any[]> {
    return httpService.get(Resources.getBaseUrl() + `ThirdPartyIntegrations/diagnostics/${id}`);
  }
  public getStats(id:string=null): Promise<any[]> {
    return httpService.get(Resources.getBaseUrl() + `ThirdPartyIntegrations/stats/?integrationId=${id||''}`);
  }
 
  public markForReconnect(id:String): Promise<any> {
    return httpService.post(Resources.getBaseUrl() + `ThirdPartyIntegrations/markforreconnect/${id}`,{});
  }
 
 
}
export let integrationService = new IntegrationService();
