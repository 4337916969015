
import { Component, Prop, Vue } from "vue-property-decorator";
import VueJsonPretty from 'vue-json-pretty'

import {
  JasperCustomerViewModel, JobStatus, JobType, ResteJasperCredentialsResult
} from "../../common/models";
import LoadCustomer from '../common/LoadCustomer.vue'
import { jasperService } from "../../utils/http";
import CustomerBase from '@/common/customer.base'
import store from '@/store'
@Component({
  components:{
    VueJsonPretty,LoadCustomer
  }
})
export default class JasperOperations extends CustomerBase {
  fields= [ 
  {
            key: 'label',
            sortable: true
          },
          {
            key: 'username',
            sortable: true
          },
          {
            key: 'password',
          },
          {
            key: 'actions',
          }
   ];
  customers: JasperCustomerViewModel[]= [];
viewCredentials(id){
  this.isBusy(true);
  jasperService.viewCredentials(id).then(result=>{
    this.isBusy(false);
      var customer= this.customers.find(z=>z.id==result.id)
           if(customer){
             customer.username = result.username;
             customer.password = result.password;
           }
  })
}
  resetJasper(id){
      this.confirm("Just making sure", "Are you sure you want to perform this operation. This operation will create new credentials for the jasper account for this firm").then(result=>{
        if(result){
           this.isBusy(true);
    jasperService.resetJasperCredentials([id]).then(result=>{
       this.isBusy(false);
         result.forEach(element => {
           var customer= this.customers.find(z=>z.id==element.id)
           if(customer){
             customer.username = element.username;
             customer.password = element.password;
           }
         });
   }).catch(err=>this.isBusy(false))
        }
      })
  }
  
  created(){
    this.isBusy(true)
    jasperService.getCustomers().then(result=>{
      this.isBusy(false)
      result.forEach(item=>{item.password="Visible after reset. (Only once)"})
      this.customers= result;

    }).catch(err=>this.isBusy(false))
  }
 
}
