
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import componentBase from "../../common/component.base";

@Component({
  components: {},
})
export default class extends componentBase {
  permissions: string = '';
  onlyIfHasRight: number = null;
  async created() {
   
  }

 
  enablePermissions() {
    if (!this.permissions) return;
    this.$emit("execute", this.permissions, this.onlyIfHasRight);
  }
}
