import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
import store from '@/store';
import router from '@/router';
class HealthCheckService {
 public getChecks(firmId:string=''): Promise<any[]> {
   return httpService.get(Resources.getBaseUrl() + `healthcheck?firmId=${firmId||''}`);
 }
 public getCheckResults(checkId:number): Promise<any[]> {
  return httpService.get(Resources.getBaseUrl() + `healthcheck/${checkId}/results`);

}
public runChecks(firmId:string=''): Promise<any[]> {
  return httpService.post(Resources.getBaseUrl() + `healthcheck/runchecks?firmId=${firmId||''}`,{});
}
public runFixes(id:number): Promise<any[]> {
  return httpService.post(Resources.getBaseUrl() + `healthcheck/runfixes/${id}`,{});
}
public runAllFixes(): Promise<any[]> {
  return httpService.post(Resources.getBaseUrl() + `healthcheck/runallfixes`,{});
}
}
export let healthCheckService = new HealthCheckService();
