
import Vue from 'vue'
class MessageService {
    public confirm(title: string, message: string) {
        return new Promise((resolve, reject) => {
            // @ts-ignore
           return swal({
                title: title,
                text: message,
                buttons: true,
                dangerMode: true
            })
                .then((willDelete) => {
                    resolve(willDelete);
                });

        })
    }
    public info(title: string, message: string):Promise<void> {
        return new Promise((resolve, reject) => {
            // @ts-ignore
           return   swal({
               title: title,
               text: message,
           }).then((willDelete) => {
                    resolve(willDelete);
                });

        })
       
    }
    public toast(title:string,message:string,variant='', autoHide:number=5000){
        //@ts-ignore
        Vue.prototype.$toast.toast(message, {
        
            title: title,
            autoHideDelay: autoHide,
            variant: variant,
            appendToast: true,
            toaster: 'b-toaster-top-right',
            solid: true,
          })
    }
}
export const messageService = new MessageService();
