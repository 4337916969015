
  import { Component, Prop, Vue } from "vue-property-decorator";
  
  import CustomerBase from "@/common/customer.base";
  import DailyLicenseStats from './DailyLicenseStats.vue'
  import FirmCanceledStats from './FirmCanceledStats.vue'
  @Component({
    components: {
        FirmCanceledStats,
        DailyLicenseStats

    }
  })
  export default class LicenseStats extends CustomerBase {
   
  }
  