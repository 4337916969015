export default {
    crateReportOptions(title,start,end){
        return {
            chart: {
              legend: {
                show: true,
                showForSingleSeries: false,
                showForNullSeries: true,
                showForZeroSeries: true,
                position: 'bottom',
                horizontalAlign: 'center', 
                floating: false,
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial',
                fontWeight: 400,
                formatter: undefined,
                inverseOrder: false,
                width: undefined,
                height: undefined,
                tooltipHoverFormatter: undefined,
                customLegendItems: [],
                offsetX: 0,
                offsetY: 0,
                labels: {
                    colors: undefined,
                    useSeriesColors: false
                },
                markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 0,
                    strokeColor: '#fff',
                    fillColors: undefined,
                    radius: 12,
                    customHTML: undefined,
                    onClick: undefined,
                    offsetX: 0,
                    offsetY: 0
                },
                itemMargin: {
                    horizontal: 5,
                    vertical: 0
                },
                onItemClick: {
                    toggleDataSeries: true
                },
                onItemHover: {
                    highlightDataSeries: true
                },
            },
              // selection: {
              //   enabled: true
              // },
              sparkline: {
                enabled: false,
            },
              type: 'area',
              stacked: false,
              // height: 350,
              zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
              },
              toolbar: {
                autoSelected: 'zoom'
              },
              animations: {
                enabled: false,
                },
            },
          
            dataLabels: {
              enabled: false
            },
            markers: {
              size: 0,
            },
            title: {
              text: title,
              align: 'left'
            },
            fill: {
              type: 'gradient',
              gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
              },
            },
            yaxis: {
              labels: {
                formatter: function (val) {
                  return val;
                  // return (val / 1000000).toFixed(0);
                },
              },
              title: {
                text: 'Triggered Reports'
              },
            },
            xaxis: {
              type: 'datetime',
              // tickAmount: 8,
              min: new Date(start).getTime(),
              max: new Date(end).getTime(),
            },
            tooltip: {
              shared: false,
              y: {
                formatter: function (val) {
                  return val;
                  // return (val / 1000000).toFixed(0)
                }
              }
            }
          }
    },
    getDailyData(data,start,end) {
        const groups = data.reduce((groups, item) => {
          const date: number = new Date(item.time.split('T')[0]).getTime();
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(item);
          return groups;
        }, {});
        var datesInRange=this.getDatesInRange(start,end).map((v)=>v.toISOString().slice(0,10))
        
        datesInRange.forEach(d=>{
          const date: number = new Date(d).getTime();

        if(!groups[date])
        {
          groups[date]=[];

        }
        })
        return Object.keys(groups).map((date) => {
          return {
            date: Number.parseInt(date),
            items: groups[date]
          };
        }).sort((a, b) => a.date - b.date)
    },
    groupBy(data,key) {
      const groups = data.reduce((groups, item) => {
        if (!groups[item[key]]) {
          groups[item[key]] = [];
        }
        groups[item[key]].push(item);
        return groups;
      }, {});
      return Object.keys(groups).map((groupKey) => {
        return {
          key: groupKey,
          items: groups[groupKey]
        };
      })
    },
     getDatesInRange(start, end) {
      for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
        arr.push(new Date(dt));
    }
    return arr;
    }
}