
import { Component, Prop, Vue } from "vue-property-decorator";
import {usersService} from "../../utils/http";
  import CustomerBase from "@/common/customer.base";
  
  
@Component({


})
export default class UserAssosiations extends CustomerBase {
userFirms:any[]=[]
@Prop()
userId: string;

constructor() {
super();
}
created() {
      this.getData();
     
    }
    getData() {
     usersService.getUserAccess(this.userId).then(result=>{
        this.userFirms=result;
        
     })
    }
}
