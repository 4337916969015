import * as msal from "@azure/msal-browser";
import Vue, { PluginObject, VueConstructor } from "vue";
import jwt_decode from "jwt-decode";
let msalInstance;

export let msalPluginInstance;

export class MsalPlugin {

    userProperties=null
    pluginOptions = {
        clientId: "",
        loginAuthority: "",
        passwordAuthority: "",
        knownAuthority: "",
        redirectUri: '',
        postLogoutRedirectUri: '',
        scopes: []
    };

    isAuthenticated = false;
    accountId=''


    install(vue, options) {
        if (!options) {
            throw new Error("MsalPluginOptions must be specified");
        }
        this.pluginOptions = options;
        this.initialize(options);
        msalPluginInstance = this;
        vue.prototype.$msal = Vue.observable(msalPluginInstance);
        this.isAuthenticated= this.getIsAuthenticated();
        msalInstance.handleRedirectPromise().then(()=>{
            this.isAuthenticated= this.getIsAuthenticated();
        });
    }

    initialize(options) {

        const msalConfig = {
            auth: {
                clientId: options.clientId,
                authority: options.loginAuthority,
                knownAuthorities: [options.knownAuthority],
                redirectUri: options.redirectUri,
                postLogoutRedirectUri: options.postLogoutRedirectUri,
                navigateToLoginRequestUrl :false
            },
            system: {
                loggerOptions: {
                    loggerCallback: (level, message, containsPii) => {
                        if (containsPii) {
                            return;
                        }
                        switch (level) {
                            case msal.LogLevel.Error:
                                console.error(message);
                                return;
                            case msal.LogLevel.Info:
                                // console.info(message);
                                return;
                            case msal.LogLevel.Verbose:
                                // console.debug(message);
                                return;
                            case msal.LogLevel.Warning:
                                // console.warn(message);
                                return;
                        }
                    },
                    piiLoggingEnabled: false,
                    logLevel: msal.LogLevel.Verbose
                }
            }
        };
        msalInstance = new msal.PublicClientApplication(msalConfig);
        this.isAuthenticated = this.getIsAuthenticated();
      
       
    }
     listenFormRedirectPromise(handler){
        msalInstance.handleRedirectPromise().then(handler).catch(err=>{
           
            if ( err.errorMessage.indexOf("AADB2C99002") > -1) {
               alert('Account does not exist. There is no user linked to this account. First login with your username and password navigate to your profile and link an external account to use to login')
            }
        })
     }
    getLoginRequest(){
       return  {
            scopes: this.pluginOptions.scopes,
        };
    }
    async signIn() {
        try {
            //clear interaction
            sessionStorage.clear();

           

            const loginResponse = await msalInstance.loginRedirect(this.getLoginRequest());
            this.isAuthenticated = !!loginResponse.account;
            // do something with this?
        } catch (err) {
            // handle error
            console.log('autherror',err)
            if (err.errorMessage && err.errorMessage.indexOf("AADB2C90118") > -1) {
                try {
                    const passwordResetResponse = await msalInstance.loginPopup({
                        scopes: this.pluginOptions.scopes,
                        authority: this.pluginOptions.passwordAuthority
                    });
                    this.isAuthenticated = !!passwordResetResponse.account;
                } catch (passwordResetError) {
                    console.error(passwordResetError);
                }
            } else {
                this.isAuthenticated = false;
            }

        }
    }
    
 
    async signOut() {
        await msalInstance.logout();
        this.isAuthenticated = false;
    }

    async acquireToken() {
        const request = {
            account: msalInstance.getAllAccounts()[0],
            scopes: this.pluginOptions.scopes
        };
        try {
            const response = await msalInstance.acquireTokenSilent(request);
            console.log('tokens',response)
            return response.accessToken;
        } catch (error) {
            if (error instanceof msal.InteractionRequiredAuthError) {
                return msalInstance.acquireTokenPopup(request).catch((popupError) => {
                    console.error(popupError);
                });
            }
            return false;
        }
    }
    handleResponse(response) {
        
        if (response !== null) {
            msalPluginInstance.accountId = response.account.homeAccountId;
            // Display signed-in user content, call API, etc.
        } else {
            // In case multiple accounts exist, you can select
            const currentAccounts = msalInstance.getAllAccounts();

            if (currentAccounts.length === 0) {
                // no accounts signed-in, attempt to sign a user in
                // msalInstance.loginRedirect(this.getLoginRequest());
                // router.push("/login")
                return;
            } else if (currentAccounts.length > 1) {
                msalPluginInstance.accountId = currentAccounts[0].homeAccountId;
            } else if (currentAccounts.length === 1) {
                msalPluginInstance.accountId = currentAccounts[0].homeAccountId;
            }
        }
        // router.push("/accountpicker")
        
    }
    getIsAuthenticated() {
        const accounts = msalInstance.getAllAccounts();
        return accounts && accounts.length > 0;
    }
    async getUserProperties() {
        
        var token= await this.acquireToken()
var decoded = jwt_decode(token);
console.log('decode token',decoded)
       return {
            objectId:decoded['oid'],
            name:decoded['given_name']
        }
    }
}
