
import { Component, Prop, Vue } from "vue-property-decorator";
import { BlockBlobClient } from "@azure/storage-blob";

import CustomerBase from "@/common/customer.base";
import { videoTutorialsService } from "@/utils/http";

@Component({
  components: {
  },
})
export default class VideoTutorials extends CustomerBase {
  playVideoLink: string = ''
  showPlayer:boolean=false;
  tutorials: any[] = [];
  fields = [
    { key: "name", sortable: true },
    { key: "fileId", sortable: true },
    { key: "description", sortable: true },
    { key: "lastModifiedDate", sortable: true },
    { key: "upload", sortable: false },
    { key: "download", sortable: false },
    { key: "actions", sortable: false },
  ];
  created() {
    this.loadVideos();
  }
  createNew() {
    this.isBusy(true);
    videoTutorialsService
      .createNew()
      .then((result) => {
        this.isBusy(false);
        this.loadVideos();
        // this.tutorials.push(result)
      })
      .catch((err) => this.isBusy(false));
  }
  async deleteTutorial(id) {
   let confirmatin=await this.confirm("Confirmation","Are you sure you want to remove this video");
   if(!confirmatin) return;
    this.isBusy(true);
    videoTutorialsService
      .delete(id)
      .then((result) => {
        this.loadVideos();
      })
      .catch((err) => this.isBusy(false));
  }
  update(id) {
    let item = this.tutorials.find(z => z.id == id);
    if (!item) return;
    this.isBusy(true);
    videoTutorialsService
      .update(id, item.name, item.fileId, item.description)
      .then((result) => {
        this.isBusy(false);
      })
      .catch((err) => this.isBusy(false));
  }

  loadVideos() {
    this.isBusy(true);
    videoTutorialsService
      .getVideoTutorials()
      .then((result) => {
        this.isBusy(false);
        this.tutorials = result;
      })
      .catch((err) => this.isBusy(false));
  }
  uploadFileChanged(id, event) {
    var files = event.target.files;
    if (files.lenght == 0) return;
    if (files.length > 1) return;
    this.uploadFile(id, files[0]);
    // @ts-ignore
    this.$refs.backupFileUpload.value = "";
  }

  async downloadFile(id) {
    var linkdata = await videoTutorialsService.createDownloadLink(id);
    if (!linkdata.link) {
      this.info("No File", "This video doesn't have a file attached")
    }
    else {
      window.open(linkdata.link, '_blank');
    }
  }
  async playFile(id) {
    var linkdata = await videoTutorialsService.createDownloadLink(id);
    if (!linkdata.link) {
      this.info("No File", "This video doesn't have a file attached")
    }
    this.playVideoLink = linkdata.link;
    this.showPlayer=!!this.playVideoLink
  }
  async uploadFile(id, file: File) {
    this.isBusy(true);
    var link = await videoTutorialsService.createUploadLink(id);

    const blobClient = new BlockBlobClient(link);
    blobClient
      .uploadData(file, {
        onProgress: (progress) => {
          var percentCompleted = (
            (progress.loadedBytes / file.size) *
            100
          ).toFixed(0);
          this.isBusy(true, percentCompleted)
        },
      })
      .then(() => {
        this.isBusy(false);
        this.loadVideos()
      });
  }


}
