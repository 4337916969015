
export class ActivityLogProperty {
  id=''
  name= ""
  oldValue= ''
  tag= ""
  type=0
  value=""
   
  fromPayload(property){
    if(!property) return null;
     this.id=property.id
     this.name=property.name
     this.oldValue=property.oldValue
     this.tag=property.tag
     this.type=property.type
     this.value=property.value
   return this;
  }
 
}
