
import { accountService } from "../utils/http/account.service";
import { Component, Prop, Vue } from "vue-property-decorator";
import store from "@/store";
@Component({
  components: {},
})
export default class AccountPicker extends Vue {
  tokenDetails = null;
  isLoggedin = false;
  isLoggingIn = false;
  loginFailed = false;
  noAccess=false;
  errorMessage='';
  async created() {
  
    //@ts-ignore
    if (this.$msal.isAuthenticated) {
      this.isLoggedin = true;
      this.loginFailed = false;
      this.isLoggingIn = false;
      await this.loadUserProperties();
     
    } else {
      this.isLoggingIn = true;
      //@ts-ignore
      this.$msal.listenFormRedirectPromise(async () => {
        this.isLoggingIn = false;
        this.isLoggedin = true;
        //@ts-ignore
        if (this.$msal.isAuthenticated) {
          this.isLoggedin = true;
          this.isLoggingIn = false;
          await this.loadUserProperties();
        } else {
          this.loginFailed = true;
        }
      });
    }
  }
  async redirectToApp() {
    if (window.history.replaceState) {
      window.history.replaceState({}, "TPSAdmin", "/");
      window.location.reload();
    } else {
      window.location.replace(
        document.location.protocol + `//` + document.location.host
      );
    }
  }

  async login() {
    //@ts-ignore
    await this.$msal.signIn();
  }
  logout() {
    //@ts-ignore
    this.$msal.signOut();
  }
  getToken() {
    //@ts-ignore
    this.$msal.acquireToken();
  }
 
 
  async loadUserProperties() {
    //@ts-ignore
    this.tokenDetails = await this.$msal.getUserProperties();
    accountService.getUserDetails(this.tokenDetails.objectId).then(details=>{
      
      accountService.loggedIn(details.profile,details.permisions);
      this.redirectToApp()
    }).catch(err=>{
      if(err.response && err.response.status==401)
      {
        this.noAccess=true
        this.errorMessage="You do not have access to this site"
      }
    })
  }
}
