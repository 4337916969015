
import { Component, Prop, Vue } from "vue-property-decorator";

import { httpService } from "../../../utils/http";
import CustomerBase from "@/common/customer.base";
import Resources from "@/common/resources";

@Component({
  components: {
   
  }
})
export default class FirmStats extends CustomerBase {
  isLoading = false;
  data = null;
  filter: string = null;
  filterOn = [];
  year: number = new Date().getFullYear();
   options=  {
      responsive: true,
      maintainAspectRatio: false
    }

  fields = [
    { key: "urlCode", sortable: true },
    // { key: "firmName", sortable: true },   
    { key: "monthlyChanges.1.hasChanges", label: "January", sortable: true },
    { key: "monthlyChanges.2.hasChanges", label: "February", sortable: true },
    { key: "monthlyChanges.3.hasChanges", label: "March", sortable: true },
    { key: "monthlyChanges.4.hasChanges", label: "April", sortable: true },
    { key: "monthlyChanges.5.hasChanges", label: "May", sortable: true },
    { key: "monthlyChanges.6.hasChanges", label: "June", sortable: true },
    { key: "monthlyChanges.7.hasChanges", label: "July", sortable: true },
    { key: "monthlyChanges.8.hasChanges", label: "August", sortable: true },
    { key: "monthlyChanges.9.hasChanges", label: "September", sortable: true },
    { key: "monthlyChanges.10.hasChanges", label: "October", sortable: true },
    { key: "monthlyChanges.11.hasChanges", label: "November", sortable: true },
    { key: "monthlyChanges.12.hasChanges", label: "December", sortable: true }
  ];
  public created() {
    this.fetchData();
  }
  getScopeName(month) {
    return `cell(monthlyChanges.${month}.hasChanges)`;
  }
  fetchData() {
    this.isLoading = true;
    httpService
      .get(
        Resources.getBaseUrl() + `FirmStats/licensestats/${this.year}`
      )
      .then((result: any[]) => {
        this.isLoading = false;

        this.data = result;
       
        console.log('this.data  ',this.data)
      });
  }



}
