
    import {
    
      Component,
    
      Prop,
    
      Vue
    
    } from "vue-property-decorator";
    
    
    
    
    import {
    
      dataIntegrityService
    
    } from "../../../utils/http";
    import CustomerBase from "@/common/customer.base";

    @Component({
    })
    
    export default class DueDateTasks extends CustomerBase {
        @Prop()
  firmId: String;
    

      public data: any[] = [];
    
      
  
      created() {
  
  
      }
     
      getData() {
  
        this.isBusy(true);
       
        dataIntegrityService.getReusedTaskGroup(this.firmId).then((result) => {
       
          this.isBusy(false);
          
          this.data = result;
          console.log('task',result)
  
  
  
        }).catch((err) => this.isBusy(false))
  
  
  
      }
  
      async fixTasks(){
        var taskGroupIds=this.data.map(z=>z.taskGroupId);
        if(taskGroupIds.length==0){
          return this.info('No tasks were detected','There are not tasks to fix')
        }
        let confirmation= await this.confirm(`Are you sure you want to fix the tasks?` ,`${taskGroupIds.length} task(s) were detected. Use with caution and backup the database first`);
        if(!confirmation) return;
        this.isBusy(true);
       
       dataIntegrityService.fixReusedTaskGroup(this.firmId,taskGroupIds).then((result) => {
      
         this.isBusy(false);
         
        this.getData();
 
 
 
       }).catch((err) => this.isBusy(false))
      }
    }
  