import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
import store from '@/store';
import router from '@/router';
class DataIntegrityService {
 public getDuplicateTimes(firmId:String): Promise<any[]> {
   return httpService.get(Resources.getBaseUrl() + `DataIntegrity/duplicatetimes/${firmId}`);
 }
 public getMasterRecourdsWithTasksCompleted(firmId:String): Promise<any[]> {
  return httpService.get(Resources.getBaseUrl() + `DueDatesFix/originalrecordswithtaskscompeted/${firmId}`);
}
public getNonRecurringDueDatesWithChildren(firmId:String): Promise<any[]> {
  return httpService.get(Resources.getBaseUrl() + `DueDatesFix/nonrecurringwithchildren/${firmId}`);
}
public fixNonRecurringDueDatesWithChildren(firmId:String): Promise<any[]> {
  return httpService.post(Resources.getBaseUrl() + `DueDatesFix/nonrecurringwithchildren/${firmId}`,{});
}
public getReusedTaskGroup(firmId:String): Promise<any[]> {
  return httpService.get(Resources.getBaseUrl() + `DueDatesFix/taskwithwronggroupid/${firmId}`);
}
public fixReusedTaskGroup(firmId:String, taskGroups:string[]): Promise<any[]> {
  return httpService.post(Resources.getBaseUrl() + `DueDatesFix/taskwithwronggroupid/${firmId}`,taskGroups);
}
public removeCompletionFromTaskGroups(firmId:String,  taskGroups:string[]): Promise<any[]> {
  return httpService.post(Resources.getBaseUrl() + `DueDatesFix/originalrecordswithtaskscompeted/${firmId}/fix`,taskGroups);
}
public getDuplicateEmployeeDueDates(firmId:String): Promise<any[]> {
  return httpService.get(Resources.getBaseUrl() + `DueDatesFix/duplicateemployeerecords/${firmId}`);
}
public fixDuplicateEmployeeDueDates(firmId:String): Promise<any[]> {
  return httpService.post(Resources.getBaseUrl() + `DueDatesFix/duplicateemployeerecords/${firmId}/fix`,{});
}


}
export let dataIntegrityService = new DataIntegrityService();
