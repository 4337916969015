
import { Component, Prop, Vue } from "vue-property-decorator";
import {httpService} from '../../utils/http/http.service'
import Resources from '@/common/resources';
import ComponentBase from "../../common/component.base";
@Component({
components: {
 
},
})
export default class LegacyUseage extends ComponentBase {
  data: any[] = [];
  async created() {
   this.getData();
  }
 
   getData(){
    httpService.get<any[]>(Resources.getBaseUrl() + `legacyuseage`).then(result=>{
      result.forEach(f=>{
        f.showDetails=false;
        f.useageByEmployee.forEach(e=>e.showDates=false)
      });
      this.data=result;
    })
   }
}
