
import { Component, Prop, Vue } from "vue-property-decorator";
import ReleaseNoteTags from './ReleaseNoteTags.vue'
import {
  releaseNotesService
} from "../../utils/http";
import ActionButton from "../common/ActionButton.vue";
import FormError from "../common/FormError.vue";
import CustomerBase from "@/common/customer.base";

import store from "@/store";
import Summernote from "../common/Summernote.vue";
@Component({
  components: {
    ReleaseNoteTags,
    ActionButton,
    FormError,
    Summernote
  }
})
export default class ReleaseNote extends CustomerBase {
    @Prop({required:true})
    id: any;

    releaseNote: any=null;
 
emitChanges(){
  this.$emit('change',this.releaseNote)
}
async loadReleaseNoteContent(id:string){
  
       this.isBusy(true)
      let data = await releaseNotesService.getNoteContent(id);
      this.isBusy(false)
      this.releaseNote.notes=data.content||''
  
}
  async created() {
    await this.initialize();
  }


  async initialize() {
    try {
      this.isBusy(true);
      let releaseNote = await releaseNotesService.getSingle(this.id);
      this.releaseNote=this.mapTag(releaseNote);
      this.loadReleaseNoteContent(this.id)
      this.isBusy(false);
    } catch (err) {
      this.isBusy(false);
    }
  }
  mapTag(tag){
    
       tag.editing = false;
       tag.notes=''
        tag.date = this.formatDate(tag.date);
        tag.tags= tag.assignedTags && tag.assignedTags.map(z=>z.id)
    return tag;
  }
  async saveContent(id:string){
   this.isBusy(true);
   try{
     
     await releaseNotesService.saveNoteContent(id,this.releaseNote.notes)
   
      this.isBusy(false);
   }
   catch{
     this.isBusy(false);
   }
  }
  async save(id: string) {
   
    try {
      this.isBusy(true);
      
      let result   =this.mapTag(await releaseNotesService.update(id, this.releaseNote));
      result.notes=this.releaseNote.notes
      this.emitChanges();
      this.isBusy(false);
    } catch (err) {
      this.isBusy(false);
    }
  }
}
