
  import { Component, Prop, Vue } from "vue-property-decorator";

 import { backgroundEventsService } from "../../utils/http";
  import CustomerBase from "@/common/customer.base";
  import VueJsonPretty from "vue-json-pretty";

  
  
  @Component({
    components: {
        VueJsonPretty
    }
  })
  export default class BackgroundEventsDetails extends CustomerBase {
    @Prop({default:()=> []})
    events: any[];
 
    requeue(){
      let data= this.events.map(e=>({
       eventType:'Webhooks.Sendgrid.Status',
       subject:'Webhooks.Sendgrid.Status',
       data:e

      }))
    
      backgroundEventsService.requeue(data).then(res=>{
        this.$emit('requeued')
      })
    }
    generateSqlQuery(){
        var applicableSuccessStatuses=['delivered','open','click']
        let events= this.events.filter(z=>applicableSuccessStatuses.includes(z.eventType));
        let clientEmailIds= events.map(z=>`'${z.emailId}'`);
        let emails= events.map(z=>`'${z.email}'`);
        var clientEmailIdsQuery= `(${clientEmailIds.join(',')})`
        var emailsQuery= `(${emails.join(',')})`
        // let query = `select * from clientemailmailboxes where ClientEmailId in ${clientEmailIdsQuery} and Email in ${emailsQuery}`
        let updateQuery = `update  clientemailmailboxes set status=4 where ClientEmailId in ${clientEmailIdsQuery} and Email in ${emailsQuery}`

        // console.log(query);
        console.log(updateQuery);
        this.copyToClipboard(updateQuery);
      }
       copyToClipboard(textToCopy) {
    // Use the Clipboard API
    navigator.clipboard.writeText(textToCopy)
        .then(() => {
            console.log("Text copied to clipboard successfully!");
        })
        .catch(err => {
            console.error("Failed to copy text: ", err);
        });
}

  }
  