
import { Component, Vue, Prop, Watch} from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class ActionButton extends Vue {
  @Prop({default:()=> []})
  errors:string[];

}
