import {httpService, httpServiceWithoutInterceptor} from '@/utils/http';
import Resources from '@/common/resources';
import store from '@/store';
import router from '@/router';
// tslint:disable-next-line:max-line-length
import {LoginModel, AccountInvitationRequest, SignupModel, TokenResultModel,UserViewModel, ResetPasswordModel, ResetPasswordRequestModel} from '@/common/models';
class AccountService {
 public getUserPermissions(id:String): Promise<String[]> {
   return httpServiceWithoutInterceptor.get(Resources.getBaseUrl() + "account/permissions/" + id);
 }
 public getUserDetails(id:String): Promise<any> {
  return httpServiceWithoutInterceptor.get(Resources.getBaseUrl() + "account/me/" + id);
}
 
 public saveToken(token: UserViewModel): void {
   localStorage.setItem('token', JSON.stringify(token));
  }
  public loggedIn(profile:any,rights:String[]): void {
  
     // @ts-ignore
    store.commit('changeUser', profile);
    store.commit('rights', (rights||[]).map(r=>r.toString()) );
  }
  public loggedOut(redirect: boolean= false): void {
   store.commit('changeUser', {} );
   store.commit('rights', [] );
  
  }

}
export let accountService = new AccountService();
