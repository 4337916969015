import * as signalR from "@aspnet/signalr";
import Resources from '@/common/resources';
import store from '@/store';
class NotificationService {
    hubConnection = null;
    
   async initializeSignalRConnection() {
        let signalRServerEndPoint = `${Resources.getBaseUrl()}notifications`;
        // this.connection = $.hubConnection(signalRServerEndPoint, { qs: `user=${store.getters.user.userId}&firm=${this.getFirm()}` });

        this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(signalRServerEndPoint)
        .build();
        await this.hubConnection.start();
    }
 
    onJobInfo(callback){
        this.hubConnection.on("adminjobinfo",callback);

    }
   

}

export let notificationService = new NotificationService()
