import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
// tslint:disable-next-line:max-line-length
class ClientJobsService {
 public getJobs(firmId:string): Promise<any[]> {
   return httpService.get(Resources.getBaseUrl() + `clientjobs/${firmId}`);
 }
 public deleteJob(firmId:string,jobId:string): Promise<any[]> {
  return httpService.delete(Resources.getBaseUrl() + `clientjobs/${firmId}/${jobId}`,{});
}
 public getJobLogs(firmId:string,jobId:string): Promise<any[]> {
  return httpService.get(Resources.getBaseUrl() + `clientjobs/${firmId}/${jobId}/logs`);
}
}
export let clientJobsService = new ClientJobsService();
