
import { Component, Prop, Vue } from "vue-property-decorator";

import { clientContactService  } from "../../../utils/http/clientcontact.service";
import CustomerBase from "@/common/customer.base";


@Component({
  components: {
  }
})
export default class ClientContacts extends CustomerBase {
  @Prop()
  firmId: string;
  contactTypes: any[] = [];
  contacts: any[] = [];
  fromType: string = "";
  toType: string = "";
  selectedContactType: string = "";
    fields = [
    { key: "clientCode", sortable: true,label:'C.Code' },
    { key: "clientName", sortable: true,label:'C.Name' },
    { key: "name", sortable: true,label:'Contact' },
    { key: "contactType", sortable: true,label:'Type' },
    
  ];
  constructor() {
    super();
  }
  
  created() {
    this.loadContactTypes();  
    this.loadContacts();
   }
   loadContacts(){
    clientContactService.getContacts(this.firmId, this.selectedContactType).then(res => {
      this.contacts = res;
    });
   }
   async updateType(){
   let confirmation= await this.confirm("Confirm","Are you sure you want to update contact type? This operation cannot be undone");
    if(!confirmation) return;
    clientContactService.updateContactType(this.firmId, this.fromType, this.toType).then(res => {
        this.info("Success","Contact type updated successfully");
      this.loadContacts();
    });
   }
  loadContactTypes() {
    this.isBusy(true);
    clientContactService
      .getContactTypes(this.firmId)
      .then(result => {
        this.isBusy(false);
        this.contactTypes=[]
               this.contactTypes.push({id:'',name:'No Contact Type'});

        this.contactTypes.push(...result);
      })
      .catch(err => this.isBusy(false));
  }
}
