
import { Component, Prop, Vue } from "vue-property-decorator";
import TagAddEdit from "./TagAddEdit.vue";
import { releaseNotesService } from "../../utils/http";
import CustomerBase from "@/common/customer.base";
@Component({
  components: {
    TagAddEdit,
  },
})
export default class ReleaseNoteTags extends CustomerBase {
  @Prop({ required: false, default: () => [] })
  value: [];
  tags: any[] = [];
  selectedTagIds = [];
  async created() {
    await this.initialize();
    this.selectedTagIds = this.value;
  }
  get selectedTags() {
    return this.tags.filter((z) => this.selectedTagIds.includes(z.id));
  }
  removeTag(id) {
    console.log("remoe tag");
    let index = this.selectedTagIds.indexOf(id);
    if (index >= 0) {
      this.selectedTagIds.splice(index, 1);
    }
  }
  includeTag(id) {
   
    if (this.selectedTagIds.includes(id)) return;
    this.selectedTagIds.push(id);
     this.$emit('input',this.selectedTagIds)
     this.$emit('change',this.selectedTagIds)
  }
  addNew() {
    this.tags.push({
      name: "new tag",
      color: "#FF5733",
    });
  }
  tagAddedOrRemoved(){
    this.initialize();
    this.$emit('input',this.selectedTagIds)
     this.$emit('change',this.selectedTagIds)
  }
  async initialize() {
    try {
      this.isBusy(true);
      this.tags = await releaseNotesService.getTags();
      this.isBusy(false);
    } catch (err) {
      this.isBusy(false);
    }
  }
}
