
import { Component, Prop, Vue } from "vue-property-decorator";

import CustomerBase from '@/common/customer.base'
import reportDataUtils from '../ReportDataUtils'
@Component({
  components: {
    
  }
})
export default class ReportChartView extends CustomerBase {
  @Prop()
  data: any[];
  @Prop()
  range: any;
  series: any[] = null;
  chartOptions:any=null;
  created() {
   this.chartOptions=reportDataUtils.crateReportOptions('All Firms Runs',this.range.start,this.range.end)
    this.series = this.prepareSeries(this.data)
  }
  

  prepareSeries(data) {
    return [{
      name: 'All Firms',
      data: reportDataUtils.getDailyData(data,this.range.start,this.range.end).map(z => ({ x: z.date, y: z.items.length }))
    }]
  }
 

}
