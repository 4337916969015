
import { Component, Prop, Vue } from "vue-property-decorator";

import { jasperService } from "../../utils/http";
import CustomerBase from '@/common/customer.base'
import AllFirmWithAllReports from './views/AllFirmWithAllReports.vue'
import ByFirm from './views/ByFirm.vue'
import ByReport from './views/ByReport.vue'
import DateRangePicker from 'vue2-daterange-picker'
import dateTimeUtility from "@/utils/datetime.utils";
import Grid from './views/Grid.vue'
@Component({
  components: {
    DateRangePicker,
    Grid,
    AllFirmWithAllReports,
    ByFirm,
    ByReport
  }
})
export default class JasperReportRuns extends CustomerBase {
  analytics: { filter: any, data: any[] } = null;
  dateRange: { startDate:Date, endDate:Date }={startDate:new Date(dateTimeUtility.monthStart()),endDate:new Date(dateTimeUtility.monthEnd())}
 
  created() {
   this.loadData();
  }
  datesUpdated(dateRange){
    this.loadData();
  }
  loadData(){
    this.isBusy(true)
    this.analytics=null;
    // console.log('series',this.generateDayWiseTimeSeries(0,18))
    jasperService.getAnalytics(dateTimeUtility.toDate(this.dateRange.startDate),dateTimeUtility.toDate(this.dateRange.endDate)).then(result => {
      this.isBusy(false)
      this.analytics=result;

  
    }).catch(err => this.isBusy(false))
  }

}
