import {httpService} from '@/utils/http';
import queryStringUtils from '../../utils/httpquery.service'

import Resources from '@/common/resources';
export default  {
    getLogEntries(firmId,filter){
        let query= filter? queryStringUtils.toQueryString(filter):''
        console.log('query',query)
        return httpService.get(`${Resources.getBaseUrl()}activitylog/logentries/${firmId}?${query}`)
      },
      getLogEntry(firmId,id){
      
        return httpService.get(`${Resources.getBaseUrl()}activitylog/logentries/${firmId}/${id}`)
      },
      getEntityTypes(firmId){
        return httpService.get(`${Resources.getBaseUrl()}activitylog/entitytypes/${firmId}`)
      },
      getUsers(firmId){
        return httpService.get(`${Resources.getBaseUrl()}activitylog/users/${firmId}`)
      },
      getPropertiesByType(firmId,typeId){
        return httpService.get(`${Resources.getBaseUrl()}activitylog/properties/names/${firmId}/${typeId}`)
      }
}
