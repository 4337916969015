
import { clientJobsService } from '../../../utils/http'

import {

    Component,

    Prop,

    Vue

} from "vue-property-decorator";



import CustomerBase from '@/common/customer.base'

@Component(
    {
        components: {
            
        }
    }
)
export default class JobLogs extends CustomerBase {
    @Prop()
    firmId: string;
    @Prop()
    jobId: string;

    
     isLoading:boolean=false;

     logs: any[] = [];



    created() {


        this.getLogs();

    }
    getLogs() {
        
this.isLoading=true;
        clientJobsService.getJobLogs(this.firmId, this.jobId).then((result) => {
            this.isLoading=false;
          this.logs=result;
          console.log(this.logs)
          


        }).catch((err) => this.isLoading=false)
    }
   

}
