
    import {
    
      Component,
    
      Prop,
    
      Vue
    
    } from "vue-property-decorator";
    
    
    
    
    import {
    
      dataIntegrityService
    
    } from "../../../utils/http";
    import CustomerBase from "@/common/customer.base";

    @Component({
    })
    
    export default class NonRecurringDueDatesWithChildren extends CustomerBase {
        @Prop()
  firmId: String;
    

      public data: any[] = [];
    
      
  
      created() {
  
  
      }
     
      getData() {
  
        this.isBusy(true);
       
        dataIntegrityService.getNonRecurringDueDatesWithChildren(this.firmId).then((result) => {
       
          this.isBusy(false);
          
          this.data = result;
          console.log('duedates',result)
  
  
  
        }).catch((err) => this.isBusy(false))
  
  
  
      }
  
      async fixDueDates(){
      
        let confirmation= await this.confirm(`Are you sure you want to run this fix?` ,` Use with caution and backup the database first`);
        if(!confirmation) return;
        this.isBusy(true);
       
       dataIntegrityService.fixNonRecurringDueDatesWithChildren(this.firmId).then((result) => {
      
         this.isBusy(false);
         
        this.getData();
 
 
 
       }).catch((err) => this.isBusy(false))
      }
    }
  