
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import componentBase from "../../common/component.base";
import { clientPortalService } from "../../utils/http";
@Component({
  components: {},
})
export default class extends componentBase {
   characters :string='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  public fileSize:number=1000;
  public clients: any[] = [];
  @Prop()
  firmId: String;
  async created() {
    this.fetchData();
  }

  async fetchData() {
    this.clients = await clientPortalService.getClients(this.firmId);
  }
  generate(generateData = false) {
    //@ts-ignore
    var zip = new JSZip();

    this.clients.forEach((client) => {
      var clientFolder = zip.folder(client.clientCode);
      if (generateData) {
        for (let i = 1; i <= 10; i++) {
          clientFolder.file(`Sample File - ${i}.txt`, this.generateString(this.fileSize));
        }
      }
    });

    zip.generateAsync({ type: "blob" }).then((content) => {
      this.saveBlob(content, "data.zip");
    });
  }

  saveBlob(blob, fileName) {
    var a = document.createElement("a");
    document.body.appendChild(a);
    //@ts-ignore
    a.style = "display: none";
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }
 getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
 generateString(length) {
    let result = ' ';
    const charactersLength = this.characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += this.characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}
}
