
    import {
    
      Component,
    
      Prop,
    
      Vue
    
    } from "vue-property-decorator";
    
   
    
    import {
    
        firmDataService
    
    } from "../../utils/http";
    
    import CustomerBase from '@/common/customer.base'
     
    
    @Component({
        components:{
        }
    })
    export default class AdministratorEmails extends CustomerBase {
      
    
      fields = [
        {key:'urlCode',label:'Firm',  sortable: true },
        {key:'administratorName', sortable: true },
        {key:'administratorEmail', sortable: true },
        {key:'role', sortable: true },
        {key:'subscription', sortable: true },
        {key:'subscriptionStatus', sortable: true },
    ]
       
    
      filter = null;
    
    
      public customers: any[] = [];
    
      
  
      created() {
  
  
        this.getData();
  
      }
     
      getData() {
  
        this.isBusy(true);
  
        firmDataService.getCustomers().then((result) => {
  
          this.isBusy(false);
         
            const data= result.filter(z=>z.license!=null && !z.isNonBillable && !z.isDemo).flatMap((customer)=>{
            let administrators= 
            
            customer.legacyAdministrators.map(a=>({...a,...{role:'Legacy Admin'}})).concat(customer.newAdministrators.map(a=>({...a,...{role:'Active Directory Admin'}}))).concat(customer.employees.map(a=>({...a,...{role:'Employee'}})));
            return administrators.map(a=>{
                return {
                id:customer.id,
                urlCode:customer.urlCode,
                subscription:customer.license?.subscription,
                subscriptionStatus:customer.license?.status,
                statusId:customer.license?.statusId,
                administratorName:a.name,
                administratorEmail:a.email,
                role:a.role
                }
            })
             
           })
           console.log('data',data)
          this.customers = data;
  
  
  
        }).catch((err) => this.isBusy(false))
  
  
  
      }
      exportToExcel(){
        this.exportTableToExcel("emails-table")
      }
  
    }
  