
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import componentBase from "../../common/component.base";
import { powerBIService } from "../../utils/http/powerbi.service";
import { httpService } from "../../utils/http";

@Component({
  components: {
  
  },
})
export default class extends componentBase {
  files: any[] = [];
  isLoading=false;
  fields = [
    { key: "name", sortable: true, label: "Name" },
    { key: "lastModified", sortable: true, label: "LastModified" },
    { key: "length", sortable: true, label: "Length" },
    { key: "update", sortable: false, label: "" }

  ];

  created() {
    this.getData();
  }
  getData() {
    this.isBusy(true);
    powerBIService
      .getFiles()
      .then((result) => {
        this.isBusy(false);

        this.files = result;
      })
      .catch((err) => this.isBusy(false));
  }
  updateFile(name:string){
    let file=this.files.find(z=>z.name==name);
    if(!file || !file.newFile) return;
    console.log('new file',file.newFile);
    this.isLoading=true
    powerBIService.updatePowerBiFile(file.name,file.newFile).then(res=>{
      this.isLoading=false;
      this.getData();

    }).catch(err=>{
      this.isLoading=false;
    });
  }

}
