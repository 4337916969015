<template>
<b-card >
  <b-card-sub-title>
    Filter <b-button @click="clearFilter" variant="link" size="sm">X</b-button>
  </b-card-sub-title>
   <b-form @submit="$event.preventDefault()" inline>
   <b-form-input   :class="{'input-has-value':from}" @change="filterChanged" v-model="from" id="inline-form-input-from" type="date"></b-form-input>
   <b-badge class="ml-1 mr-1" variant="light">
     <i class="fa fa-arrows-alt-h"></i>
   </b-badge>
   <b-form-input :class="{'input-has-value':to}" @change="filterChanged" v-model="to" id="inline-form-input-to" type="date"></b-form-input>
    
    <!-- <search-dropdown class="ml-1"   @change="filterChanged" :class="{'input-has-value':entityType}"
          v-model="entityType" placeholder="Entity"
          style="width:150px"
          :api="entitysTypeAPI"
          :debounce="500"
        ></search-dropdown > -->
          <v-select
                          :options="entityTypes"
                          v-model="entityType"
                          label="value"
                          @change="filterChanged()"
                          value="id"
                        ></v-select>
     <b-form-select class="ml-1"  :class="{'input-has-value':actionType}" @change="filterChanged"  size="sm" v-model="actionType" >
      <b-form-select-option :value="null">Action Type</b-form-select-option>
      <b-form-select-option :key="action.value" v-for="action in actions" :value="action.value">{{action.name}}</b-form-select-option>
      
    </b-form-select>
     <v-select
                          :options="users"
                          v-model="user"
                          label="value"
                          @change="filterChanged"
                          value="id"
                        ></v-select>
     <!-- <search-dropdown class="ml-1"   @change="filterChanged" :class="{'input-has-value':user}"
          v-model="user" placeholder="User"
          style="width:150px"
          :api="usersAPI"
          :debounce="500"
        ></search-dropdown >
         <search-dropdown  class="ml-1"  v-if="entityType && !hidePropertyFilter"  @change="filterChanged"
         :class="{'input-has-value':property}"
          v-model="property"
         
            style="width:150px"
          placeholder="Property"
           :api="propertiesAPI"
          :debounce="500"
        ></search-dropdown > -->
          <v-select v-if="entityType && !hidePropertyFilter"  
                          :options="properties"
                          v-model="property"
                          label="value"
                          @change="filterChanged"
                          value="id"
                        ></v-select>
 </b-form>
</b-card>
</template>
<script>
import queryStringUtils from '../../../utils/httpquery.service'
import {debounce} from '../../../utils/debounce'
import activityLogService from "../../../utils/http/activitylog.service";

export default {
  name:'ActivityEntry',
  components: {
  //  searchDropdown
  },
  props: {
   firmId:{
     type:String,
     required:true
   },
   hidePropertyFilter:{
     type:Boolean,
     default:false
   }
  },
  data() {
    return {
       from:null,
       to:null,
       entityType:null,
       actionType:null,
       property:null,
       properties:[],
       user:null,
       entityTypes:[],
       users:[],
       entities:[
         {
           name:'Client',
           value:1
         },
         {
           name:'Employee',
           value:2
         },
         {
           name:'Office',
           value:3
         },
         {
           name:'Firm',
           value:4
         },
         {
           name:'Address',
           value:5
         },
         {
           name:'Email',
           value:6
         },
         {
           name:'Time',
           value:7
         },
         {
           name:'Invoice',
           value:8
         },
         {
           name:'Payment',
           value:9
         },
         {
           name:'ClientGroup',
           value:10
         },
       ],
        actions:[
         {
           name:'Created',
           value:1
         },
         {
           name:'Updated',
           value:2
         },
         {
           name:'Deleted',
           value:3
         }
       ]
    };
  },

  created() {
   this.filterChanged= debounce.debounce(this.filterChanged,500);
   activityLogService.getEntityTypes(this.firmId).then(types=>{
   this.entityTypes=types
   })
    activityLogService.getUsers(this.firmId).then(users=>{
   this.users=users
   })
  },
  computed:{
   
  },
  methods: {
    
    clearFilter(){
      this.from=null;
      this.to=null;
      this.entityType=null;
      this.actionType=null;
      this.property=null;
      this.user=null;
      this.filterChanged();

    },
    filterChanged(){
      let filterObject={
        from:this.from,
        to:this.to,
        entityType:(this.entityType && this.entityType.id) || "",
        actionType:this.actionType,
        pName:this.property && this.property.id,
        userId:this.user && this.user.id
      };
      let queryString=queryStringUtils.toQueryString(filterObject);
      this.$emit('filter',filterObject,queryString);
    }
  },
};
</script>