<template>
   <b-list-group>
        <b-list-group-item
          :key="property.name"
          v-for="property in properties"
        >
        <b-card v-if="property.Type==types.LogProperties" >
            <b-card-sub-title class="mr-2">{{ property.Name }}</b-card-sub-title>
             <SinglePropertyVisualizer v-for="subProperty in property.Value " :property="subProperty"/>
         
        
        </b-card>
        <SinglePropertyVisualizer v-else :property="property"/>
        
        </b-list-group-item>
      </b-list-group>
  
</template>
<script>
import {ManagerActivityLogPropertyType} from '../../../common/enums'
import SinglePropertyVisualizer from './SinglePropertyVisualizer.vue'
export default {
  components:{
    SinglePropertyVisualizer
  },
  data() {
    return {
      types:ManagerActivityLogPropertyType,
      properties: [],
    };
  },
  props: {
    
    changes: {
      type: String,
      default: '',
    },
  },
  watch:{
    changes(){
      this.parseProperties();
    }
  },
  created() {
    this.parseProperties();
  },
  methods: {
    parseProperties() {
      if(!this.changes) return ;
      let data= JSON.parse(this.changes);
      console.log('data')
      this.properties=data;
    },
  },
};
</script>