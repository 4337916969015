
import ComponentBase from "@/common/component.base";
import {JobType} from "@/common/enums";
import { Component, Vue } from 'vue-property-decorator';
import {notificationService} from './utils/notification/notification.service'
import { JobViewModel } from './common/models';
@Component({})
export default class App extends ComponentBase {
constructor() {
   super();  
}
async created () {
   await notificationService.initializeSignalRConnection()
   notificationService.onJobInfo((data:JobViewModel)=>{
    switch(data.type){
      case JobType.Backup :
         console.log('type backup',data)
        //@ts-ignore
       this.$eventHub.emit(this.EventNames.BackupsChanged,data);
        break;
       default :
        break;
    }
    //@ts-ignore
       this.$eventHub.emit(this.EventNames.JobsChanged,data);
   })
 }
}
