/* tslint:disable:max-classes-per-file*/

export class LoginModel {
   public userName!: string;
   public password!: string;
}
export class ResetPasswordModel {
    public newPassword?: string;
    public token?: string;
 }

export class ResetPasswordRequestModel {
    public email!: string;
    public resetPasswordUrl!: string;
 }
export class TokenResultModel {
    public token: string = '';
    public refreshToken: string = '';
 }
export class UserViewModel {
    public id: string = '';
    public token: string = '';
    public email: string = '';
 }
export class AccountInvitationRequest {
     public  email: string = '';
     public  redirectUrl: string = '';
 }

export class SignupModel {

     public  firstName: string = '';
     public  lastName: string = '';
     public  password: string = '';
     public  phoneNumber: string = '';
     public  firmName: string = '';
     public  firmUrl: string = '';
     public  firmSize: number = 1;
     public  firmOccupation: string = '';
     public region: number = 0;
     public token: string = '';
     public featureId: number;
     public numberofEmployees: number = 1;
     public maxEmployees: number = 1;
 }

export enum CustomerRegion{
    US =1,
    Canada=2
}
export enum DBDeletionStatus{
    Active=0,
 MarkedForDelete=1,
 IsDeleting=2,
 Deleted=3
}
export class CustomerViewModel {
    seats:number=0;
        checked: boolean;
        id: string;
        label: string;
        dbName: string;
        dbVersion: string;
        status: DatabaseStatus;
        statusSetDate: Date;
        public lastActivity:Date;  
        runningJobs:number;
        deleted:boolean;
        dbDeletionStatus:Number;
        dbDeletionStatusName:String;
        replicationStatusName:String;
        jasperEndpointStatusName:String;
        hasActiveSubscription:Boolean;
        isPaid:Boolean;
        isDemo:Boolean;
        subscription:String;
        customFeatures:String;
        hasNewWorkflow:Boolean;
        get isDeletedOrBeingDeleted(){
            return this.dbDeletionStatus==DBDeletionStatus.Deleted ||   this.dbDeletionStatus==DBDeletionStatus.IsDeleting
        }
       
    }
export class Backup {
        public  id :string;
    public  firmId :string
    public  date :Date;
    public  description : string
    public isConversion : boolean
    } 

    export class Template {
        public  id :string;
    public  name :string
    public  lastModifiedDate :Date;
    public  description : string
    public checked : boolean
    } 
export class JasperCustomerViewModel {
        public id: string;
        public label: string;
        public username: string;
        public password: string;
    }
export class JobDetailsViewModel {
        public id: string;
        public jobType: JobType;
        public jobStatus: JobStatus;
        public status: JobStatus;
        public output: string;
        public customerId: string;
        public  outputLoaded: boolean;
    }
export class ResteJasperCredentialsResult {
        public id: string;
        public username: string;
        public password: string;
    }
export enum JobStatus {
        Queued = 1,
        Running = 2,
        Failed = 3,
        Succeeded = 4,
    }
export enum LicenseStatus
    {
        Expired = -3,
        Canceled = -2,
        SuspendedInactive = -1,
        Active = 1,
        SuspendedActive = 2
    }
export enum DatabaseStatus {
        Ok = 1,
        NotCreated = 2,
        Creating = 3,
        Created = 4,
        CreationFailed = 5,
        UpdateFailed = 6,
        Deleted = 7,
    }
export enum JobType {
        Update = 1,
        Delete = 2,
        Create = 3,
        Backup = 4,
        Restore=5,
        PostRestoreCleanup=7
    }
export class TreeItem<T> {
         public item: T;
         public children: Array<TreeItem<T>>;
    }
export class Reports {

        public id: string;
        public name: string;
        public reportId: string;
        public parentId: string;
        public rightId: string;
        public isOpen: boolean;
    }


export class FinancialReportViewModel {
    public constructor(input:FinancialReportViewModel=null){
        if(!input) return;
        this.nonBillable=input.nonBillable;
        this.firmName=input.firmName;
        this.firmId=input.firmId;
        this.tax=input.tax;
        this.billingDate=input.billingDate;
        this.expireDate= input.expireDate;
        this.currentSubscription= input.currentSubscription;
        this.employeeLicenses=input.employeeLicenses;
        this.firmLicenses= input.firmLicenses;
        this.isCardExpired=input.isCardExpired;

    }
      public nonBillable: boolean;
        public firmName: string;
        public isCardExpired:boolean;
        public firmId: string;
         public tax: string;
         public billingDate:string;
         public expireDate:string;
          public currentSubscription: string;
         public employeeLicenses : FinancialReportViewModelLicense[]  ;
         public firmLicenses : FinancialReportViewModelLicense[]  ;
    }
    export class ElavonSubscriptionViewModel {
        public billingCycle: boolean;
            public amount: number;
            public cardNumber: string;
             public recurringId: string;
        }
export class FinancialReportViewModelLicense {


        public activatedOn: Date;
        public statusSetDate: Date;
        public status: LicenseStatus;
        public statusText: string;
        public createdOn: Date;
        public employee: string;

}
export class ClientsViewModel
{
    public  id : string
    public  firm : string
    public  email : string
    public  phone : string
    public  createdDate : Date
    public  status : string
    public  statusSetDate : Date
    public  subscription: string
    public  lastActivity : Date
    public  subscriptionStatus : string
}
export class JobViewModel{
    public  id :string
    public type :number
    public  status :number
    public customerId :string
    public userId :string
    public isError :boolean
    public startTime :Date
    public stopTime :Date
}