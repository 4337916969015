
  import { Component, Prop, Vue } from "vue-property-decorator";
  import ComponentBase from "../../common/component.base";
import IntegrationList from './IntegrationList.vue'
import IntegrationStats from './IntegrationStats.vue'
  
  @Component({
  components: {
    IntegrationList,
    IntegrationStats
  },
})
  export default class Integrations extends ComponentBase {
   
   
    
    created() {
   
    
      
    }
  }
  