

import Vue from 'vue'
import moment from 'moment'

Vue.filter('localdatetime', function (dateTime) {
  if (!dateTime) {
    return 'Invalid Time'
  }
  return moment.utc(dateTime).local().format('YYYY-MM-DD HH:mm:ss')
})

