
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import componentBase from "../../common/component.base";
import { dbService } from "../../utils/http/db.service";
import { notificationService } from "../../utils/notification/notification.service";
import { JobType, JobStatus } from "../../common/enums";
import VueJsonPretty from "vue-json-pretty";

@Component({
  components: {},
})
export default class extends componentBase {
  @Prop()
  firmId: string;
  billingDetails:any=null;
  async created() {
    this.loadDetails();
  }
reactivateLicense(){
 this.confirm(
      "Just making sure",
      "This action will reactivate the license for the firm if the license is not active. This won't fix the clients license permanently it will only set it to active. Next time the license is revalidated it will go back to inactive if the firm doesn't have valid payment details. If the firm doesn't have payment details at all then this firm will remain forever active."
    ).then(result => {
      if (result) {
        this.isBusy(true);
        dbService.reactivateLicense(this.firmId).then(result => {
          console.log('License==> ', result)
          this.isBusy(false);
           this.loadDetails();
        });
      }
    });
}
  deactivateLicense(){
    this.confirm(
      "Just making sure",
      "This action will remove the license for the firm. This will not remove the license from elavon. The recurring subscription on elavon needs to be removed manually. After this action is complete make sure to Syncronize the admin tool with the manager to see the changes"
    ).then(result => {
      if (result) {
        this.isBusy(true);
        dbService.deactivateLicense(this.firmId).then(result => {
          console.log('License==> ', result)
          this.isBusy(false);
          this.loadDetails();
        });
      }
    });
  }
  loadDetails() {
      this.isBusy(true);
    dbService
        .billingDetails(this.firmId)
        .then(result => {
          this.isBusy(false);
          this.billingDetails=result;
        })
        .catch(err => this.isBusy(false));
  
  }
}
