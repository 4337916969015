import Resources from '@/common/resources';
import {httpService, httpServiceWithoutInterceptor} from '@/utils/http';
import store from '@/store';
import router from '@/router';
// tslint:disable-next-line:max-line-length
class BackgroundTasksService {
  getActive (): Promise<any[]> {
    
    return httpService.get<any[]>(Resources.getBaseUrl() + "jobs?isDismissed=false&maxAge=5")
  }
  dissmiss (jobId): Promise<any> {
    return httpService.delete(`${Resources.getBaseUrl()}jobs/dismiss/${jobId}`,{})
  }
}
export let backgroundTasksService = new BackgroundTasksService();
