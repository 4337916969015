
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import componentBase from "../../common/component.base";
import { clientPortalService } from "../../utils/http";
import { BlockBlobClient } from "@azure/storage-blob";
import {debounce} from '../../utils/debounce'
@Component({
  components: {},
})
export default class extends componentBase {
  public currentPage: number = 0;
  public completedCurrentPage: number=0;
  public perPage: number = 50;
  public clients: any[] = [];
  public uploadInitiated: boolean = false;
  public files: any[] = [];
  public filesWithErrors: any[] = [];
  public completedFiles: any[] = [];
  public totalSize:number=0;
  public fields: any[] = [
   {
      label: "Status",
      key: "status",
      sortable: true,
    },
    {
      label: "File",
      key: "fileName",
      sortable: true,
    },
    {
      label: "Folder",
      key: "folderPath",
      sortable: true,
    },
    {
      label: "C.Code/F.Name",
      key: "determinedClientCodeFromPath",
      sortable: true,
    },
    {
      label: "Client",
      key: "clientName",
      sortable: true,
    },
    
  ];
  @Prop()
  firmId: String;

  constructor(){
    super()
    this.moveProcessedFiles=debounce.throttle(this.moveProcessedFiles,5000)
  }
  async created() {
    this.getClients();
  }

  

  get canUpload() {
    return !this.uploadInitiated
   
  }
  
  reset() {
    this.uploadInitiated = false;
    this.filesWithErrors=[];
    this.completedFiles=[]
    this.files = [];
    //@ts-ignore
    this.$refs.folderPicker.value = null;
  }
  async upload() {
    this.uploadInitiated = true;
    let filesWithoutErrors = this.files.filter((z) => !z.error);
    let fileChunks = this.chunkArray(filesWithoutErrors, 30);
    for (let i = 0; i < fileChunks.length; i++) {
      let actions = fileChunks[i].map(this.uploadFile);
      console.log("actions", actions);
      await Promise.allSettled(actions);
    }
  }
  uploadFile(fileDetails: any) {
    return new Promise(async (resolve, reject) => {
      try {
        var uploadData = await clientPortalService.getUploadLink(
          this.firmId,
          fileDetails.clientId,
          fileDetails.fileName,
          fileDetails.folderPath
        );
        fileDetails.link = uploadData.link;
        fileDetails.fileId = uploadData.fileId;
        const blobClient = new BlockBlobClient(fileDetails.link);
        blobClient
          .uploadData(fileDetails._file, {
            onProgress: (progress) => {
              var percentCompleted = (
                (progress.loadedBytes / fileDetails._file.size) *
                100
              ).toFixed(0);
              fileDetails.progress = percentCompleted;
              fileDetails.isUploading = true;
            },
          })
          .then(() => {
            clientPortalService
              .completeUpload(this.firmId, fileDetails.fileId)
              .then(() => {
                resolve(null);
                //@ts-ignore
                fileDetails.uploadComplete = true;
                fileDetails.isUploading = false;
                // let index=this.files.findIndex(z=>z.fileId==fileDetails.fileId);
                // if(index>=0){
                //   this.completedFiles.push(fileDetails);
                //   this.files.splice(index,1)
                // }
                this.moveProcessedFiles()
              });
          });
      } catch (err) {
        reject(err);
      }
    });
  }
  folderPickerChanged(event: any) {
    this.files = [];
    var files = event.target.files;

    let filesToProcess=[]
    for (var i = 0; i < files.length; i++) {
      var pathParts = files[i].webkitRelativePath.split("/");
      let clientCodeFromPath = "";
      if (pathParts.length > 1) {
        clientCodeFromPath = pathParts[1];
      }

      const folderPath = pathParts.slice(2, -1).join('/');

      console.log('folderpath',folderPath)
      let client = this.clients.find((c) => c.clientCode == clientCodeFromPath);
      let error = "";
      if (!clientCodeFromPath) {
        error = "Could not determine client code from file path";
      } else if (!client) {
        error = "Could not find client with code " + clientCodeFromPath;
      }
      console.log('parts',pathParts)
      filesToProcess.push({
        progress: 0,
        isUploading: false,
        uploadComplete: false,
        uploadError: "",
        determinedClientCodeFromPath: clientCodeFromPath,
        clientName: client?.name,
        clientCode: client?.clientCode,
        clientId: client?.id,
        fileName: files[i].name,
        folderPath: folderPath,
        error: error,
        size:files[i].size,

        _file: files[i],
      });
    }
    this.files=filesToProcess.filter(z=>!z.error);
    this.filesWithErrors=filesToProcess.filter(z=>z.error)
    this.totalSize=this.sum(this.files,'size')/(1024 ** 2);
   }
  async getClients() {
    this.clients = await clientPortalService.getClients(this.firmId);
  }
  sum(items, prop){
    return items.reduce( function(a, b){
        return a + b[prop];
    }, 0);
};
   moveProcessedFiles(){
    console.log('moveprocessedfilescalled')
    let processed=this.files.filter(z=>z.uploadComplete);
    this.completedFiles.push(...processed);
    this.files=this.files.filter(z=>!z.uploadComplete);
   }
  chunkArray(myArray, chunk_size) {
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
      tempArray.push(myArray.slice(index, index + chunk_size));
    }

    return tempArray;
  }
  groupBy<T>(list: T[], keyGetter: (n: T) => any): Map<any, T[]> {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
}
