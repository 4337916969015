
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import componentBase from "../../common/component.base";
import ClientPortalFiles from './ClientPortalFiles.vue'
import BatchUpload from './BatchUpload.vue'
import DummyData from './DummyData.vue'
import PortalPermissions from './PortalPermissions.vue'
@Component({
  components: {
    ClientPortalFiles,
    BatchUpload,
    DummyData,
    PortalPermissions
  },
})
export default class extends componentBase {

   @Prop()
  firmId: String;
  async created() {
  }

  
}
