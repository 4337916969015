
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import componentBase from "../../common/component.base";
import { powerBIService } from "../../utils/http/powerbi.service";
import { notificationService } from "../../utils/notification/notification.service";

@Component({
  components: {
  },
})
export default class extends componentBase {
  public jobs: any[]=[];
   @Prop()
  firmId: String;
  async created() {
    this.fetchData();
  }
async deleteJob(id){
 var confirmation= await this.confirm("Confirmation","Canceling a job that is active could lead to reports to not be configured properly. Only perform this action when you plan to start another job to complete the setup")
  if(!confirmation) return;
   await powerBIService.deleteJob(id);
    this.fetchData();
}
  async fetchData() {
    try{
      this.isBusy(true);
      this.jobs = await powerBIService.getJobs([this.firmId]);
      this.isBusy(false)
    }
    catch(err){
      this.info('Error', "Something went wrong trying to get jobs")
      this.isBusy(false)
    }
    
    
  }
  
  
}
