<template>
  <b-tabs>
    <b-tab
      v-for="section in propertySections"
      :title="section.name"
      :key="section.name"
    >
    
      <b-list-group>
        <b-list-group-item
          :key="property.name"
          v-for="property in section.properties"
        >
          <b-badge class="mr-2">{{ property.name }}</b-badge>

          <b-badge variant="light">
            <span
              class="text-danger mr-2"
              style="text-decoration: line-through"
              v-if="isChangeMode"
              >{{ property.oldValue }}</span
            >
            <span class="text-success">{{ property.value }}</span></b-badge
          >
        </b-list-group-item>
      </b-list-group>
    </b-tab>
  </b-tabs>
</template>
<script>
export default {
  data() {
    return {
      propertySections: [],
    };
  },
  props: {
    isChangeMode: {
      type: Boolean,
      default: true,
    },
    properties: {
      type: [],
      default: [],
    },
  },
  created() {
    this.parseProperties();
  },
  methods: {
    parseProperties() {
      let key = "tag";
     let grouppedProperties= this.properties.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {})
      this.propertySections= Object.keys(grouppedProperties).map(z=>({name:z, properties: grouppedProperties[z] }));
      console.log('this.propertySections',this.propertySections)
    },
  },
};
</script>