import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
import store from '@/store';
import router from '@/router';
class FirmDataService {
 public getCustomers(): Promise<any[]> {
   return httpService.get(Resources.getBaseUrl() + `customers`);
 }
 public dataStats(firmId:String=null): Promise<any[]> {
  return httpService.get(Resources.getBaseUrl() + `firmdatastats/${firmId||''}`);
}
}
export let firmDataService = new FirmDataService();
