import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
// tslint:disable-next-line:max-line-length
class UsersService {
  public getAllUsers(): Promise<any[]> {
    return httpService.get(Resources.getBaseUrl() + `users`);
  }
  public getUser(id:String): Promise<any> {
    return httpService.get(Resources.getBaseUrl() + `users/${id}`);
  }
  public getMfaStatus(id:String): Promise<any> {
    return httpService.get(Resources.getBaseUrl() + `users/${id}/mfastatus`);
  }
  public removeMFA(id:String): Promise<any> {
    return httpService.post(Resources.getBaseUrl() + `users/${id}/removemfa`,{});
  }
  public getUserAccess(id:string): Promise<any> {
    return httpService.get(Resources.getBaseUrl() + `users/${id}/access`);
  }
 


}
export let usersService = new UsersService();
