
import {

    Component,

    Prop,

    Vue

} from "vue-property-decorator";





import CustomerBase from "@/common/customer.base";
import DuplicateTimeEntries from './DuplicateTimeEntries.vue'
import DueDateTasks from './DueDateTasks.vue'
import DuplicateEmployeeDuedates from './DuplicateEmployeeDuedates.vue'
import DueDateTaskGroup from './DueDateTaskGroup.vue'
import NonRecurring from './NonRecurring.vue'
@Component({
    components:{
        DueDateTaskGroup,
        DuplicateTimeEntries,
        DueDateTasks,
        DuplicateEmployeeDuedates,
        NonRecurring
    }
})

export default class DataIntegrityView extends CustomerBase {
    @Prop()
    firmId: String;


}
