
import { Component, Prop, Vue } from "vue-property-decorator";
import VueJsonPretty from "vue-json-pretty";

import {
  CustomerViewModel,
  JobStatus,
  JobType,
  DatabaseStatus,
  Backup,
  Template,
  ClientsViewModel,
  JobDetailsViewModel
} from "../../common/models";
import { templateService, httpService, clientService } from "../../utils/http";
import CustomerBase from "@/common/customer.base";
import store from "@/store";
import Resources from "@/common/resources";

@Component({
  components: {
    VueJsonPretty
  }
})
export default class DbOperations extends CustomerBase {
  filteredImportTemplateTypes:number[]=[]
  templateTypes:any=[
    {text:'Collection',value:51},
    {text:'Statement',value:48},
    {text:'Invoice',value:27},
    {text:'Letter',value:47},
    {text:'Payment',value:67},
  ]
  jobDetailsFields = [
    { key: "jobTypeName",label:'Type', sortable: true },
    { key: "start", sortable: true },
    { key: "status", sortable: true },
    { key: "output", sortable: false }
  ];

  fields = [
    { key: "checked", label: "Checked", sortable: false },
    { key: "name", sortable: true },
    { key: "description", sortable: true },
    { key: "lastModifiedDate", sortable: true },
    { key: "show_details", sortable: false }
  ];
  public clients: ClientsViewModel[] = [];
  public selectedClients: string[] = [];
  public syncBeforeDate: Date = null;
  public syncNonExistentOnly: boolean = false;
  public jobs: JobDetailsViewModel[] = [];
  public outputData = null;
  public showOutput = false;
  public showClientModal = false;
  public templates: Template[] = [];
  public templateFile = {};
  public activeJobsOnly = false;
  public importFromFirmId=null;
  public showTemplateImportModal=false
  public importTemplateList=[]
  public selectedTemplatesToImport=[]
  async created() {
   await this.loadData()
    this.clients =await clientService.getClients();;
  }
  get filteredImortTemplateList(){
    if(this.filteredImportTemplateTypes.length==0) return this.importTemplateList;
    return this.importTemplateList.filter(z=>this.filteredImportTemplateTypes.includes(z.typeId))
  }
  async loadData(){
 this.isBusy(true);
    let data = await templateService.get();
    data.forEach(item => {
      item.checked = false;
    });
    this.templates = data;
    this.isBusy(false);
  }
  async importNewTemplates(){
    if(this.selectedTemplatesToImport.length==0) return;
    var data = this.importTemplateList.filter(z=>this.selectedTemplatesToImport.includes(z.id)).map(z=>({id:z.id,name:z.name,description:z.description}))
    await templateService.addTemplates(data)
    await this.loadData();
    this.showTemplateImportModal=false;
  }
  loadTemplates(){
    if(!this.importFromFirmId) return;
    templateService.getFirmTemplates(this.importFromFirmId).then(result=>{
      this.importTemplateList=[...result]  


    })

  }
  templateMasterCheckboxChanged(value: boolean) {
    this.selectUnselectTemplates(value);
  }
  selectUnselectTemplates(checked) {
    this.templates.forEach(template => (template.checked = checked));
  }
  async syncTemplates() {
    let syncDecision = await this.yesno(
      "Are you sure you want to proceed.",
      "This will sync the selected templates with all the selected firms, and cannot be undone"
    );
    if (!syncDecision) return;
    let selectedTemplates = this.templates
      .filter(z => z.checked)
      .map(z => z.id);
    if (selectedTemplates.length == 0)
      return this.info("Invalid action", "You have not selected any templates");
    await templateService.syncTemplates(
      selectedTemplates,
      this.selectedClients,
      this.syncBeforeDate,
      this.syncNonExistentOnly || false
    );
    await this.getJobs();
    this.showClientModal = false;
  }
  async syncDefaultTemplates() {
    let syncDecision = await this.yesno(
      "Are you sure you want to proceed.",
      "This will sync the selected templates with the templates used when creating new firms"
    );
    if (!syncDecision) return;
    let selectedTemplates = this.templates
      .filter(z => z.checked)
      .map(z => z.id);
    if (selectedTemplates.length == 0)
      return this.info("Invalid action", "You have not selected any templates");

    await templateService.syncDefaultTemplates(selectedTemplates);
    await this.getJobs();
  }
  async getJobs() {
    this.jobs = await templateService.getJobs();
  }
  async showOutputData(id) {
    let job=this.jobs.find(z=>z.id==id);
    if(job==null) return;
    let jobData= await templateService.getJob(id);
    job.output=jobData.output;
    if(!job.output) job.output="{}";
    this.outputData = JSON.parse(job.output);
    this.showOutput = !this.showOutput;
  }
  downloadTemplateFile(id) {
    templateService.downloadTemplate(id);
  }
  uploadTemplateFile(id) {
    var data = new FormData();
    data.append("file", this.templateFile[id], "template.docx");

    this.confirm(
      "Are you sure you want to upload",
      "This will override the existing template"
    ).then(uplaodDecision => {
      if (uplaodDecision) {
        httpService
          .post(Resources.getBaseUrl() + `templates/upload/${id}`, data, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(result => {
            this.isBusy(false);
            var template = this.templates.find(z => z.id == id);
            if (template) {
              //@ts-ignore
              template.lastModifiedDate = result.lastModifiedDate;
            }
          });
      }
    });
  }
}
