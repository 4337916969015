import Vue from 'vue';
import moment from 'moment';
import dateTimeUtility from './datetime.utils'
const dateFilter = function(value, format='MM DD YYYY') {
    if( !value )  return '' 
    return moment(value).format(format);
};

Vue.filter('date', dateFilter);

Vue.filter('relativetime', function (val) {
    return val ? dateTimeUtility.relativetime(dateTimeUtility.toLocale(val)) :''
  })
