import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
import store from '@/store';
import router from '@/router';
// tslint:disable-next-line:max-line-length
import { CustomerViewModel, JobDetailsViewModel,ResteJasperCredentialsResult, JasperCustomerViewModel } from '@/common/models';
class JasperService {
 
 public getCustomers(): Promise<JasperCustomerViewModel[]> {
  return httpService.get(Resources.getBaseUrl() + `Jasper`);
}

public resetJasperCredentials(ids:string[]): Promise<ResteJasperCredentialsResult[]> {
  return httpService.post(Resources.getBaseUrl() + `Jasper/resetcredentials`, ids);
}
public viewCredentials(id: string): Promise<ResteJasperCredentialsResult> {
  return httpService.get(Resources.getBaseUrl() + `Jasper/viewCredentials/${id}`);
}
public getAnalytics(start:string,end:string): Promise<any> {
  return httpService.get(Resources.getBaseUrl() + `Jasper/analytics?from=${start??''}&to=${end??''}`);
}


}
export let jasperService = new JasperService();
