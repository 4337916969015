
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import componentBase from "../../common/component.base";
import { dbService } from "../../utils/http/db.service";
import { notificationService } from "../../utils/notification/notification.service";
import { JobType, JobStatus } from "../../common/enums";
import VueJsonPretty from "vue-json-pretty";

@Component({
  components: {},
})
export default class extends componentBase {
  public queries: any[] = [];
  public queryId: String = null;
  async created() {
    this.fetchData();
  }

  async fetchData() {
    this.queries = await dbService.getBatchQueries();
  }
  executeBatchQuery() {
    if (!this.queryId) return;
    this.$emit("execute", this.queryId);
  }
}
