import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
class VideoTutorialsService {
  public getVideoTutorials(): Promise<any> {
    return httpService.get(Resources.getBaseUrl() + `videotutorials`);
  }
  public createNew(): Promise<any> {
    return httpService.post(Resources.getBaseUrl() + `videotutorials`,{});
  }
  public createUploadLink(id): Promise<any> {
    return httpService.post(Resources.getBaseUrl() + `videotutorials/uploadlink/${id}`,{});
  }
  public createDownloadLink(id): Promise<any> {
    return httpService.post(Resources.getBaseUrl() + `videotutorials/downloadlink/${id}`,{});
  }
  public update(id,name,fileId,description): Promise<any> {
    return httpService.put(Resources.getBaseUrl() + `videotutorials/${id}`,{name,fileId,description});
  }
  public delete(id): Promise<any> {
    return httpService.delete(Resources.getBaseUrl() + `videotutorials/${id}`,{});
  }
 
}
export let videoTutorialsService = new VideoTutorialsService();
