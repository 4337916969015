
import {

Component,

Prop,

Vue

} from "vue-property-decorator";


import moment from 'moment';

import CustomerBase from '@/common/customer.base'
import TenantStats from './TenantStats.vue'
@Component(
    {
        components: {
            TenantStats
        }
    }
)
export default class ClietPortalTenantStats extends CustomerBase {
    @Prop({required:false})
    firmId: string;

}
