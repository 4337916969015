
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import componentBase from "../../common/component.base";
import { powerBIService,jobTypes } from "../../utils/http/powerbi.service";
import { notificationService } from "../../utils/notification/notification.service";
import { JobType, JobStatus } from "../../common/enums";
import VueJsonPretty from "vue-json-pretty";
import PowerBiPreview from './PowerBIReportPreview.vue'
@Component({
  components: {
    PowerBiPreview
  },
})
export default class extends componentBase {
  public powerBIData: any=null;
  public showPreview = false;
  public currentReportId =''
  powerBIJobTypes:any= jobTypes;

   @Prop()
  firmId: String;
  async created() {
    this.fetchData();
  }

  async fetchData() {
    this.powerBIData = await powerBIService.getPowerBIConfig(this.firmId);
    if(!this.powerBIData){
      this.powerBIData ={
        workspaceId:'',
        clientId:'',
        clientSecret:'',
        reports:[]
      }
    }
  }
  removeReport(index){
   this.powerBIData.reports.splice(index,1)
  }
  closePreview(){
    this.currentReportId=''
  }
  previewReport(id){
  this.currentReportId=id;
  this.showPreview=true;
  }
  addReport(){
    this.powerBIData.reports.push({id:'', reportId:'', reportName:''})
  }
  async scheduleJob(jobType:Number){
    var confirm = await this.confirm("Are you sure", "Action cannot be undone");
     if(!confirm) return;
     event.preventDefault()
     this.isBusy(true);
     powerBIService.schedulePowerBiJob([this.firmId],jobType).then(z=> {
       this.isBusy(false);
       this.info('Update Scheduled',"An update has been scheduled for this firm. You can view the update progress in the report update")
     }).catch(err=>{
       this.isBusy(false);
       this.info('Something went wrong', "Sorry we couldn't complete your request at this moment")
     })
  }
  
}
