
import {ActivityLogActionType} from '../../../common/enums'
import {ActivityLogProperty} from './ActivityLogProperty'
export class ActivityLogList {
    id=''
    actionType= 0
    actionTypeName=''
    userName=''
    entityType=''
    entityTypeName=''
    timestamp=''
    displayName=''
    properties=[]
   
  fromPayload(logSet){
    if(!logSet) return this;
     this.id=logSet.id
     this.displayName=logSet.displayName;
     this.actionType=logSet.actionType;
     this.actionTypeName=logSet.actionTypeName;
     this.userName=logSet.userName;
     this.entityType=logSet.entityType;
     this.entityTypeName=logSet.entityTypeName
     this.timestamp=logSet.timestamp;
     this.properties=logSet.properties? logSet.properties.map(p=>new ActivityLogProperty().fromPayload(p)):[]
    
   return this;
  }
  get actionDisplayText(){
    switch(this.actionType){
        case ActivityLogActionType.Create: return 'created';
        case ActivityLogActionType.Update: return 'updated';
        case ActivityLogActionType.Delete: return 'deleted';
        default: return ''
      }
  }
  get actionVariant(){
    switch(this.actionType){
      case ActivityLogActionType.Create: return 'success';
      case ActivityLogActionType.Update: return 'info';
      case ActivityLogActionType.Delete: return 'danger';
      default: return ''
    }
  }
  get isChange(){
    return this.actionType == ActivityLogActionType.Update
  }
 
 
}
