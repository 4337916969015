import Vue from 'vue';
import Router from 'vue-router';
import DbOperationsView from './views/customer/DbOperationsView.vue';
import HangfireDashboardsView from './views/hangfiredashboards/HangFireDashboardsView.vue'
import AdministrationView from './views/AdministrationView.vue';
import Login from './views/Login.vue';
import AccountPicker from './components/AccountPicker.vue';
import Logout from './components/Logout.vue';
import LoggedOut from './components/LoggedOut.vue';
import ClientsView from './views/customer/ClientsView.vue'
import LicenseStatsView from './views/customer/LicenseStatsView.vue'
import MonthlyReport from './views/billing/MonthlyReportView.vue'
import ReportsView from './views/reports/ReportsView.vue'
import SubscriptionView from './views/subscriptions/SubscriptionView.vue'
import RightsView from './views/rights/RightsView.vue';
import EventsView from './views/events/EventsView.vue';
import ReleaseNotesView from './views/ReleaseNotes/ReleaseNotesView.vue';
import PowerBIWorkspaces from './views/powerbi/PowerBiView.vue';
import Coupons from './components/coupons/CouponList.vue'
import HealthChecks from './components/healthcheck/HealthChecks.vue'
import HomeView from './views/home/HomeView.vue';
import JasperOperationsView from './views/customer/JasperOperationView.vue'
import DbFileView from './views/db/DbFileView.vue'
import DemoDbFileView from './views/db/DemoDbFileView.vue'
import MessageView from './views/MessageView.vue'
import TemplatesView from './views/templates/TemplatesView.vue'
import TutorialsView from './views/videotutorials/TutorialsView.vue'
import PortalStatsView from './views/stats/PortalStatsView.vue'
import QueryView from './views/db/QueryView.vue'
import JasperReportAnalytics from './components/reports/ReportRuns.vue'
import FirmData from './components/firmdata/Index.vue'
import Integrations from './components/integrations/Integrations.vue'
import B2CLegacy from './components/LegacyUseage/Index.vue'
import AllUsers from './components/users/AllUsers.vue'
import BackgroundEvents from './components/BackgroundEvents/Events.vue'
import store from '@/store'
import RightIds from './common/rights';
import { msalPluginInstance} from './plugins/msal-plugin'
Vue.use(Router);
function canNavigate(routeRights) {
  let rights = store.getters.rights;
  if (!routeRights || routeRights.length == 0) { return true; } else {
    return rights.some((z) => routeRights.includes(z));
  }

}
const routes = [
  {

    path: '/',
    redirect: '/admin',
    meta: { requiresAuth: true, canNavigate: () => true },
  },
  {

    path: '/admin',
    component: AdministrationView,
    children: [
      {
        path: '/',
        redirect: '/admin/home',
      },
      {
        path: 'home',
        name:'home',
        component: HomeView,
        meta: {title:'Services', icon:'fa fa-wrench', requiresAuth: true, canNavigate: () => true},
      },
      {
        path: 'database',
        component: DbOperationsView,
        meta: { title:'Database', icon:'fas fa-database', requiresAuth: true, canNavigate: () => canNavigate([RightIds.Backup,RightIds.UpdateDB,RightIds.DeleteClient,RightIds.DeleteBackup,RightIds.Restore,RightIds])},
      },
      {
        path: 'hdashboards',
        component: HangfireDashboardsView,
        meta: {title:'Hangifire Dashboards', icon:'fas fa-tasks', requiresAuth: true, canNavigate: () => canNavigate([RightIds.Hangfire])},
      },
      {
        path: 'jasper',
        component: JasperOperationsView,
        meta: {title:'Jasper Management', icon:'fas fa-chart-bar', requiresAuth: true, canNavigate: () => canNavigate([RightIds.Jasper]) },
      },
      {
        path: 'templates',
        component: TemplatesView,
        meta: {title:'Templates', icon:'fas fa-file-word', requiresAuth: true, canNavigate: () => true},
      },
      {
        path: 'allusers',
        component: AllUsers,
        meta: {title:'AllUsers', icon:'fas fa-user', requiresAuth: true, canNavigate: () => true},
      },
      
      {
        path: 'tutorials',
        component: TutorialsView,
        meta: {title:'Tutorials', icon:'fas fa-video', requiresAuth: true, canNavigate: () => canNavigate([RightIds.ViewVideoTutorials])},
      },
      {
        path: 'query',
        component: QueryView,
        meta: {title:'Query', icon:'fas fa-terminal', requiresAuth: true, canNavigate: () => canNavigate([RightIds.BatchQuery])},
      },
      
   
      {

        path: 'monthly-report',
        component: MonthlyReport,
        meta: {title:'Report', icon:'fas fa-money-bill-alt', requiresAuth: true, canNavigate: () => canNavigate([RightIds.Billing]) },
      },
      {

        path: 'jasperanalytics',
        component: JasperReportAnalytics,
        meta: {title:'Jasper Analytics', icon:'fas fa-chart-bar', requiresAuth: true, canNavigate: () => true },
      },
      {

        path: 'firmdata',
        component: FirmData,
        meta: {title:'Firm Data', icon:'fas fa-table', requiresAuth: true, canNavigate: () => true },
      },
      {

        path: 'integrations',
        component: Integrations,
        meta: {title:'Integrations', icon:'fas fa-cog', requiresAuth: true, canNavigate: () => true },
      },
      {

        path: 'legacyuseage',
        component: B2CLegacy,
        meta: {title:'LegacyUseage', icon:'fas fa-clock', requiresAuth: true, canNavigate: () => true },
      },
     
      
      {

        path: 'powerbiworkspaces',
        component: PowerBIWorkspaces,
        meta: {title:'PowerBI Groups', icon:'fas fa-chart-bar', requiresAuth: true, canNavigate: () => canNavigate([RightIds.PowerBIEdit]) },
      },
      
      {
        path: 'subscriptions',
        component: SubscriptionView,
        meta: {title:'Subscriptions', icon:'fas fa-key', requiresAuth: true, canNavigate: () => true },
      },
      {
        path: 'rights',
        component: RightsView,
        meta: { title:'Rights', icon:'fas fa-user-secret', requiresAuth: true, canNavigate: () => canNavigate([RightIds.ChangeRights])},
      },
      {
        path: 'events',
        component: EventsView,
        meta: {  title:'Events', icon:'fas fa-bell', requiresAuth: true, canNavigate: () => canNavigate([RightIds.Events])},
      },
      {
        path: 'backgroundevents',
        component: BackgroundEvents,
        meta: {  title:'Background Events', icon:'fas fa-bell', requiresAuth: true, canNavigate: () => canNavigate([RightIds.Events])},
      },
      
      {
        path: 'releasenotes',
        component: ReleaseNotesView,
        meta: {  title:'Release Notes', icon:'fa fa-gift', requiresAuth: true, canNavigate: () => canNavigate([RightIds.Events])},
      },
      {
        path: 'clients',
        component: ClientsView,
        meta: {  title:'Clients', icon:'fas fa-user', requiresAuth: true, canNavigate: () => canNavigate([RightIds.ClientList]) },
      },
      {
        path: 'licensestats',
        component: LicenseStatsView,
        meta: {   title:'License Stats', icon:'fa fa-exclamation-triangle',requiresAuth: true, canNavigate: () => true },
      },
      {
        path: 'portalstats',
        component: PortalStatsView,
        meta: {   title:'Portal Stats', icon:'fa fa-exclamation-triangle',requiresAuth: true, canNavigate: () => true },
      },

      
      {
        path: 'dbfile',
        component: DbFileView,
        meta: { title:'Database File', icon:'fas fa-file', requiresAuth: true, canNavigate: () => canNavigate([RightIds.UpdateDBFile]) },
      },
      
      {
        path: 'demodbfile',
        component: DemoDbFileView,
        meta: {  title:'Demo Db File', icon:'fas fa-file',requiresAuth: true, canNavigate: () => canNavigate([RightIds.DemoDbFiles]) },
      },
      {
        path: 'coupons',
        component: Coupons,
        meta: {  title:'Coupons', icon:'fas fa-ticket-alt',requiresAuth: true,canNavigate: () => canNavigate([RightIds.ViewCoupons])},
      },
      {
        path: 'healthchecks',
        component: HealthChecks,
        meta: {  title:'HealthChecks', icon:'fas fa-ticket-alt',requiresAuth: true,canNavigate: () => canNavigate([RightIds.HealthChecks])},
      },
      {

        path: 'message',
        component: MessageView
      },

    ],
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/LoggedOut',
    component: LoggedOut,
  },
  {
    path: '/Logout',
    name:'logout',
    component: Logout,
  },
  
  {
    path: '/accountpicker',
    component: AccountPicker,
  },
  
  {
    path: '/message',
    component: MessageView,
  },
];
const router = new Router({
  mode: 'history',
  routes,
});
function nextNavigateableRoute() {
  const adminChildren = routes.find((z) => z.path === '/admin').children;
  const navigateableRoautes = adminChildren.filter((z) => z.meta != null && z.path !== 'message' && (z.meta.canNavigate == null || z.meta.canNavigate()));
  return navigateableRoautes.length > 0 ? navigateableRoautes[0] : null;
}
router.beforeEach(function(to, from, next) {
  if (!store.getters.isInitialized) {
    store.commit('initialiseStore');
  }
  if (!to.meta.requiresAuth) { next(); } else {
    if (msalPluginInstance.isAuthenticated) {
      if (to.meta.canNavigate == null || to.meta.canNavigate()) { next(); } else {
        const nextPossibleRoute = nextNavigateableRoute();
        if (nextPossibleRoute) { next({ path: `/admin/${nextPossibleRoute.path}` }); } else { next({ path: '/admin/message', query: { title: 'Access Denied', message: 'You do not have access to open this page ' } }); }
      }


    } else { next({ path: '/login' }); }
  }

});
export default router;
