
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
   
  }
})
export default class Sidebar extends Vue {
//   @Prop()
//   firmId: string;
  sidebarVisible:boolean=true;
  size:string= "onehundreed";
  close() {
    this.$emit("close");
    }
}
