import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

let store = new Vuex.Store({
  state: {
    user:{},
   isInitialized:false,
   environment:'development',
   isLoading: false,
   loadingPercentage:'',
   userInfo:null,
   rights:[]
  },
  getters: {
    user(state){
      return state.user
    },
    isInitialized (state) {
      return state.isInitialized
    },
    isLoading (state) {
      return state.isLoading;
    },
    loadingPercentage(state){
      return state.loadingPercentage
    },
    environment (state) {
      return state.environment;
    },
    rights(state){
      return state.rights||[]
    }
  },
  mutations: {
    userInfo(state,info){
      state.userInfo = info
    },
    rights(state,value){
     state.rights=value||[]
    },
    changeUser(state, user){
    state.user=user||{}
    },
    isLoading (state,loading) {
      state.isLoading =loading.isLoading;
      state.loadingPercentage = loading.isLoading? loading.percent && loading.percent.toString() : '';      
    },
    loadingPercentage(state,value){
       state.loadingPercentage = value
    },
    environment(state,environment) {
      state.environment=environment;
    },
    initialiseStore(state) {
      if (localStorage.getItem('tps_admin_store')) {
        // Replace the state object with the stored item
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem('tps_admin_store')))
        );
      }
      state.isInitialized = true;
      state.isLoading=false; // ignore state for this varaible
    },
  },
  actions: {

  },
});

// Subscribe to store updates
store.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  localStorage.setItem('tps_admin_store', JSON.stringify(state));
});

export default store;
