
    import {
    
      Component,
    
      Prop,
    
      Vue
    
    } from "vue-property-decorator";
    
   
    
    import {
    
        firmDataService
    
    } from "../../utils/http";
    
    import CustomerBase from '@/common/customer.base'
     
    import DataStats from './DataStats.vue'
    
    @Component({
      name:'data-stats',
        components:{
         'data-stats': DataStats
        }
    })
    export default class AdministratorEmails extends CustomerBase {
      
      @Prop()
      firmId: string=null;
      currentFirmDetailsId:String=null
      fields = [
        {key:'urlCode',label:'Firm',  sortable: true },
        {key:'date', label:'Calculated.On', sortable: true },
        {key:'numberOfClients',label:'Clients', sortable: true },
        {key:'numberOfEmployees',label:'Employees', sortable: true },
        {key:'numberOfOffices', label:'Offices', sortable: true },
        {key:'numberOfWorkcodes',label:'Workcodes', sortable: true },
        // {key:'numberOfContacts', sortable: true },
        {key:'numberOfDuedates',label:'Legacy. Duedates', sortable: true },
        {key:'numberOfWorkflowEntires',label:'Duedates', sortable: true },

        {key:'numberOfInvoices',label:'Invoices', sortable: true },
        {key:'numberOfPayments',label:'Payments', sortable: true },
        {key:'numberOfTimeEntries',label:'Times', sortable: true },
        {key:'numberOfTimers',label:'Timers', sortable: true },
        {key:'numberOfClientEmails',label:'Emails', sortable: true },

        // {key:'numberOfPortalClients', sortable: true },
        {key:'numberOfPortalFiles', sortable: true },
        // {key:'numberOfThirdPartyIntegrations', sortable: true },
        
        {key:'portalBandwidth', sortable: true },
        {key:'portalStorage', sortable: true },
    ]
       
    
     
    
    
      public stats: any[] = [];
    
      
  
      created() {
  
  
        this.getData();
  
      }
     
      getData() {
  
        this.isBusy(true);
  
        firmDataService.dataStats(this.firmId).then((result) => {
  
          this.isBusy(false);
         
          this.stats=result;
  
  
  
        }).catch((err) => this.isBusy(false))
  
  
  
      }
      exportToExcel(){
        this.exportTableToExcel("stats-table")
      }
  
    }
  