import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
import store from '@/store';
import router from '@/router';
// tslint:disable-next-line:max-line-length
import { CustomerViewModel, JobDetailsViewModel,ResteJasperCredentialsResult, Reports, TreeItem } from '@/common/models';
class SubscriptionService {
  public getSubscriptionLocks(): Promise<any> {
    return httpService.get(Resources.getBaseUrl() + `licenses/locks`);
  }
 public getSubscriptions(subscriptionLockId:string): Promise<any> {
   return httpService.get(Resources.getBaseUrl() + `licenses/locks/${subscriptionLockId}/subscriptions`);
 }
 public makeSubscriptionLockDefault(subscriptionLockId:string): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `licenses/locks/makedefault/${subscriptionLockId}`,{});
}
public schedulePriceUpdateBySubscriptionLock(subscriptionLockId:string): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `licenses/updatepricesbysubscriptionlock/${subscriptionLockId}`,{});
}
public schedulePriceUpdateByFirms(firms:string[]): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `licenses/updatepricesbyfirms`,firms);
}
public schedulePriceValidation(): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `licenses/validatesubscriptionprices`,{});
}
public validateSubscriptionToCustomerLink(): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `licenses/ValidateSubscriptionToCustomerLink`,{});
}
public linkFirmsToStripeCustomers(): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `licenses/linkfirmstostripe`,{});
}
public deleteSubscriptionLock(subscriptionLockId:string): Promise<any> {
  return httpService.delete(Resources.getBaseUrl() + `licenses/locks/${subscriptionLockId}`,{});
}
public cloneSubscriptionLock(subscriptionLockId:string): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `licenses/locks/clone/${subscriptionLockId}`,{});
}
 public getSubscriptionDisplayText(id): Promise<any> {
  return httpService.get(Resources.getBaseUrl() + `licenses/subscriptions/${id}/text`);
}
public saveSubscriptionDisplayText(id,displayText): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `licenses/subscriptions/${id}/text`,{
   id:id,
   displayText:displayText
  });
}
 public updateSubscriptionFeatures(subscriptionId,features: any[]): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `licenses/subscriptions/${subscriptionId}/updatefeatures`,features);
}
public changeState(id: String, state: Boolean): Promise<any> {
  return httpService.put(Resources.getBaseUrl() + `licenses/subscriptions/state`,{id:id, isHistory: state});
}

public getFeatureList(): Promise<any> {
  return httpService.get(Resources.getBaseUrl() + `firmfeatures/features`);
}
public getFirmFeatures(firmId:String): Promise<any> {
  return httpService.get(Resources.getBaseUrl() + `firmfeatures/${firmId}`);
}
public saveFirmFeatures(firmId:String,features:any[]): Promise<any> {
  return httpService.post(Resources.getBaseUrl() + `firmfeatures/${firmId}`,features);
}

}
export let subscriptionService = new SubscriptionService();
