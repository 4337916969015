import Vue from 'vue';
import store from '@/store';
import CommonRightIds from '@/common/rights';
import TPSEventNames from '@/common/events';
import moment from "moment";
import XLSX from "xlsx";
import { messageService } from '@/utils/message.service';

export default class ComponentBase extends Vue {
  public Rights = [];
  public RightIds = CommonRightIds;
  public EventNames = TPSEventNames;
  public constructor() {
    super();
    this.Rights = store.getters.rights;
  }
 
  public isBusy(loading: boolean, percent: number|string = null) {
    store.commit('isLoading', { isLoading: loading, percent });
  }
  public info(title: string, message: string) {
    
    messageService.toast(title,message,'info')
  }
  formatDate(date:Date,format:string='YYYY-MM-DD'){
  if(!date) return;
  return moment(date).format(format)
  }
  guid() {
    let _p8 =(s=null)=> {
         var p = (Math.random().toString(16)+"000000000").substr(2,8);
         return s ? "-" + p.substr(0,4) + "-" + p.substr(4,4) : p ;
     }
     return _p8() + _p8(true) + _p8(true) + _p8();
 }
  public confirm(title: string, message: string) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      swal({
        title,
        text: message,
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        resolve(willDelete);
      });
    });
  }
  public multiConfim(title: string, messages: string[]) {
    return new Promise(async (resolve, reject) => {
      for (let i = 0; i < messages.length; i++) {
        let result = await this.confirm(title, messages[i]);
        if(!result){
          resolve(result)
          break;
        }
      }
      resolve(true);
    });
  }
  public yesno(title: string, message: string) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      swal({
        title,
        text: message,
        buttons: ['No', 'Yes'],
      }).then((willDelete) => {
        resolve(willDelete || false);
      });
    });
  }
  public hasAnyRight(rights: string[]) {
    return rights.some((z) => this.Rights.includes(z));
  }
  public hasEveryRight(roles: string[]) {
    return roles.every((z) => this.Rights.includes(z));
  }
  exportTableToExcel(tableID) {
    let type='xls';
     //@ts-ignore
    var data = document.getElementById(tableID);
 //@ts-ignore
    var file = XLSX.utils.table_to_book(data, { sheet: "Data" });

    //@ts-ignore
    XLSX.write(file, { bookType: type, bookSST: true, type: "base64" });
 //@ts-ignore
    XLSX.writeFile(file, "Data." + type);
  }
}
