
import { Component, Vue, Prop, Watch} from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class MessageResult extends Vue {
  message:string=''
  backLink:string=''
  backLinkText:string=''
  title:string=''

    created(){
      //@ts-ignore
   this.title=this.$route.query.title
    //@ts-ignore
   this.message=this.$route.query.message
    //@ts-ignore
      this.backLink=this.$route.query.backLink
       //@ts-ignore
      this.backLinkText=this.$route.query.backLinkText
    }

}
