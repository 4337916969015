
  import { Component, Vue, Prop, Watch } from "vue-property-decorator";
  import componentBase from "../../common/component.base";
  import { bidirectionalEmailService } from "../../utils/http";
  
  @Component({
    components: {
        
    },
  })
  export default class extends componentBase {
    @Prop({required:false})
    firmId: string;
    loading:boolean=false;
    
    status:any=null;
    async created() {
      this.getStatus();
    }
  
   
    async getStatus() {
      this.loading=true;
       bidirectionalEmailService.getStatus(this.firmId).then(result=>{
        this.status=result;
        this.loading=false;
       }).catch(err=>{
        this.loading=false;
       })


    }
    async setupBidirectionalEmail() {
      this.loading=true;
       bidirectionalEmailService.setupBidirectionalEmail(this.firmId).then(result=>{
        this.getStatus();
       }).catch(err=>{
        this.loading=false;
       })


    }
  }
  