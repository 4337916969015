
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import componentBase from "../../common/component.base";
import { dbService } from "../../utils/http/db.service";
import { notificationService } from "../../utils/notification/notification.service";
import { JobType, JobStatus } from "../../common/enums";
import VueJsonPretty from "vue-json-pretty";

@Component({
  components: {},
})
export default class extends componentBase {
  @Prop()
  firms: string[];
  public findValue='';
  public replaceValue='';
  async created() {
    
  }

  
  runJob() {
      this.isBusy(true);
    dbService
        .runFindAndReplace(this.findValue,this.replaceValue, this.firms)
        .then(result => {
          this.isBusy(false);
          this.info('Started','View results on the jobs in progress')
        })
        .catch(err => this.isBusy(false));
  
  }
}
