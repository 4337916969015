
import { Component, Prop, Vue } from "vue-property-decorator";


import { workflowService } from "../../utils/http";
import CustomerBase from "@/common/customer.base";

@Component({
  components: {
  }
})
export default class Workflow extends CustomerBase {
 
 

  @Prop()
  firmId: string;
    
  confirmed:boolean=false;
  jobDetails:any=null;
  created() {
  }
  startMigration(){
    
    this.isBusy(true);
    workflowService.startMigration(this.firmId).then(result=>{
    this.jobDetails=result;
    this.isBusy(false);
    this.info("Job Started","Track the job status on the jobs tab")
   }).catch(err=>{
    this.isBusy(false);
   })
  }
 
 
}
